import React from 'react';

const Sponsors = () => {
  return (
    <div className="mt-40 text-center">
      <div className="mt-40">
        <h2 className="text-center text-3xl text-pink-400 py-20">BECOME A UMUV SPONSOR!</h2>
      </div>
      <h1 className="">With us you are a happy sponsor.</h1>
      <h1 className="">Keeping our relationships strong and sponsors even stronger! </h1>

      <div className="flex flex-col justify-center items-center text-center">
        <div className="w-1/2 text-center">
          <h1 className="text-pink-400 py-10">Thank you!</h1>
        </div>
        <div className="my-10 w-full md:w-1/2">
          <p className="py-2">
            UMUV is looking for sponsors so we can develop our idea further, with a more sophisticated service for a
            broader community.
          </p>

          <p className="py-2">
            If you are someone who is interested in contributing to the dance community or who wants to advertise a
            dance brand, yoga brand, healthy food brand, or any other brand that is related to an active body, then you
            can get involved in helping UMUV by sponsoring.
          </p>

          <p className="py-2">
            We assure you that UMUV is the next big idea! People can&apos;t wait for it to become what we plan it to
            become! You will not regret to be part of it. We will only grow, develop and become better!
          </p>
        </div>

        <div className="py-10">
          <h4 className="text-pink-500"> Get in touch with us and you will not be dissapointed!</h4>
          <h4 className="text-pink-500">
            <a href="mailto:umuvworld@gmail.com" target="_blank" rel="noopener noreferrer">
              umuvworld@gmail.com
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
