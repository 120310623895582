import React from 'react';
import AppUpdateModal from './AppUpdateModal';

function BackOffice() {
  function AppUpdatesSection() {
    return (
      <div className="flex flex-col justify-center my-10">
        <h1 className="text-purple-400">Manage App updates</h1>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 mt-20 bg-center flex justify-center">
      <div className="flex justify-center items-center flex-col mt-10 mb-10">
        <AppUpdatesSection />
        <AppUpdateModal />
      </div>
    </div>
  );
}

export default BackOffice;
