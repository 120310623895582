import React from 'react';
import { useMemo } from 'react';
import { firestore } from '../../lib/firebase.prod';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Center } from '@mantine/core';
import { Spinner } from '../styles';
import { SpinningOrbitLoader } from 'react-loaders-kit';
import { IconArrowBigDownLines } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import { UserCardWrapper } from './styles';

import { collection, query, limit, getDocs, startAfter, doc } from 'firebase/firestore';

const AllUsers = () => {
  const history = useHistory();
  const usersQuery = useMemo(() => query(collection(firestore, 'users'), limit(50)), []);

  const [users, setUsers] = useState([]);
  const [cursorDoc, setCursorDoc] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDocs(usersQuery).then(snapshot => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const firstUsers = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));
      setCursorDoc(lastVisible);
      setUsers(firstUsers);
      setLoading(false);
    });
  }, [usersQuery]);

  async function getMore() {
    setLoading(true);
    const paginatedQuery = query(collection(firestore, 'users'), startAfter(cursorDoc), limit(50));
    return getDocs(paginatedQuery)
      .then(snapshot => {
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        setCursorDoc(lastVisible);
        const paginatedUsers = snapshot.docs.map(contentObj => ({ ...contentObj.data(), docId: contentObj.id }));
        setUsers(prev => [...prev, ...paginatedUsers]);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  return (
    <UserCardWrapper>
      <div className="grid grid-cols-1 gap-6 mt-40 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mx-10 sm:grid-cols-1 xs:grid-cols-1 2xl:grid-cols-6 hover:cursor-pointer">
        {users.map(({ photoURL, name, created_at, id }) => {
          const date = new Date(created_at);
          let isValidDate = date instanceof Date && !isNaN(date);
          let formattedDate;

          if (isValidDate) {
            formattedDate = new Intl.DateTimeFormat('en-US', {
              month: 'long',
              year: 'numeric',
            }).format(date);
          }

          return (
            <div
              className="text-white rounded-lg shadow-md p-4 min-w-[200px] bg-white hover:bg-slate-100"
              key={id}
              onClick={() => history.push(`/users/${id}`)}
            >
              <div className="flex items-center mb-4 flex-col justify-center">
                <img src={photoURL} alt={`User Name:${name}`} className="w-12 h-12 rounded-full mr-4"></img>

                <div className="text-center py-2">
                  <h2 className="text-xl font-bold text-slate-800">{name}</h2>
                  {created_at && isValidDate ? <p className="text-slate-800">Joined {formattedDate} </p> : null}
                </div>
              </div>
              <p className="text-gray-300"></p>
            </div>
          );
        })}
      </div>
      <div>
        <Center style={{ height: 200 }}>
          {loading ? (
            <Spinner>
              <SpinningOrbitLoader {...{ loading: true, size: 120 }} />
            </Spinner>
          ) : (
            <Button
              onClick={getMore}
              radius={4}
              rightIcon={<IconArrowBigDownLines size={14} />}
              disabled={loading}
              className="lg:w-1/4 md:w-1/8 xs:w-full m-10 rounded-md text-slate-100 text-xl h-12 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 font-montserrat"
            >
              Load more
            </Button>
          )}
        </Center>
      </div>
    </UserCardWrapper>
  );
};

export default AllUsers;
