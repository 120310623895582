import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { removeDashes, truncateString } from '../helpers/util-fns';

export const CardWrapper = styled.div`
  padding: 0 0 10px 0;
  min-width: 240px;
  margin: 4px 2px;
  margin: 0.75rem;
  border: 1px solid mediumpurple;
  font-family: 'Montserrat', sans-serif;
  img {
    object-fit: cover;
    width: 240px;
  }
  position: relative;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.06);
  border: none;
  transition: box-shadow 0.3s ease;
  border-radius: 0 0 4px 4px;
  border: none !important;
  display: flex;
  flex-direction: column;

  a {
    color: #c785fd;
    font-family: 'Montserrat', sans-serif;
    width: 240px;
  }

  &:hover {
    box-shadow: 0px 0px 30px 10px rgba(50, 46, 51, 0.2);
    a {
      color: #c785fd;
    }
  }
`;

export const InfoText = styled.p`
  font-family: 'Montserrat', sans-serif;
  padding-left: 1rem;
  padding-right: 10px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const DateRelated = styled.div`
  width: 50%;
  padding-left: 10px;

  p {
    position: absolute;
    top: 25px;
    right: 10px;
    color: white;
    text-align: center;
    z-index: 150;
    font-size: 14px;
    font-weight: 500;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    :after {
      content: '';
      display: block;
      width: 43px;
      height: 56px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -28px;
      margin-left: -21px;
      z-index: -1;
      border: 1px solid #753c91;
      box-shadow: 0 0 35px rgba(50, 46, 51, 0.3), inset 0 0 35px rgba(50, 46, 51, 0.3);
      background: transparent;
    }
  }
`;

export const Time = styled.div`
  p {
    color: #555;
    width: fit-content;
    padding-left: 1rem;
  }
`;

export const Place = styled.div`
  p {
    color: #555;
    padding-left: 1rem;
  }
`;

const Card = ({ muvent, link }) => {
  return (
    <CardWrapper>
      <Link to={{ pathname: link, state: muvent }} className="cursor-pointer">
        <img alt="wrapper" src={muvent.photoURL} />
        <InfoText>{truncateString(removeDashes(muvent?.slug || muvent.title), 40)?.toLocaleUpperCase()}</InfoText>
        <Time>
          <p>
            {muvent?.date ? format(muvent?.date?.toDate(), 'MMM d, yyyy') : null}
            {muvent?.start && ' @ '}
            {muvent?.start && format(new Date(muvent?.start?.toDate()), 'HH:mm')}
          </p>
        </Time>
        <Place>
          <p>
            {truncateString(muvent?.address_1 || muvent?.city, 18)}{' '}
            {truncateString(muvent?.address_2 || muvent?.country, 3)}
          </p>
        </Place>
      </Link>
    </CardWrapper>
  );
};

export default Card;
