import React from 'react';

const Careers = () => {
  return (
    <div>
      <div className="mt-40 mb-40">
        <h2 className="text-center text-3xl text-pink-400">WE MAKE WORK FUN</h2>

        <div className="flex flex-col justify-center items-center text-center">
          <h2 className="py-10 text-2xl"> Build the biggest dance platform ever with us!</h2>
          <h2 className="py-10 text-2xl">Become part of the team & you won&apos;t regret it</h2>
          <h4 className="text-pink-500">We are looking for interns in:</h4>

          <ul className="text-start">
            <li className="list-disc">Marketing</li>
            <li className="list-disc">Communication</li>
            <li className="list-disc">Web Development</li>
            <li className="list-disc">Web Design</li>
            <li className="list-disc">Data Entry</li>
            <li className="list-disc">Website Legal Matters</li>
          </ul>

          <div className="py-10">
            <h4 className="text-pink-500">Email us at:</h4>
            <h4 className="text-pink-500">
              <a href="mailto:umuvworld@gmail.com" target="_blank" rel="noopener noreferrer">
                umuvworld@gmail.com
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
