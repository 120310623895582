import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto py-8 px-4 mt-40">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy - GDPR compliant</h1>
      <h2>WE CARE ABOUT PRIVACY</h2>

      <h4>UMUV&apos;s PRIVACY POLICY & ACTIVELY OPTING IN</h4>
      <p className="py-2">
        UMUV.WORLD (hereinafter referred to as “UMUV”, “we”, “us” or “our”) acknowledges the importance of protecting
        every user&apos;s (hereinafter referred to as &quot;user&quot;, &quot;you&quot;, &quot;your&quot; or
        &quot;yours&quot;) personal information.
      </p>
      <p className="py-2">
        This Privacy Policy describes how we collect, use, and disclosure information about you in connection with your
        use of our services, including those offered through our website, emails, and mobile application (collectively,
        the &quot;Service&quot;). When you use the Service, you consent to our collection, use, and disclosure of
        information about you as described in this Privacy Policy.
      </p>
      <p className="py-2">
        A link to Privacy Policy, Cookie Policy and Terms of Use pages will be provided on the signup form on the
        website & you will actively check that checkbox where you agree to all of our terms of service and all its
        components -{' '}
        <a href="https://www.umuv.world/cookie-policy" className="text-blue-600">
          Cookie Policy
        </a>{' '}
        and{' '}
        <a href="https://www.umuv.world/terms-of-use" className="text-blue-600">
          Terms Of Use
        </a>
        .
      </p>

      <div className="py-6">
        <h3 className="text-blue-600">WHAT TYPES OF INFORMATION DOES UMUV COLLECT ABOUT ITS USERS?</h3>
        <p className="py-2">
          You will provide the majority of the information we collect about you. UMUV collects anonymous information
          from visitors of its website and specific personal information from registered users of its service. That
          information includes, your name, email address and any other personal information you provide when you
          register or update the details of your user account, or when you supply ID and other verification information;
        </p>
        <p className="py-2">
          We receive, store and process information, when you access or use UMUV to: search for or post classes,
          muvents, articles, dance tips and dance shows, wish list a class, like or comment posts communicate with other
          users follow other users process payments, and more info that is clearly persistent and public about you on
          UMUV’s website.{' '}
        </p>
        <p className="py-2">
          This information will be stored and processed by computer and may be used for communication purposes or to
          process orders. When you visit UMUV, we use a third party tracking partner to gather certain information
          automatically and store it in log files. This information includes internet protocol (IP) addresses, browser
          type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp and clickstream
          data. We use this information, which does not identify individual users, to analyze trends, to administer the
          site, to track users’ movements around the site and to gather demographic information about our user base as a
          whole.
        </p>
        <p className="py-2">
          The information that you contribute through the Service is intended for public consumption, including: Your
          profile info, including bio, photo, cv, gender, birth date, wishlist, schools you teach at The events you post
          such as classes, muvents, dance tips, dance shows, articles, photos, video links Your comments and likes The
          users you follow, and users that follow you.
        </p>
        <p className="py-2">
          We may display this information through the Service, share it with businesses, and further distribute it to a
          wider audience through third party sites and services.
        </p>
        <p className="py-2">
          In addition, we store certain information from your browser using &quot;cookies&quot;. A cookie is a small
          piece of information that a web-service can store on your web browser and later retrieve. A cookie enables the
          Service to recognize you as a prior user, provides the Service with information regarding your prior use and
          assists us in determining what portions of the Service are most appropriate for you.
        </p>
        <p className="py-2">
          The cookie file will also store information about the events you searched and posted and also about the access
          to all content of the site for one day.
        </p>{' '}
        <p className="py-2">
          Cookies may also transmit information about your browser type, search preferences, IP address, data relating
          to advertisements that have been displayed to you or that you have clicked on, and the date and time of your
          use. Cookies may be persistent or stored only during an individual session.
        </p>
        <p>
          UMUV reserves the right to send you general emails about your account that have to do but not limited to
          general news about our company, the state of the application, when terms of service change, or any other email
          that directly affects the state of your account and our platform. Other email preferences about receiving
          news, newsletter, or emails regarding user followings, associations with other users on UMUV, or when users
          comment, wishlist etc can be changed in your account settings - notification settings.
        </p>
      </div>

      <div className="py-6">
        <h3 className="text-blue-600">WHY DOES UMUV COLLECT USER&apos;S DATA?</h3>
        <p className="py-2">
          To personalize your experience (your information helps us to better respond to your individual needs) To
          improve our Service (we continually strive to improve our website offerings based on the information and
          feedback we receive from you) To improve customer service ( your information helps us to more effectively
          respond to your customer service requests and support needs) To keep our website secure and prevent fraud To
          process transactions To send you non-marketing commercial communications To send you email notifications that
          you have specifically requested To send you our email newsletter, if you have requested it (you can inform us
          at any time if you no longer require the newsletter)
        </p>
        <p className="py-2">
          To provide third parties with statistical information about our users (but those third parties will not be
          able to identify any individual user from that information)];
        </p>
        <p className="py-2">
          All our website financial transactions are handled through our payment services provider, Stripe. You can
          review the provider&apos;s privacy policy at https://stripe.com/pt/privacy.
        </p>
        <p className="py-2">
          We will share information with our payment services provider only to the extent necessary for the purposes of
          processing payments you make via our website, refunding such payments and dealing with complaints and queries
          relating to such payments and refunds.
        </p>
        <p className="py-2">
          We may disclose your personal information to any of our employees, officers, insurers, professional advisers,
          agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this policy.
        </p>
      </div>
      <div className="py-6 ">
        <h3 className="text-blue-600">HOW DO WE ENSURE THAT USER DATA REMAINS PRIVATE AND SECURE? </h3>

        <p className="py-2">
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This
          does not include trusted third parties who assist us in operating our website, conducting our well-functioning
          or servicing you, so long as those parties agree to keep this information confidential.
        </p>
        <p className="py-2">
          We may also release your information when we believe release is appropriate to comply with the law, enforce
          our site policies, or protect ours or others rights, property or safety. Non-personally identifiable visitor
          information may be provided to other parties for marketing, advertising, or other uses.
        </p>
      </div>
      <div className="py-6">
        <h3 className="text-blue-600">WHERE DOES YOUR DATA RESIDE.</h3>

        <p className="py-2">
          We use Firebase which is a Google Cloud Platform for our database and storage. Firebase is a mobile and web
          application development platform developed by Firebase, Inc. in 2011, then acquired by Google in 2014. As of
          March 2020, the Firebase platform has 19 products, which are used by 1.5 million apps.
        </p>

        <p className="py-2">You data is as safe as it could ever be!</p>

        <p className="py-2">
          We use MAILCHIMP and SENDGRID for marketing purposes and email delivery service/communications.
        </p>
      </div>

      <div className="py-6">
        <h3 className="text-blue-600">WE HAVE YOUR DATA FULLY ORGANIZED AND COMPLETELY SAFE.</h3>

        <p className="py-2">
          Your data can safely, quickly and securely be organized and shipped to you at your request at any time. You
          own your data and we are ready to show you at any time what information we have collected on you and how we
          use your information at your request.
        </p>

        <p className="py-2">
          For more info on how we use your information read the above chapters. To request a copy of your data we have
          on you please contact us at mydata@umuv.world. Your data will made available to you within a month and free of
          charge.
        </p>
      </div>

      <div className="py-6">
        <h3 className="text-blue-600">WHO DO WE SHARE YOUR DATA WITH.</h3>

        <p className="py-2">
          We do not share your personal data in bulk with any third party. We do not sell your data to any third party.
        </p>
        <p className="py-2">
          We do not sell your data to anyone. You own your data and it is up to you to make it available on UMUV or not.
          The data you have made public and available on UMUV is what we use to share on our social media sites and
          marketing campaigns with the sole purpose of promoting you and helping you reach and expand to more audiences.
        </p>
        <p className="py-2">
          The data you create through your posts (including dance classes, dance events, videos and more on the website)
          is yours and can be fully managed by you at full control.
        </p>
      </div>

      <div className="py-6">
        <h3 className="text-blue-600">TERMINATING/DELETING ACCOUNT</h3>

        <p className="py-2">
          Once your account is terminated/deleted, you acknowledge and agree that we permanently delete your account and
          all the data associated with it, including everything you have posted on UMUV and your associations with other
          users on UMUV (including followers, comments on others posts and more.). We delete everything/any kind of
          content that you have created on the Website. We do not keep anything. Everything is deleted permanently.
        </p>

        <p className="py-2">If you wish to terminate/delete your account, please contact us at umuvworld @ gmail.com</p>
      </div>

      <div className="py-6">
        <h3 className="text-blue-600">NO SCRAPING - NOT ALLOWED ON THIS WEBSITE</h3>
        <p className="py-2">What is?</p>
        <p className="py-2">
          &nbsp;&nbsp; Web scraping, web harvesting, or web data extraction is data scraping used for extracting data
          from websites.
          <a
            href="https://en.wikipedia.org/wiki/Web_scraping"
            className="text-blue-600"
            target="_blank"
            rel="noreferrer"
          >
            &nbsp; Learn more about scraping
          </a>
        </p>
        on UMUV?
        <p className="py-2">
          &nbsp;&nbsp; Scraping is absolutely not allowed on this Website. We take our users&apos; data and privacy very
          seriously and will not allow or tolerate the collection of our users&apos; data from anyone or any software.
        </p>
        <p className="py-2">
          We are willing to go to any legal length to protect the data of our users from being scraped from this
          Wesbite.
        </p>
        <p className="py-2">
          It is absolutely against our policy, desire and will to allow anyone without our consent to collect data
          remotely and in bulk.
        </p>
        <p className="py-2">
          You can get in contact with us if you have any concerns or questions about this here:&nbsp; legal@umuv.world
        </p>
      </div>

      <div className="py-6">
        <h3 className="text-blue-600">CHANGES TO THIS PRIVACY POLICY</h3>

        <p className="py-2">
          By using the Service, you consent to the collection and use of your information as set forth in this Privacy
          Policy. If we decide to change this Privacy Policy, which we may do at any time without notice to you, we will
          post those changes on this page.
        </p>
        <p className="py-2">
          By continuing to use the Service after we have posted the changes, you agree to and accept such changes. You
          should periodically check the Service for changes to this Privacy Policy.
        </p>
        <p className="py-2">
          If we make any material changes to this Privacy Policy, we will notify you of the changes by reasonable means,
          which could include notifications through the Services or via email. Please check this page frequently to see
          any updates or changes to this Privacy Policy.
        </p>
        <p className="py-2">
          If you do not agree to any part of this Privacy Policy, then we cannot provide this Service to you, and you
          should stop accessing UMUV and delete your account.
        </p>
        <p>Updated, July 2023</p>
      </div>

      <div className="py-6">
        <p className="mb-4">
          At <span className="font-semibold">umuv.world</span>, we value the privacy and security of our users. This
          privacy policy outlines the types of personal information we collect, how it is used, and how you can control
          the data you share with us. By using our website, you consent to the terms and practices described in this
          policy.
        </p>

        <h2 className="text-xl font-semibold mb-2">1. Information Collection</h2>
        <p className="mb-4">
          We may collect certain personal information, such as your name, email address, and demographic details when
          you interact with our website. This information is collected through voluntary submissions, such as when you
          fill out forms or subscribe to our newsletter.
        </p>

        <h2 className="text-xl font-semibold mb-2">2. Use of Information</h2>
        <p className="mb-4">
          We use the collected information to provide you with the best possible experience on umuv.world. This may
          include personalizing your user experience, improving our services, and sending relevant updates and
          notifications.
        </p>

        <h2 className="text-xl font-semibold mb-2">3. Data Sharing</h2>
        <p className="mb-4">
          We may share your personal information with trusted third-party service providers who assist us in operating
          our website and delivering our services. These providers are bound by strict confidentiality agreements and
          are prohibited from using your information for any other purpose.
        </p>

        <h2 className="text-xl font-semibold mb-2">4. Security Measures</h2>
        <p className="mb-4">
          We prioritize the security of your personal information and have implemented industry-standard measures to
          safeguard it. However, please note that no method of transmission over the internet or electronic storage is
          100% secure. We cannot guarantee absolute security but will make every effort to protect your data.
        </p>

        <h2 className="text-xl font-semibold mb-2">5. Cookies and Tracking Technologies</h2>
        <p className="mb-4">
          We use cookies and similar tracking technologies to enhance your browsing experience on umuv.world. These
          technologies allow us to analyze website traffic, personalize content, and improve overall functionality. You
          have the option to disable cookies through your browser settings, although this may limit certain features of
          our website.
        </p>

        <h2 className="text-xl font-semibold mb-2">6. Third-Party Links</h2>
        <p className="mb-4">
          Our website may contain links to third-party websites that are not owned or controlled by us. We are not
          responsible for the privacy practices or content of these websites and encourage you to review their
          respective privacy policies.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
