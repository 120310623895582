import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../lib/firebase.prod';

export default function useAuthListener() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')));

  useEffect(() => {
    const listener = onAuthStateChanged(auth, authUser => {
      if (authUser) {
        if (!user) {
          notifications.show({
            id: 'login-notif',
            withCloseButton: true,
            autoClose: 5000,
            title: `Welcome back ${authUser?.displayName ?? ''}`,
            message: `Happy you made it back!`,
            color: 'teal',
            loading: false,
            icon: <IconCheck size="1rem" />,
          });
        }
        localStorage.setItem('authUser', JSON.stringify(authUser));
        setUser(authUser);
      } else {
        localStorage.removeItem('authUser');
        setUser(null);
      }
    });

    return () => listener();
  }, [firestore]);

  return { user };
}
