// /* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { AdminRoute, IsUserRedirect, ProtectedRoute } from './helpers/routes';
import { useAuthListener } from './hooks';
import SignIn from './pages/SignIn';
import Register from './pages/register';
import Home from './pages/Home';
import Muvents from './pages/Muvents';
import Muvent from './pages/Muvent';
import PostMuvent from './pages/PostMuvent';
import Profile from './pages/Users/Profile';
import MainMenu from './components/Menu';
import EditMuvent from './pages/EditMuvent';
import NYBDC from './pages/Users/UserOfTheMonth/NYBDC';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Settings from './pages/Users/Settings';
import Notifications from './pages/Users/Notifications';
import Websites from './pages/Websites';
import BackOffice from './pages/Admin/BackOffice';
import ResetPassword from './pages/ResetPassword';
import AllUsers from './pages/Users/AllUsers';
import './App.css';
import Footer from './components/Footer';
import CookiePolicy from './pages/CookiePolicy';
import AboutUs from './pages/AboutUs';
import Partnerships from './pages/staticPages/Partnerships';
import Help from './pages/staticPages/Help';
import Donate from './pages/staticPages/Donate';
import Careers from './pages/staticPages/Careers';
import Sponsors from './pages/staticPages/Sponsors';
import Advertise from './pages/staticPages/Advertise';
import AppUpdates from './pages/AppUpdates';
import GoogleAds from './pages/GoogleAds';
import NerdStats from './pages/NerdStats';
import Articles from './pages/Articles';
import Article from './pages/Article';

export function App() {
  const { user } = useAuthListener();

  return (
    <div className="bg-white">
      <Router>
        <MainMenu user={user} />
        <Switch>
          <IsUserRedirect user={user} loggedInPath={ROUTES.MUVENTS} path={ROUTES.SIGN_IN}>
            <SignIn />
          </IsUserRedirect>

          <IsUserRedirect user={user} loggedInPath={ROUTES.MUVENTS} path={ROUTES.SIGN_UP}>
            <Register />
          </IsUserRedirect>

          <IsUserRedirect user={user} loggedInPath={ROUTES.MUVENTS} path={ROUTES.RESET_PATH}>
            <ResetPassword />
          </IsUserRedirect>

          <Route path={ROUTES.MUVENTS} exact>
            <Muvents />
          </Route>
          <Route path={ROUTES.ARTICLES} exact>
            <Articles />
          </Route>
          <Route path={ROUTES.ARTICLE} exact>
            <Article />
          </Route>

          <Route path={ROUTES.WEBSITES} exact>
            <Websites />
          </Route>

          <Route path={ROUTES.PRIVACY} exact>
            <PrivacyPolicy />
          </Route>

          <Route path={ROUTES.ABOUT_US} exact>
            <AboutUs />
          </Route>

          <Route path={ROUTES.PARTNERSHIPS} exact>
            <Partnerships />
          </Route>

          <Route path={ROUTES.HELP} exact>
            <Help />
          </Route>

          <Route path={ROUTES.COOKIE_POLICY} exact>
            <CookiePolicy />
          </Route>

          <Route path={ROUTES.TERMS_OF_SERVICE} exact>
            <TermsOfService />
          </Route>

          <Route path={ROUTES.DONATE} exact>
            <Donate />
          </Route>

          <Route path={ROUTES.CAREERS} exact>
            <Careers />
          </Route>

          <Route path={ROUTES.SPONSORS} exact>
            <Sponsors />
          </Route>
          <Route path={ROUTES.ADVERTISE} exact>
            <Advertise />
          </Route>

          <AdminRoute user={user} admin={user?.uid === 'uovFRo4FY7d3tYpRGEvJaZAdbvz1'} path={ROUTES.NERD_STATS} exact>
            <NerdStats />
          </AdminRoute>

          <Route path={ROUTES.APP_UPDATES} exact>
            <AppUpdates />
          </Route>

          <Route path={ROUTES.NYBDC_USER_OF_MONTH} exact>
            <NYBDC />
          </Route>

          <Route path={ROUTES.MUVENT} exact>
            <Muvent />
          </Route>
          <Route path={ROUTES.GOOGLE_ADS}>
            <GoogleAds />
          </Route>

          <Route path={ROUTES.USERS_ALL} exact>
            <AllUsers />
          </Route>

          <ProtectedRoute user={user} path={ROUTES.POST_MUVENT}>
            <PostMuvent />
          </ProtectedRoute>

          <ProtectedRoute user={user} path={ROUTES.EDIT_MUVENT} exact>
            <EditMuvent />
          </ProtectedRoute>

          <AdminRoute user={user} admin={user?.uid === 'uovFRo4FY7d3tYpRGEvJaZAdbvz1'} path={ROUTES.BO} exact>
            <BackOffice />
          </AdminRoute>

          <Route user={user} path={ROUTES.USER} exact>
            <Profile />
          </Route>

          <ProtectedRoute user={user} path={ROUTES.SETTINGS} exact>
            <Settings />
          </ProtectedRoute>

          <ProtectedRoute user={user} path={ROUTES.NOTIFICATIONS} exact>
            <Notifications />
          </ProtectedRoute>

          <IsUserRedirect user={user} loggedInPath={ROUTES.MUVENTS} path={ROUTES.HOME}>
            <Home />
          </IsUserRedirect>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
