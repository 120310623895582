import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTE from '../../constants/routes';
import Badge from '../Badge';
import { useEffect } from 'react';
import { firestore } from '../../lib/firebase.prod';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { useState } from 'react';

const Footer = () => {
  const [muventsStats, setMuventsStats] = useState();
  const [usersStats, setUsersStats] = useState();
  const [websitesStats, setWebsitesStats] = useState();

  useEffect(() => {
    const fetchStats = async () => {
      const snapshot = await getDocs(query(collection(firestore, 'stats')));

      const allContent = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));
      const dbStatObj = id => allContent.find(content => content.docId === id);

      setMuventsStats(dbStatObj('muvents')?.length);
      setUsersStats(dbStatObj('users')?.length);
      setWebsitesStats(dbStatObj('websites')?.length);
    };

    fetchStats();
  }, []);

  return (
    <footer className="bg-[rgb(22,22,58)] text-white pt-24">
      <div className="max-w-7xl mx-auto px-4 pb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <h4 className="text-lg font-semibold mb-4">
              <Link to={ROUTE.ABOUT_US} className="text-sm text-purple-300 cursor-pointer">
                ABOUT US
              </Link>
            </h4>

            <ul className="text-sm text-slate-200">
              <Link to={ROUTE.ABOUT_US} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">The Team, the Mission, the Vision</span>
              </Link>

              <Link to={ROUTE.PARTNERSHIPS} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">Partnerships</span>
              </Link>

              <Link to={ROUTE.CAREERS} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">Careers</span>
              </Link>
              <Link to={ROUTE.SPONSORS} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">Sponsors</span>
              </Link>
              <Link to={ROUTE.DONATE} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">Donate</span>
              </Link>
              <Link to={ROUTE.ADVERTISE} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">Advertise</span>
              </Link>
              <Link to={ROUTE.APP_UPDATES} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">App Updates</span>
              </Link>
            </ul>
          </div>

          <div>
            <h4 className="text-sm text-purple-300 cursor-pointer mb-4">CONTACT</h4>
            <ul className="text-sm text-slate-300">
              <li className="block mb-2">5842 Catalpa Ave, APT 2L</li>
              <li className="block mb-2">Ridgewood, NY 11385</li>
              <li className="block mb-2">Email: umuvworld@gmail.com</li>
              <Link to={ROUTE.HELP} className="block mb-2">
                <span className="text-sm text-slate-300 cursor-pointer">Help Page</span>
              </Link>
              <li className="block mb-2 cursor-pointer" onClick={() => tidioChatApi.open()}>
                Chat with us
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-sm text-purple-300 cursor-pointer mb-4">SOCIAL</h4>
            <ul className="text-sm text-slate-300">
              <li>
                <a href="https://www.facebook.com/umuvWorld/" target="_blank" rel="noreferrer">
                  Facebook
                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/umuvworld/" target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-sm text-purple-300 cursor-pointer mb-4">LEGAL</h4>
            <ul className="text-sm text-slate-300">
              <Link to={ROUTE.TERMS_OF_SERVICE}>
                {' '}
                <li className="block mb-2">Terms of Service</li>
              </Link>
              <Link to={ROUTE.PRIVACY}>
                {' '}
                <li className="block mb-2">Privacy Policy</li>
              </Link>
              <Link to={ROUTE.COOKIE_POLICY}>
                {' '}
                <li className="block mb-2">Cookie Policy</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-2 md:gap-10 py-10 border-t-[1px] border-slate-900 flex-col md:flex-row md:h-full">
        <Badge name="muvents" number={muventsStats} />
        <Badge name="users" number={usersStats} />
        <Badge name="websites" number={websitesStats} />
      </div>
    </footer>
  );
};

export default Footer;
