import styled from 'styled-components';
// import { colors } from "../../lib/theme";

export const MenuWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    list-style-type: none;
    text-align: center;
    width: 96%;
  }

  li {
    display: inline-block;
    min-width: 100px;
    margin: 4px;
    padding: 4px;
    color: white;
  }

  span.ant-menu-title-content* {
    font-family: 'Montserrat', sans-serif !important;
  }

  .ant-col.ant-col-20,
  .ant-col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 6px 14px;
    border-radius: 2px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
  }

  button {
    border: none;
    padding: 6px;
    border-radius: 2px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }

  .submenu {
    color: black;
  }
`;

export const MobileMenuUserProfileGlass = styled.div`
  background: rgba(173, 154, 236, 0.25);
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1) inset;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(30px);
`;
