// write a help page im React based on this content"
// Help Page
// Welcome to the Help Page! Here you will find answers to common questions, guides on how to use our website, and resources for additional support. If you can't find what you're looking for, feel free to contact us.

// Click on the "Sign Up" button at the top right corner of the homepage.
// Fill in the required information: username, email, and password.
// Verify your email address by clicking on the link sent to your inbox.
// Log in with your new credentials.
// Logging In
// Click on the "Log In" button at the top right corner of the homepage.
// Enter your email and password.
// Click "Submit" to access your account.
// Account Management
// Updating Profile Information
// Log in to your account.
// Navigate to "My Profile" from the user menu.
// Click on "Edit Profile" and update your information.
// Click "Save" to apply changes.
// Changing Your Password
// Log in to your account.
// Go to "Account Settings" from the user menu.
// Click on "Change Password."
// Enter your current password and your new password.
// Confirm the new password and click "Save."
// Deleting Your Account
// Log in to your account.
// Go to "Account Settings" from the user menu.
// Scroll down to the "Delete Account" section.
// Click "Delete Account" and confirm your decision.
// Using the Website
// Searching for Content
// Use the search bar at the top of the page.
// Enter keywords related to what you&apos;re looking for.
// Browse through the search results and click on the desired content.
// Posting Content
// Log in to your account.
// Navigate to the "Create Post" section from the user menu.
// Fill in the post details: title, description, and category.
// Click "Submit" to publish your post.
// Interacting with Other Users
// Comment on posts by clicking on the "Comment" button below the post.
// Like posts by clicking on the "Like" button.
// Send private messages through the "Messages" section in the user menu.
// Troubleshooting
// Password Reset
// Click on the "Forgot Password?" link on the login page.
// Enter your email address.
// Check your email for a password reset link.
// Follow the instructions to reset your password.
// Account Locked
// Ensure you&apos;re entering the correct password.
// If you&apos;ve tried too many times, wait for 15 minutes before trying again.
// If you still can&apos;t access your account, contact support.
// Page Not Loading
// Refresh the page.
// Clear your browser&apos;s cache and cookies.
// Try accessing the website using a different browser.
// Check your internet connection.
// Contact Support
// If you need further assistance, our support team is here to help.

// Support Email
// Email us at support@example.com. Please include a detailed description of your issue and your account information.

// Live Chat
// Click on the "Live Chat" button at the bottom right corner of the page to chat with a support representative.

// Community Forums
// Visit our Community Forums to ask questions and find answers from other users.

import React from 'react';

export default function Help() {
  return (
    <div className="bg-slate-900 flex justify-center py-10">
      <div className="w-full mx-20">
        <h2 className="text-3xl font-extrabold text-white text-center mb-20 mt-28">Welcome to UMUV Help Page</h2>
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <img
            src={'https://res.cloudinary.com/umuv/image/upload/v1631313513/UMUV/umuv_world.png'}
            alt="UMUV Help Page - The global dance platform"
            className="w-full h-80 object-cover"
          ></img>
          <div className="p-4">
            <h3 className="text-xl font-semibold text-purple-500 mb-2">Getting Started</h3>
            <p className="text-gray-700 my-4">
              Welcome to the Help Page! Here you will find answers to common questions, guides on how to use our
              website, and resources for additional support. If you can't find what you're looking for, feel free to
              contact us at umuvworld@gmail.com
            </p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Creating an Account</h3>
            <p className="text-gray-700 my-4">
              Click on the "Sign Up" button at the top right corner of the homepage. Fill in the required information:
              username, email, and password. Verify your email address by clicking on the link sent to your inbox. You
              will receive an email from us (umuvworld+info@gmail.com). Make sure to check the spam folder.Follow the
              instructions on the email and Log in with your new credentials.
            </p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Logging In</h3>
            <p className="text-gray-700 my-4">
              Click on the "Log In" button at the top right corner of the homepage. Enter your email and password. Click
              "Submit" to access your account.
            </p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Account Management</h3>
            <p className="text-gray-700 my-4">
              Updating Profile Information Log in to your account. Navigate to "My Profile" from the user menu. Click on
              "Edit icon" and update your information. Click "Save" to apply changes.
            </p>

            {/* <h3 className="text-xl font-semibold text-purple-500 mb-2">Changing Your Password</h3>
            <p className="text-gray-700 my-4">
              Log in to your account. Go to "Account Settings" from the user menu. Click on "Change Password." Enter
              your current password and your new password. Confirm the new password and click "Save."
            </p> */}

            {/* <h3 className="text-xl font-semibold text-purple-500 mb-2">Deleting Your Account</h3>
            <p className="text-gray-700 my-4">
              Log in to your account. Go to "Account Settings" from the user menu. Scroll down to the "Delete Account"
              section. Click "Delete Account" and confirm your decision.
            </p> */}

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Using the Website</h3>
            <p className="text-gray-700 my-4">
              Searching for Content Use the search bar at the top of the page. Enter keywords related to what you're
              looking for. Browse through the search results and click on the desired content.
            </p>
            {/* 
            <h3 className="text-xl font-semibold text-purple-500 mb-2">Posting Content</h3>
            <p className="text-gray-700 my-4">
              Log in to your account. Navigate to the "Create Post" section from the user menu. Fill in the post
              details: title, description, and category. Click "Submit" to publish your post.
            </p> */}

            {/* <h3 className="text-xl font-semibold text-purple-500 mb-2">Interacting with Other Users</h3>
            <p className="text-gray-700 my-4">
              Comment on posts by clicking on the "Comment" button below the post. Like posts by clicking on the "Like"
              button. Send private messages through the "Messages" section in the user menu.
            </p> */}

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Troubleshooting</h3>
            <p className="text-gray-700 my-4">
              Password Reset Click on the "Forgot Password?" link on the login page. Enter your email address. Check
              your email for a password reset link. Follow the instructions to reset your password.
            </p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Account Locked</h3>
            <p className="text-gray-700 my-4">
              Ensure you&apos;re entering the correct password. If you&apos;ve tried too many times, wait for 15 minutes
              before trying again. If you still can&apos;t access your account, contact support.
            </p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Page Not Loading</h3>
            <p className="text-gray-700 my-4">
              Refresh the page. Clear your browser&apos;s cache and cookies. Try accessing the website using a different
              browser. Check your internet connection.
            </p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Contact Support</h3>

            <p className="text-gray-700 my-4">If you need further assistance, our support team is here to help.</p>

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Support Email</h3>
            <p className="text-gray-700 my-4">
              Email us at umuvworld@gmail.com Please include a detailed description of your issue and your account
              information.
            </p>

            {/* <h3 className="text-xl font-semibold text-purple-500 mb-2">Live Chat</h3>
            <p className="text-gray-700 my-4">
              Click on the "Live Chat" button at the bottom right corner of the page to chat with a support
              representative.
            </p> */}

            <h3 className="text-xl font-semibold text-purple-500 mb-2">Community Forums</h3>
            <p className="text-gray-700 my-4">
              Visit our Community on{' '}
              <a
                href="https://www.facebook.com/umuvWorld/"
                target="_blank"
                rel="noreferrer"
                className="text-purple-500"
              >
                Facebook
              </a>{' '}
              to ask questions and find answers from other users.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
