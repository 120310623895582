import React, { Fragment, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { firestore } from '../lib/firebase.prod';
import { collection, getDocs, limit, query, orderBy } from 'firebase/firestore';
import { HomePageBG } from './styles';
import { format } from 'date-fns';
import { removeDashes, truncateString } from '../helpers/util-fns';
import { SIGN_UP } from '../constants/routes';

function Home() {
  const [muvents, setMuvents] = useState([]);
  const [loadingMuvents, setLoadingMuvents] = useState(false);

  const globalPlatformStyles =
    'font-montserrat font-extrabold text-5xl bg-clip-text m-4 text-purple-400 md:text-5xl xs:text-5xl';

  useEffect(() => {
    setLoadingMuvents(true);
    getDocs(query(collection(firestore, 'muvents'), orderBy('date', 'desc'), limit(30))).then(snapshot => {
      const firstMuvents = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));
      setMuvents(firstMuvents);
      setLoadingMuvents(false);
    });
  }, []);

  return (
    <div className="flex flex-col">
      <HomePageBG>
        <a
          href={SIGN_UP}
          className="md:text-purple-500 p-2 px-4 rounded-full xs:bg-purple-400 xs:text-white md:bg-white text-center text-xl bottom-14 absolute hover:shadow-lg hover:bg-purple-400 hover:text-white transition-all duration-300 ease-in-out"
        >
          SIGNUP NOW
        </a>
      </HomePageBG>

      {/* MUVENTS */}

      <div className="bg-white py-2 rounded-md">
        <div className="flex overflow-x-auto">
          <div className="flex-1 flex space-x-4 p-4 overflow-x-scroll">
            {muvents.map(muvent => {
              const { photoURL, date, start, slug, docId, thumbnail_300_200 } = muvent;

              const link = `muvents/${docId}`;

              return (
                <Link to={{ pathname: link, state: muvent }} key={docId}>
                  <div className="bg-slate-50 rounded-md w-[400px] shadow-slate-200 shadow-md cursor-pointer hover:shadow-none">
                    <div className="flex items-center flex-col">
                      <img
                        src={thumbnail_300_200 || photoURL}
                        alt={slug}
                        className="object-cover h-[220px] rounded-t-md pt-2"
                        width="380px"
                        height="200px"
                        about={slug}
                        loading="lazy"
                      />

                      <p className="text-slate-800 text-sm py-4">
                        {truncateString(removeDashes(slug), 40).toLocaleUpperCase()}
                      </p>
                      <p className="date-time text-slate-800 py-2">
                        {date ? format(date?.toDate(), 'MMM d, yyyy') : 'Online'}
                        {start && ' @ '}
                        {start && format(new Date(start?.toDate()), 'HH:mm')}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      {/* MUVENTS */}
    </div>
  );
}

export default Home;
