import React from 'react';

function Partnerships() {
  return (
    <div>
      <div className="bg-slate-900 py-16 flex justify-center">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-20 2xl:px-20">
          <h2 className="text-3xl font-extrabold text-white m-40">Our Partnerships</h2>

          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-8 m-20">
            {/* s */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={'https://res.cloudinary.com/umuv/image/upload/v1688506566/idanceContemporary.png'}
                alt="I Dance Contemporary"
                className="w-full h-80 object-cover"
              ></img>
              <div className="p-4">
                <h3 className="text-xl font-semibold text-purple-500 mb-2">I Dance Contemporary</h3>
                <p className="text-gray-700 my-4">
                  I Dance Contemporary Gallery is the first online gallery dedicated to art dance photography. With just
                  a few clicks, you can purchase dance art prints form the best artists photorgaphers form all over the
                  world.
                </p>

                <p className="text-gray-700 my-4">
                  I Dance Contemporary brings to light the artistry and uniqueness of dance and dancers.
                </p>

                <p className="text-gray-700 my-4">
                  I Dance Contemporary is about dancing in our contemporary world. It is about how people handle motion
                  to express themselves.
                </p>

                <a
                  href="https://idancecontemporary.gallery"
                  target="_blank"
                  className="text-purple-400"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={'https://res.cloudinary.com/umuv/image/upload/v1688507286/SDC.png'}
                alt="Oeiras Dance Academy"
                className="w-full h-80 object-cover"
              ></img>
              <div className="p-4">
                <h3 className="text-xl font-semibold text-purple-500 mb-2">Sublime Dance Company</h3>
                <p className="text-gray-700 my-4">
                  Sublime dance company was born in the summer of 2010 at Hunter college, NYC. It was inspired by the
                  gorgeous studios that Hunter offers, the wonderful dancers and a fantastic environment populated by
                  amazing artists, teachers, and mentors.
                </p>

                <p className="text-gray-700 my-4">
                  SDC continues to not only create & perform but also expand its educational programs mostly through
                  teaching contemporary dance master classNamees, regular ongoing classNamees for every level and age,
                  dance movement Therapy sessions, workshops and intensives.
                </p>
                <a href="https://sublimedancecompany.com/" target="_blank" rel="noreferrer" className="text-purple-400">
                  Learn more
                </a>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={'https://res.cloudinary.com/umuv/image/upload/v1688507007/oeirasDanceAcademy.png'}
                alt="Oeiras Dance Academy"
                className="w-full h-80 object-cover"
              ></img>
              <div className="p-4">
                <h3 className="text-xl font-semibold text-purple-500 mb-2">Oeiras Dance Academy</h3>
                <p className="text-gray-700 my-4">
                  A Oeiras Dance Academy é uma nova academia de dança que abriu no Centro Histórico de Oeiras.
                </p>

                <p className="text-gray-700 my-4">
                  A Academia nasceu para dar resposta a uma lacuna no ensino de dança em Oeiras pretendendo prestar um
                  serviço de proximidade e excelência através aulas de mais de 30 modalidades de dança de Segunda a
                  Domingo leccionadas por profissionais credenciados.
                </p>

                <p className="text-gray-700 my-4">
                  Venha conhecer a Oeiras Dance Academy e experimente uma aula gratuita.
                </p>
                <a href="https://www.oeirasdance.pt" target="_blank" rel="noreferrer" className="text-purple-400">
                  Learn more
                </a>
              </div>
            </div>

            {/* s */}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center flex-col bg-slate-900 py-20">
        <h1 className="text-white">Want to join UMUV as a Partner?</h1>
        <a href="mailto:umuvworld@gmail.com" target="_blank" className="text-purple-300" rel="noreferrer">
          Email us now!
        </a>
      </div>
    </div>
  );
}

export default Partnerships;
