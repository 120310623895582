import React from 'react';

const TermsOfService = () => {
  return (
    <div className=" m-20">
      <h1>Terms of Service</h1>
      <p>These are the terms of service.</p>

      <h3 className="text-center text-md leading-8 font-extrabold tracking-tight text-gray-900 sm:text-md my-4">
        Thanks for using UMUV.
      </h3>

      <p className="text-center text-md leading-8 font-extrabold tracking-tight text-gray-900 sm:text-md my-4">
        Please read these Terms carefully. By using UMUV or signing up for an
        account, you're agreeing to these Terms, which will result in a legal
        agreement between you and UMUV (“Agreement”). We'll start with the
        basics, including a few definitions that should help you understand
        these Terms.
      </p>
      <p className="text-center text-md leading-8 font-extrabold tracking-tight text-gray-900 sm:text-md my-4">
        UMUV (“UMUV,” “we,” or “us”) is an online global dance platform (the
        “Service”) offered through the URL www.umuv.world (we'll refer to it as
        the “Website”) that allows you to, among other things, search, create,
        manage and share information on dance events.
      </p>

      <p className="text-center text-md leading-8 font-extrabold tracking-tight text-gray-900 sm:text-md my-4">
        If you don't agree to these Terms, you must immediately discontinue your
        use of the Service. UMUV uses Google Maps to provide certain features of
        the Service, and, as a result, we are contractually obligated to make
        our Members aware of certain terms related to the use of such features.
        Therefore, you acknowledge and agree that by signing up for an account
        and using the Service, you are also bound by the Google Maps (including
        the Google Privacy Policy).
      </p>

      <p className="text-center text-md leading-8 font-extrabold tracking-tight text-gray-900 sm:text-md my-4">
        If you have any questions about our Terms, feel free to contact us.
      </p>
    </div>
  );
};

export default TermsOfService;
