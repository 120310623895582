import React, { useEffect, useRef, useState } from 'react';
import { logout } from '../../lib/firebase.prod';
import { MenuWrapper, MobileMenuUserProfileGlass } from './styles';
import Avatar from 'antd/lib/avatar/avatar';
import { toDashes } from '../../helpers/util-fns';
import { useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Drawer } from '@mantine/core';
import { useMediaQuery } from 'beautiful-react-hooks';
import { isMobile } from 'react-device-detect';
import { gsap } from 'gsap';

const MainMenu = ({ user }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  gsap.config({
    autoSleep: 60,
    force3D: false,
    nullTargetWarn: false,
    trialWarn: false,
    units: { left: '%', top: '%', rotation: 'rad' },
  });

  const homeBtn = useRef(null);
  const muventsBtn = useRef(null);
  const websitesBtn = useRef(null);
  const usersBtn = useRef(null);
  const articlesBtn = useRef(null);
  const loginBtn = useRef(null);
  const signupBtn = useRef(null);
  const postMuventBtn = useRef(null);
  const home = gsap.timeline({ paused: true });
  const muvents = gsap.timeline({ paused: true });
  const users = gsap.timeline({ paused: true });
  const websites = gsap.timeline({ paused: true });
  const articles = gsap.timeline({ paused: true });
  const login = gsap.timeline({ paused: true });
  const signup = gsap.timeline({ paused: true });
  const postMuvent = gsap.timeline({ paused: true });

  const [openedDrawer, setOpenedDrawer] = useState(false);

  async function handleLogout() {
    await logout();
    history.push('/');
  }

  function openMenu() {
    return gsap
      .timeline()
      .to('.open-menu', {
        opacity: 1,
        width: '100%',
        autoAlpha: 1,
        zIndex: 9999,
      })
      .to(
        [
          homeBtn.current,
          muventsBtn.current,
          websitesBtn.current,
          usersBtn.current,
          loginBtn.current,
          signupBtn.current,
          postMuventBtn.current,
        ],
        {
          opacity: 1,
          y: 20,
          stagger: 0.03,
          ease: 'BackOut',
        },
      );
  }

  function closeMenu() {
    return gsap
      .timeline()
      .to(
        [
          homeBtn.current,
          muventsBtn.current,
          websitesBtn.current,
          usersBtn.current,
          loginBtn.current,
          signupBtn.current,
          postMuventBtn.current,
        ],
        {
          opacity: 0,
          y: -20,
          stagger: 0.05,
          ease: 'easeOut',
        },
      )
      .to('.open-menu', { opacity: 0, width: '0%', autoAlpha: 0 });
  }

  const isMobileSize = useMediaQuery('(max-width: 780px');
  // const isLaptopMin = useMediaQuery("(min-device-width: 1200px)");
  // const isLaptopMax = useMediaQuery("(max-device-width: 1800px)");

  useEffect(() => {
    const animProps = { x: 20, ease: 'easeOut', color: 'black' };
    home.to(homeBtn.current, animProps).addPause();
    muvents.to(muventsBtn.current, animProps).addPause();
    websites.to(websitesBtn.current, animProps).addPause();
    users.to(usersBtn.current, animProps).addPause();
    articles.to(articlesBtn.current, animProps).addPause();
    postMuvent.to(postMuventBtn.current, animProps).addPause();
    login.to(loginBtn.current, { x: 20, ease: 'easeOut', color: 'white' }).addPause();
    signup.to(signupBtn.current, { x: 20, ease: 'easeOut', color: 'white' }).addPause();
  }, [home, muvents, websites, login, signup, postMuvent, pathname]);

  return (
    <>
      {isMobile || isMobileSize ? (
        <div className="">
          <div className="bg-white w-full fixed top-0">
            <div
              className="menu fixed right-6 top-6 grid cursor-pointer grid-cols-1 place-items-end gap-1 z-50"
              onClick={openMenu}
            >
              {[...Array(3).keys()].map(() => {
                return <div key={Math.random() * 1000} className="box-menu box z-50 h-0.5 w-10 bg-indigo-500"></div>;
              })}
            </div>
          </div>

          <div className="absolute shadow-sm top-16 align-items-center flex h-full flex-col overflow-y-scroll bg-gradient-to-r bg-purple-400 pb-20 pt-36 text-center open-menu opacity-0">
            <div
              className="absolute top-14 right-14 z-20 cursor-pointer font-gruppo text-6xl text-white rounded-full w-16 h-16"
              onClick={closeMenu}
            >
              <span className="closeMenu">X</span>
            </div>

            <button
              className="sticky px-3 py-4 m-2 text-6xl text-white font-montserrat hover:border-transparent focus:outline-none"
              ref={homeBtn}
              onClick={
                pathname !== '/'
                  ? () => {
                      closeMenu();
                      home.reverse();
                      history.push('/');
                    }
                  : () => {}
              }
              onMouseOver={() => home.play()}
              onMouseLeave={() => home.reverse()}
            >
              {' '}
              HOME
            </button>

            <button
              className="sticky px-3 py-4 m-2 text-6xl text-white font-montserrat hover:border-transparent focus:outline-none"
              ref={muventsBtn}
              onClick={() => {
                closeMenu();
                muvents.reverse();
                history.push(ROUTES.MUVENTS);
              }}
              onMouseOver={() => muvents.play()}
              onMouseLeave={() => muvents.reverse()}
            >
              {' '}
              MUVENTS
            </button>

            {user ? (
              <button
                ref={postMuventBtn}
                onClick={() => {
                  closeMenu();
                  postMuvent.reverse();
                  history.push(ROUTES.POST_MUVENT);
                }}
                onMouseOver={() => postMuvent.play()}
                onMouseLeave={() => postMuvent.reverse()}
                className="px-3 py-1 mb-6 text-2xl text-white font-montserrat hover:text-black border-2 border-purple-300 w-2/4 m-auto rounded-full"
              >
                <span></span>
                POST MUVENT
              </button>
            ) : null}

            <button
              className="sticky px-3 py-4 m-2 text-6xl text-white font-montserrat hover:border-transparent focus:outline-none"
              ref={usersBtn}
              onClick={() => {
                closeMenu();
                users.reverse();
                history.push(ROUTES.USERS_ALL);
              }}
              onMouseOver={() => users.play()}
              onMouseLeave={() => users.reverse()}
            >
              {' '}
              USERS
            </button>
            <button
              className="sticky px-3 py-4 m-2 text-6xl text-white font-montserrat hover:border-transparent focus:outline-none"
              ref={websitesBtn}
              onClick={() => {
                closeMenu();
                websites.reverse();
                history.push(ROUTES.WEBSITES);
              }}
              onMouseOver={() => websites.play()}
              onMouseLeave={() => websites.reverse()}
            >
              {' '}
              WEBSITES
            </button>
            <button
              className="sticky px-3 py-4 m-2 text-6xl text-white font-montserrat hover:border-transparent focus:outline-none"
              ref={articlesBtn}
              onClick={() => {
                closeMenu();
                articles.reverse();
                history.push(ROUTES.ARTICLES);
              }}
              onMouseOver={() => articles.play()}
              onMouseLeave={() => articles.reverse()}
            >
              {' '}
              ARTICLES
            </button>

            {user ? (
              <>
                <MobileMenuUserProfileGlass className="flex justify-center flex-col mt-20 items-center p-10 m-10">
                  <button
                    className="cursor-pointer"
                    // onClick={() => setOpenedDrawer(true)}
                  >
                    <img
                      src={
                        user?.photoURL || 'https://res.cloudinary.com/umuv/image/upload/v1622919587/UMUV/umuv_user1.jpg'
                      }
                      alt="umuv-user"
                      className="w-20 h-20 rounded-full bg-center"
                    ></img>
                  </button>

                  <br />
                  <br />

                  <a
                    href={`/users/${user.uid}/`}
                    // href={`/users/${toDashes(user.displayName)}/`}
                    onClick={() => {}}
                    className="px-3 py-2 text-2xl text-white font-montserrat hover:text-black"
                  >
                    Profile
                  </a>
                  <a
                    href={`/users/${toDashes(user.displayName)}/settings/`}
                    onClick={() => {}}
                    className="px-3 py-2 text-2xl text-white font-montserrat hover:text-black"
                  >
                    Settings
                  </a>
                  {user?.uid === 'uovFRo4FY7d3tYpRGEvJaZAdbvz1' ? (
                    <a href={ROUTES.BO} className="px-3 py-2 text-2xl text-white font-montserrat hover:text-black">
                      Back Office
                    </a>
                  ) : null}

                  <a
                    href="/"
                    onClick={handleLogout}
                    className="sticky px-4 py-2 mt-20 text-2xl text-white font-montserrat focus:outline-none border-2 rounded-full hover:bg-gray-800 hover:text-white"
                  >
                    {' '}
                    LOGOUT
                  </a>
                </MobileMenuUserProfileGlass>
              </>
            ) : null}

            {!user ? (
              <div className="flex gap-6 justify-center xs:flex-col md:flex-row xs:p-20">
                <button
                  className="sticky px-6 py-4 m-2 text-3xl text-white font-montserrat bg-slate-800 rounded-full hover:bg-slate-900"
                  ref={loginBtn}
                  onClick={() => {
                    closeMenu();
                    login.reverse();
                    history.push(ROUTES.SIGN_IN);
                  }}
                  onMouseOver={() => login.play()}
                  onMouseLeave={() => login.reverse()}
                >
                  {' '}
                  LOGIN
                </button>

                <button
                  className="sticky px-6 py-4 m-2 text-3xl text-white font-montserrat bg-slate-800 rounded-full hover:bg-slate-900"
                  ref={signupBtn}
                  onClick={() => {
                    closeMenu();
                    signup.reverse();
                    history.push(ROUTES.SIGN_UP);
                  }}
                  onMouseOver={() => signup.play()}
                  onMouseLeave={() => signup.reverse()}
                >
                  {' '}
                  SIGNUP
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <MenuWrapper className="z-50 sticky">
        {!(isMobile || isMobileSize) ? (
          <ul className="flex justify-center items-center shadow-sm flex-wrap fixed top-2 bg-purple-100/80 rounded-full">
            <li className="absolute left-0">
              <Avatar
                size={40}
                src="https://res.cloudinary.com/umuv/image/upload/v1622639701/UMUV/umuv_logo.jpg"
                onClick={() => history.push('/home')}
                style={{ cursor: 'pointer' }}
              />
            </li>
            <li className="text-gray-800">
              <a className="text-slate-800 hover:text-purple-600" href="/muvents">
                Muvents
              </a>
            </li>
            <li className="text-slate-800">
              <a className="text-slate-800 hover:text-purple-600" href="/websites">
                Websites
              </a>
            </li>
            <li className="text-slate-800">
              <a className="text-slate-800 hover:text-purple-600" href={ROUTES.USERS_ALL}>
                Users
              </a>
            </li>
            <li className="text-slate-800">
              <a className="text-slate-800 hover:text-purple-600" href="/dance-articles">
                Articles
              </a>
            </li>
            {user ? (
              <li className="px-4 py-2 rounded-full bg-slate-800 hover:bg-purple-200">
                <a className="text-white hover:text-black" href="/create-muvent ">
                  POST EVENT
                </a>
              </li>
            ) : null}
            {!user && (
              <>
                <li className="rounded-full bg-purple-400 hover:bg-slate-800">
                  <a className="text-white" href="/login">
                    LOGIN
                  </a>
                </li>
                <li className="rounded-full bg-purple-400 hover:bg-slate-800">
                  <a className="text-white" href="/signup">
                    SIGNUP
                  </a>
                </li>
              </>
            )}
            {user ? (
              <li>
                <button className="w-16 cursor-pointer" onClick={() => setOpenedDrawer(true)}>
                  <img
                    src={
                      user?.photoURL || 'https://res.cloudinary.com/umuv/image/upload/v1622919587/UMUV/umuv_user1.jpg'
                    }
                    alt={`${user.displayName} profile image`}
                    className="w-8 h-8 rounded-full bg-center"
                  ></img>
                </button>
              </li>
            ) : null}
            {user && openedDrawer ? (
              <Drawer
                opened={openedDrawer}
                onClose={() => setOpenedDrawer(false)}
                padding="md"
                size="xl"
                position="right"
              >
                <div className={`rounded-sm py-2 block w-full bg-gradient-to-r from-pink-100 to-purple-300`}>
                  <a
                    href={`/users/${user.uid}/`}
                    onClick={() => {}}
                    className="block px-4 py-2 text-gray-900 cursor-pointer hover:text-gray-900 mb-2"
                  >
                    Profile
                  </a>
                  <a
                    href={`/users/${toDashes(user.displayName)}/settings/`}
                    className="block px-4 py-2 text-gray-900 cursor-pointer hover:text-gray-900 mb-2"
                  >
                    Settings
                  </a>

                  {user?.uid === 'uovFRo4FY7d3tYpRGEvJaZAdbvz1' ? (
                    <a
                      href={ROUTES.BO}
                      className="block px-4 py-2 text-gray-900 cursor-pointer hover:text-gray-900 mb-2"
                    >
                      BackOffice
                    </a>
                  ) : null}

                  <div className="border-t-2 border-pink-100"></div>
                  <a
                    href="/"
                    onClick={handleLogout}
                    className="block px-4 py-2 text-gray-900 cursor-pointer hover:text-gray-900 mb-2 mt-4"
                  >
                    Logout
                  </a>
                </div>
              </Drawer>
            ) : null}
          </ul>
        ) : null}
      </MenuWrapper>
    </>
  );
};

export default MainMenu;
