import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const Videos = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    margin: 40px;
  }
`;

const NYBDC = () => {
  return (
    <>
      <div className="flex mt-40 flex-col justify-center">
        <h2 className="text-purple-400 text-2xl text-center">NEW YORK BAROQUE DANCE COMPANY</h2>
        <br></br>

        <div className="text-center flex justify-center my-10">
          <ReactPlayer url="https://www.youtube.com/watch?v=C4e9n6_IOic" width="440px" height="260px" />
        </div>
        <div className="text-center flex justify-center my-10">
          <img
            className="text-center"
            alt="nybdc"
            src="https://res.cloudinary.com/umuv/image/upload/v1699099311/UMUV/users_of_the_month/NYBDC/nybdc.jpg"
          ></img>
        </div>

        <div class="w-full">
          <div class="flex flex-col w-1/2 mx-auto gap-10">
            <h2 className="text-lg text-purple-400">
              <b>A bit of history</b>
            </h2>

            <p>
              In 1976, Catherine Turocy asked her fellow Ohio State alumna Ann Jacoby to join her in forming the New
              York Baroque Dance Company. Turocy and Jacoby sought to bridge the gap between scholar and performer by
              enlisting dancers from New York’s modern and ballet companies, reconstructing a full evening’s worth of
              baroque dances, and seeing who might show up.
            </p>
            <p>
              They invited the young harpsichordist and conductor James Richman to lead his ensemble, Concert Royal, in
              providing the evening’s music. Turocy recalls that “hearing some of the music for the first time in tech
              rehearsals, wearing the heavy costumes, the tight corsets, performing in the restricted sightlines of
              masks…we felt intoxicated.
            </p>
            <p>
              We were naive to the difficulties of early music show biz!” The audience and critics who attended the
              company’s initial concert – most of them curious and unsure of what to expect – left the theater and
              started talking. The company was widely hailed for infusing baroque materials with a new vitality, and
              this reflected Turocy and Jacoby’s research prerogative of consulting not only Feuillet notations, but
              also dance treatises, paintings, acting manuals, the journals of choreographers, and the letters written
              by people who had attended original productions.
            </p>
            <p>
              Today the company is run by Catherine Turocy, Artistic Director as well as Caroline Copeland and Sarah
              Edgar who are both Associate Directors.
            </p>
          </div>
        </div>

        <div class="w-full">
          <div class="flex flex-col w-1/2 mx-auto gap-10">
            <p>
              <span className="first_word">The </span>
              NYBDC is a leading force in the revival of 17th and 18th century ballet, challenging aesthetic conventions
              and bringing forgotten masterpieces to new audiences in what The Guardian has called “a whirlwind of
              desperately needed fresh air.”
            </p>
            <p>
              We offer monthly classes, Dance of the Month, at Mark Morris Dance Center every first Saturday of the
              month. These are open classes and you can drop in any month. The general public to seasoned dancers
              discover the minuet, the chaconne, the sarabande as well as contredanses and period gesture and posture.
            </p>
            <p>
              We offer a chamber music and dance concert which displays theatrical noble and comic dances as well as
              dances for the ballroom and can be tailored for the size of the venue and the interests of the presenter.
            </p>
            <p>
              We love to collaborate on developing concerts, operas, ballets, site specific works and special events.
              With our company of dancers and choreographers we can do small or very large productions. Our vast costume
              stock of over 100 costumes is conveniently available for any productions in which we are hired.
            </p>
            <p>
              Every summer we offer an historical dance intensive to professionals and amateurs, exploring the many eras
              of dance from the Renaissance to today and looking at specific questions across time. This year we are
              looking at Historical Dance at Play: www.historical.dance
            </p>
            <p>
              Our artistic director, Catherine Turocy, is available for master classes and lectures. She has been lauded
              with numerous awards both national and international. She holds a BESSIE from New York and an IZZIE from
              San Francisco. The French Government decorated her as a knight in the Order of Arts and Letters for her
              dedication and cutting edge work in Baroque dance as performer, choreographer, stage director and teacher.
              She was the first historical artist to receive a Dance Film Award, for the creation of the video, The Art
              of Dancing: An Introduction to Baroque Dance. As of this date she has choreographed 78 Baroque opera
              productions and in over half of these she was also the stage director and often the principal solo dancer.
              Outside of her opera work she has created 56 ballets including Les Petits Riens by Mozart and The Four
              Seasons by Vivaldi.
            </p>
            <br></br>
            <br></br>
          </div>
        </div>

        <div class="w-full">
          <div class="flex flex-col w-1/2 mx-auto gap-10">
            <h2 className="history">
              <em>Who dances at NYBDC?</em>
            </h2>
            <p>
              <span className="first_word">A</span>
              number of dancers have come and gone from the company in its 42-year history, and the current group is
              notable for its connections to the world of contemporary dance as well as for its immersion in Turocy and
              Jacoby’s model of the “scholar-performer.” Many of the dancers are fluent in Feuillet notation and have
              become influential presences in the early dance scene, teaching and guesting at universities across the
              country.
            </p>
            <p>
              The company’s presence has been of wide-reaching consequence: there are few people working in the field,
              especially in the United States, who cannot trace their lineage to the NYBDC.
            </p>
          </div>
        </div>

        <div class="w-full">
          <div class="flex flex-col w-1/2 mx-auto gap-10">
            <p>Some of the dancers of NYBDC on UMUV:</p>
            <div className="col-md-4 col-lg-4 umuv-nybdc-dancer">
              <p>Olsi Gjeci</p>
              <p>Caroline Copeland</p>
              <p>Carly Fox Horton</p>
              <p>Mathew Ting</p>
              <p>Andrew Trego</p>
              <p>Alexis Silver</p>
            </div>
          </div>
        </div>

        <div class="w-full">
          <div class="flex flex-col w-1/2 mx-auto gap-10">
            <br></br>
            <h2 className="history">
              <em>Who teaches at NYBDC?</em>
            </h2>
            <p>
              <span className="first_word">The</span>
              older members of the company and our directors are qualified and experienced teachers. They include
              Catherine Turocy, Caroline Copeland, Sarah Edgar, Rachel List, Ani Udovicki and Meggi Sweeney Smith.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h2 className="history">
              <em>Mission, values, vision</em>
            </h2>
            <p>
              The New York Baroque Dance Company is a professional dance company dedicated to promoting the advancement
              of public knowledge of 17th/18th century dance, the foundation of western social and theatrical styles.
              With the premise that history informs and inspires our contemporary world, the NYBDC researches,
              reconstructs, reimagines and presents rare works as well as newly created works inspired by history, thus
              ensuring our heritage has a presence in the future of dance.
            </p>
            <p>
              How Do We Serve Dance? The company performs at the highest level of artistic excellence, nationally and
              internationally, in concerts, fully staged period opera and ballet productions, neo-Baroque projects and
              free street performances. An important part of our work is sharing our passion with the general public.
              Maintaining an online vimeo site with documentation of our workshops exploring seminal choreographies; as
              well as a blog on our website, both free and accessible to the public, helps us to reach thousands of
              people from diverse cultural and economic backgrounds.
            </p>
            <p>
              Since 1976 the NYBDC has mentored dancers in their “dancestry” as performers, choreographers, directors,
              researchers and teachers, thus enriching the future with the vibrant legacy of our dance heritage.
            </p>
            <p>
              Dedicated to expanding dance’s presence within the other arts and sciences, the NYBDC has a history of
              unusual collaborations such as our online video lecture tying together Fibonacci sequences with dance and
              garden design.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h2 className="history">
              <em>Evolution and the Community</em>
            </h2>
            <p>
              With a company of 8 core dancers and 12 auxiliary dancers, working with a notation system allows them to
              put a concert together within 3 days, much as a music group can sight read music and perform. Turocy and
              members of the company also create new ballets in the Baroque style/context in the same way a ballet
              choreographer uses steps to create a new work, using the same process to explore poetic ideas and
              challenge the talents of the performers. Literally taking the past into the future they also work in the
              new genre of New Baroque dance which uses period steps and concepts in a contemporary context. (Company
              XIV directed by Austin McCormick is a good example of this new burgeoning form).
            </p>
            <p>
              Today, work has expanded internationally with guest appearances and over 70 opera- ballets, numerous
              theatrical works and site specific creations in repertoire. Our dance heritage lives on through the legacy
              of the NYBDC, a living resource for dancers, artists and scholars.
            </p>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h2 className="history">
              <em>What Dancers Say</em>
            </h2>
            <p>From Sade Warner, young adult dancer:</p>
            <p>
              <em>
                “Ballet has endured and evolved over the decades and it is the foundation for many other dance forms and
                techniques. But what was the foundation for ballet? This workshop is such a remarkable opportunity study
                from the best and to learn the history behind the steps we do everyday. Dancing ballet is not only
                enlivening but it is truly special to know that you are continuing a tradition that is full of history.
                That is a thrill that I want to share with my students. “
              </em>
            </p>
            <br></br>
            <p>From Marissa Castillo, somatic ballet teacher:</p>
            <p>
              <em>
                “I feel that historical dance is a major component for understanding today’s dance, especially classical
                ballet. Where did the steps come from? How did they develop the ballet technique we employ today? This
                all stems from Baroque and also Renaissance dances. I feel it is critical as my role as a dance educator
                to pass this knowledge onto my students. I also see it as a way to help students understand that ballet
                didn’t just all of a sudden appear but it comes from a history and lineage worthy of study and
                examination.”
              </em>
            </p>
            <br></br>
            <br></br>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Videos>
          <div>
            <ReactPlayer url="https://www.youtube.com/watch?v=hMdsQtQ4IlU" width="440px" height="260px" />
          </div>
          <div>
            <ReactPlayer url="https://www.youtube.com/watch?v=C4e9n6_IOic" width="440px" height="260px" />
          </div>
          <div>
            <ReactPlayer url="https://www.youtube.com/watch?v=iliuLQAVmag" width="440px" height="260px" />
          </div>
        </Videos>
      </div>
    </>
  );
};

export default NYBDC;
