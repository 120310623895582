import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import { Edit, Trash } from 'tabler-icons-react';
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from 'react-share';
import { IconCheck, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { Heart } from 'tabler-icons-react';
import { SpinningOrbitLoader } from 'react-loaders-kit';
import {
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore';

import { deleteDocument, firestore } from '../lib/firebase.prod';
import * as ROUTES from '../constants/routes';
import { useAuthListener } from '../hooks';
import { format } from 'date-fns';
import CustomDivider from '../components/CustomDivider';
import Card from '../components/Card';
import * as S from './styles';
import muventDefaultBG from '../images/muvent-default-bg.jpg';
// import AdComponent from './Admin/AdComponent';

const Muvent = () => {
  const { slug } = useParams();
  const { user } = useAuthListener();
  const history = useHistory();
  const loaderProps = { loading: true, size: 120 };

  const [muvent, setMuvent] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [likes, setLikes] = useState([]);
  const [liked, setLiked] = useState(null);
  const [categoryMuvents, setCategoryMuvents] = useState([]);
  const [muventPhotoURL, setMuventPhotoURL] = useState('');

  useEffect(() => {
    const fetchMuvent = async () => {
      setLoading(true);
      const docRef = doc(firestore, 'muvents', slug);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setMuvent(docSnap.data());
        if (docSnap.data().photoURL) {
          setMuventPhotoURL(docSnap.data().photoURL);
        } else {
          setMuventPhotoURL(muventDefaultBG);
        }
        setLoading(false);
      } else {
        notifications.show({
          id: 'muvent-smth-went-wrong',
          withCloseButton: true,
          autoClose: 5000,
          title: 'Something went wrong.',
          message: 'We could not retrieve your muvent data. Please ask for assistance',
          color: 'red',
          loading: false,
          icon: <IconX size="1rem" />,
        });
        history.push('/muvents');
      }
    };
    fetchMuvent();
  }, [slug, firestore]);

  const muventsOfSameCategoryCall = useMemo(() => {
    if (!muvent?.category) return null;
    return query(
      collection(firestore, 'muvents'),
      where('category', '==', muvent?.category),
      orderBy('date', 'desc'),
      limit(20),
    );
  }, [muvent?.category]);

  useEffect(() => {
    const fetchCategoryMuvents = async () => {
      if (muventsOfSameCategoryCall) {
        const snapshot = await getDocs(muventsOfSameCategoryCall);
        const sameCategoryMuvents = snapshot.docs.map(contentObj => ({
          ...contentObj.data(),
          docId: contentObj.id,
        }));
        const filteredMuvents = sameCategoryMuvents.filter(muvent => muvent.docId !== slug);
        setCategoryMuvents(filteredMuvents);
      }
    };
    fetchCategoryMuvents();
  }, [muventsOfSameCategoryCall, slug]);

  useEffect(() => {
    const likesCall = query(collection(firestore, 'muvents', slug, 'likes'));
    const fetchLikes = async () => {
      const querySnapshot = await getDocs(likesCall);

      const likes = querySnapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));

      setLikes(likes);

      if (user) {
        setLiked(likes.some(({ docId }) => docId === user?.uid));
      }
    };
    fetchLikes();
  }, [liked, slug]);

  const {
    title,
    description,
    photoURL,
    creator_name,
    address_1,
    address_2,
    video_link,
    website_link,
    ticket_link,
    application_form_link,
    more_info,
    ownerId,
    category,
    city,
    country,
    thumbnail_300_200,
  } = muvent || history.location.state;

  const hasAtLeastOneLink = ticket_link || website_link || application_form_link || video_link;

  function isLikedByCurrentUser() {
    if (!user) return false;
    return likes.some(({ docId }) => docId === user?.uid);
  }

  async function handleDelete(evt) {
    evt.preventDefault();
    setDeleting(true);

    try {
      deleteDocument({ collection: 'muvents', docID: slug, photoURL, thumbnailURL: thumbnail_300_200 });
      notifications.show({
        id: 'delete-muvent',
        withCloseButton: true,
        autoClose: 5000,
        title: `Successfully deleted ${title}`,
        message: `Your ${category} was successfully deleted!`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });

      setTimeout(() => {
        history.push(ROUTES.MUVENTS);
      }, 1000);
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
    }
  }

  function handleEdit(evt) {
    evt.preventDefault();
    history.push(`/muvents/${slug}/edit`);
  }

  async function handleLike() {
    const like = { likedByUserName: user.displayName, likedByUserEmail: user.email };
    const likeRef = doc(firestore, 'muvents', slug, 'likes', user.uid);

    await setDoc(likeRef, like).then(() => {
      setLiked(true);

      if (muvent.ownerId !== user.uid) {
        const notifData = {
          title: title,
          muvent_category: category,
          muvent_url: `https://umuv.world/muvents/${slug}`,
          likedByName: user.displayName || 'unknown',
          likedByEmail: user.email || 'unknown',
          muventOwnerId: ownerId,
        };
        addDoc(collection(firestore, 'email_notif_new_like'), notifData);
      }
    });
  }

  async function handleUnlike() {
    const likeRef = doc(firestore, 'muvents', slug, 'likes', user.uid);
    await deleteDoc(likeRef)
      .then(() => setLiked(false))
      .catch(() => {});
  }

  function ShareButtons() {
    return (
      <div className="flex justify-center items-center">
        <span className="italic">share: </span>
        <TwitterShareButton
          title={title}
          via={'UMUV'}
          hashtags={['me', 'dance']}
          url={`https://umuv.world/muvents/${slug}`}
        >
          <TwitterIcon size={26} round={true} className="mx-2" />
        </TwitterShareButton>

        <FacebookShareButton quote={title} hashtag={'umuv'} url={`https://umuv.world/muvents/${slug}`}>
          <FacebookIcon size={26} round={true} className="x-2" />
        </FacebookShareButton>
      </div>
    );
  }

  function MuventLink({ link, name }) {
    const linkProps = { target: '_blank', rel: 'noopener noreferrer' };
    if (!link?.length) return null;
    return (
      <a href={link} {...linkProps}>
        {name} Link
      </a>
    );
  }

  if (loading) {
    return (
      <S.BrowseWrapper>
        <S.Spinner>
          <SpinningOrbitLoader {...loaderProps} />
        </S.Spinner>
      </S.BrowseWrapper>
    );
  }

  return (
    <div className="flex flex-row mt-20">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`UMUV - ${title}`}</title>
        <link rel="canonical" href={`https://umuv.world/muvents/${slug}`} />
      </Helmet>

      <div className="lg:w-full md:w-full sm:w-full xl:w-full xs:w-full">
        <S.MuventContainer>
          <S.MuventImage alt={`${muvent.title} image`} src={muventPhotoURL} />

          <S.MuventLikeAndShare>
            <div className=""></div>

            <div className="flex justify-center items-center hover:fill-red-500">
              {isLikedByCurrentUser() ? (
                <Heart size={30} strokeWidth={1} color={'#FF0000'} onClick={user ? handleUnlike : null} fill="red" />
              ) : (
                <Heart size={36} strokeWidth={1} color={'#2d8f86'} onClick={user ? handleLike : null} />
              )}
              {likes?.length ? (
                <span className="px-2">
                  {likes?.length} {likes.length > 1 ? 'likes' : 'like'}
                </span>
              ) : null}
            </div>

            <div className="flex justify-end mr-1">
              <ShareButtons />
            </div>
          </S.MuventLikeAndShare>

          <S.MuventTitle>{title}</S.MuventTitle>
          {!!creator_name && (
            <S.MuventCreator>
              <Link to={`/users/${ownerId}`} className="block mb-2 text-purple-500 underline">
                by <br />
                {creator_name}
              </Link>
            </S.MuventCreator>
          )}
          <div className="flex justify-center items-center mt-2">
            <span className="inline-block bg-purple-400 text-white rounded-full py-1 px-2 text-xs text-center">
              {category}
            </span>
          </div>

          {/*-------------- DATE & PLACE SECTION ------------------*/}
          <div className="my-10"></div>
          <CustomDivider title="DATE & PLACE" />
          <div className="flex justify-center flex-col text-center">
            {muvent?.date ? (
              <p>
                {format(muvent?.date.toDate(), 'MMM d')}
                {muvent?.start ? <span> @ </span> : null}
                {muvent?.start ? dayjs(muvent?.start?.toDate()).format('HH:mm') : null}
              </p>
            ) : null}

            {address_1 ? <p>{address_1}</p> : null}
            {address_2 ? <p>{address_2}</p> : null}
            <p>
              {city ? <span>{city}</span> : null},&nbsp;&nbsp;
              {country ? <span>{country}</span> : null}
            </p>
          </div>

          {/*-------------- DESCRIPTION SECTION ------------------*/}
          <div className="mt-10"></div>
          <CustomDivider title="DESCRIPTION" />
          <S.MuventDescriptionWrapper>
            <S.MuventDescriptionWrapperInner>
              <S.MuventDescriptionParagraph>{description}</S.MuventDescriptionParagraph>
            </S.MuventDescriptionWrapperInner>
          </S.MuventDescriptionWrapper>

          {/*---------------- MORE INFO SECTION ------------------*/}
          {more_info ? (
            <>
              <div className="my-10"></div>
              <CustomDivider title="MORE INFO" />
              <div className="flex flex-col text-center m-10 md:m-10">
                <p>{more_info}</p>
              </div>
            </>
          ) : null}

          {hasAtLeastOneLink && (
            <>
              <div className="my-10"></div>
              <CustomDivider title="LINKS" />
              <S.LinksWrapper>
                <MuventLink link={ticket_link} name="Ticket" />
                <MuventLink link={website_link} name="Website" />
                <MuventLink link={application_form_link} name="Application Form" />
                <MuventLink link={video_link} name="Video" />{' '}
              </S.LinksWrapper>
            </>
          )}

          {user && user?.uid === ownerId ? (
            <S.MuventActions>
              <S.MuventActionButton
                onClick={handleEdit}
                disabled={deleting}
                leftIcon={<Edit size={20} strokeWidth={1} />}
              >
                Edit
              </S.MuventActionButton>

              <S.MuventActionButton
                onClick={handleDelete}
                variant="white"
                color="violet"
                disabled={deleting}
                leftIcon={<Trash size={20} strokeWidth={1} />}
              >
                Delete
              </S.MuventActionButton>
            </S.MuventActions>
          ) : null}
        </S.MuventContainer>
      </div>
      <div className="flex flex-col mt-10 mb-30 justify-center h-full items-center xs:hidden xl:flex">
        <p className="text-center text-md text-purple-800 rounded-full mb-4 w-fit px-10 ">{`More ${category} events`}</p>
        <div className="overflow-y-scroll h-screen">
          {categoryMuvents?.map(muvent => (
            <Card muvent={muvent} link={`/muvents/${muvent.docId}`} key={muvent.docId}></Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Muvent;
