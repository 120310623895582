import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../lib/firebase.prod';
import { BioFormWrapperTwinMacro } from '../styles';
import { Formik, Form } from 'formik';
import { Autocomplete, Button, Collapse, Space, TextInput, Textarea } from '@mantine/core';
import {
  BrandFacebook,
  BrandInstagram,
  BrandTwitter,
  BrandYoutube,
  Calendar,
  Edit,
  GenderFemale,
  World,
  WorldWww,
  Writing,
} from 'tabler-icons-react';
import { DatePickerInput } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { countries } from '../../helpers/generalData';

const BioSection = ({ user, authUser }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [gender, setGender] = useState(user?.gender || '');
  const [birthdate, setBirthdate] = useState((user.birthdate && user?.birthdate?.toDate()) || '');
  const [country, setCountry] = useState(user?.country || '');
  const [website, setWebsite] = useState(user?.website || '');
  const [instagram, setInstagram] = useState(user?.instagram || '');
  const [youtube, setYoutube] = useState(user?.youtube || '');
  const [twitter, setTwitter] = useState(user?.twitter || '');
  const [facebook, setFacebook] = useState(user?.facebook || '');
  const [bio, setBio] = useState(user?.bio || '');

  const handleBioSubmit = async () => {
    const data = {
      bio,
      gender,
      birthdate,
      country,
      website,
      instagram,
      youtube,
      twitter,
      facebook,
    };

    try {
      const userRef = doc(firestore, 'users', authUser.uid);
      await updateDoc(userRef, data);

      notifications.show({
        id: 'update-bio',
        withCloseButton: true,
        autoClose: 6000,
        title: `Yay! You rock ${authUser?.displayName}`,
        message: `Succesfully updated your bio`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
      close();
    } catch (error) {}
  };

  const getCountriesData = () => {
    return countries.map(({ flag, name }) => `${flag} ${name}`);
  };

  return (
    <>
      <button className="text-slate-900 text-sm hover:text-purple-600 bg-white px-4 font-montserrat" onClick={open}>
        <Edit size={16} strokeWidth={1} color={'black'} className="cursor-pointer" />
      </button>

      <Modal
        opened={opened}
        onClose={close}
        centered
        fullScreen
        transitionProps={{ transition: 'fade', duration: 500 }}
      >
        <BioFormWrapperTwinMacro>
          <Formik initialValues={{}} onSubmit={handleBioSubmit}>
            {({ isSubmitting }) => (
              <Form className="sm:w-full md:w-2/2 lg:w-2/3 xl:w-2/5 mx-2">
                <div className="flex flex-col gap-2">
                  <h1 className="text-purple-400">
                    Tell us a bit about yourself, {authUser?.displayName?.split(' ')[0]}
                  </h1>
                  <Space h="xl" />
                  <Textarea
                    icon={<Writing size={16} />}
                    placeholder={`Write a short description about who you are and where you dance`}
                    autosize
                    minRows={5}
                    value={bio}
                    onChange={evt => setBio(evt.currentTarget.value)}
                  />
                  {/*------------- GENDER SECTION -------------*/}
                  <TextInput
                    placeholder="Gender"
                    radius="xs"
                    value={gender}
                    icon={<GenderFemale size={16} />}
                    onChange={event => setGender(event.target.value)}
                    className="w-full"
                  />
                  {/*------------- BIRTHDAY SECTION -------------*/}
                  <DatePickerInput
                    minDate={new Date('01/01/1920')}
                    dropdownType="modal"
                    placeholder={`birthday`}
                    value={birthdate || new Date('01/01/1920')}
                    onChange={date => {
                      setBirthdate(date);
                    }}
                    icon={<Calendar size={16} />}
                    className="w-full"
                  />
                  {/*------------  SECTION --------------------*/}
                  <Autocomplete
                    icon={<World size={16} />}
                    value={country}
                    onChange={val => setCountry(val)}
                    placeholder="Country: Type the country to see more options"
                    data={getCountriesData()}
                    className="w-full"
                  />
                  {/*------------------------------------------*/}
                  {/*------------  SOCIAL MEDIA SECTION ------------*/}
                  {/*------------------------------------------*/}
                  <Space h="xl" />
                  <h2 className="text-purple-400 text-xl">
                    Links
                    <span>
                      <small> (where people can go check your work)</small>
                    </span>
                  </h2>
                  <TextInput
                    placeholder="Your Website"
                    radius="xs"
                    value={website}
                    icon={<WorldWww size={16} />}
                    onChange={evt => {
                      setWebsite(evt.currentTarget.value);
                    }}
                    className="w-full"
                  />
                  <TextInput
                    placeholder="Instagram"
                    radius="xs"
                    value={instagram}
                    icon={<BrandInstagram size={16} />}
                    onChange={evt => setInstagram(evt.currentTarget.value)}
                    className="w-full"
                  />
                  <TextInput
                    placeholder="Youtube"
                    radius="xs"
                    icon={<BrandYoutube size={16} />}
                    value={youtube}
                    onChange={evt => setYoutube(evt.currentTarget.value)}
                    className="w-full"
                  />
                  <TextInput
                    placeholder="Twitter"
                    radius="xs"
                    value={twitter}
                    icon={<BrandTwitter size={16} />}
                    onChange={evt => setTwitter(evt.currentTarget.value)}
                    className="w-full"
                  />
                  <TextInput
                    placeholder="Facebook"
                    radius="xs"
                    value={facebook}
                    icon={<BrandFacebook size={16} />}
                    onChange={evt => setFacebook(evt.currentTarget.value)}
                    className="w-full"
                  />
                </div>

                <div className="flex justify-between mt-12 gap-6">
                  <Button
                    leftIcon={<Collapse size={14} />}
                    className="xs:w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/5 mx-2 rounded-full text-2xl h-16 bg-white hover:bg-gray-100 hover:text-purple-500 text-purple-400 border-purple-400 border-1 font-montserrat"
                    onClick={close}
                  >
                    CANCEL
                  </Button>
                  <Button
                    leftIcon={<Collapse size={14} />}
                    type="submit"
                    className="w-full rounded-full text-2xl h-16 bg-purple-300 hover:bg-gray-100 hover:text-purple-500 text-white font-montserrat"
                    disabled={isSubmitting}
                  >
                    SAVE
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </BioFormWrapperTwinMacro>
      </Modal>
    </>
  );
};

export default BioSection;
