import React from 'react';

const Donate = () => {
  return (
    <div>
      <div className="mt-40">
        <h2 className="text-center text-3xl text-pink-400">HELP UMUV!</h2>
      </div>
      <br />
      <br />
      <center>
        <h1 className="">Donating to UMUV means helping Dance worldwide!</h1>
      </center>
      <br />
      <br />
      <br />
      <div className="flex flex-col justify-center items-center text-center">
        <div className="w-1/2 text-center">
          <h2 className="text-xl">
            We need your support to be able to keep 90% of our services free,
            and make UMUV better each day. WE work hard, efficiently and with
            love in order to bring the world the technological easiness we all
            have been waiting for in Dance! Please help with as much as you can!
            $1 shows a lot of support too! Get involved today in making the
            world a better place and helping dance all over the world!
          </h2>

          <h1 className="text-pink-400 py-10">Thank you!</h1>
        </div>

        <div className="my-10">
          <h3 className="">Bitcoin address:</h3>
          <h4 className="">1J8wzyf9LtAMf1XsSKCyzGvK86RPk13Cna</h4>
          <div className="">Copy Address</div>
        </div>

        <div className="my-10">
          <h3 className="">Litecoin address:</h3>
          <h4 className="">LP9SNJELFgiVvsRdq6VsXr5iPY5ZXoo8cX</h4>
        </div>

        <div className="my-10">
          <h3 className="">Or send us a check if you are in United States:</h3>
          <h3 className="">Olsi Gjeci</h3>
          <h3 className="">5842 Catalpa Ave, Apt 2L</h3>
          <h3 className="">Ridgewood, New York 11385</h3>
        </div>
      </div>
    </div>
  );
};

export default Donate;
