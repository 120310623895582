import React from 'react';

const AboutUs = () => {
  return (
    <div className="m-10 mt-40 mb-20 text-center">
      <h3 className=" m-10 text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        WHO WE ARE
      </h3>
      <h2 className="">We love dance!</h2>
      <p className="text-xl text-gray-500 my-2">
        We love to provide the best tools for dance!
      </p>
      <p className="text-xl text-purple-500 my-10">
        OLSI GJECI
        <span className="text-base text-gray-400 block">
          Developer / Design / Founder
        </span>
      </p>
      <p className="text-xl my-10">
        Olsi Gjeci (Dancer) was born in Vlore, Albania. He began his
        professional career as a folk dancer with the ensemble "Laberia" and The
        State Ensemble of Folk Songs and Dances. In 2006, he moved to NYC.
        Studied dance and philosophy at Hunter College. Olsi also dances baroque
        and balinese among other styles. He joined Trisha Brown Dance Company in
        December 2013. He also dances with Vicky Shick and is a member of NY
        Baroque Dance Company, BEMF Dance Ensemble, and BALAM Dance Theatre.
        Olsi is artistic director of Sublime Dance Company. He is the creator
        and designer/developer of UMUV since its inception.
      </p>
      <p className="text-xl text-purple-500 my-10">
        DIANA SEABRA
        <span className="text-base text-gray-400 block">
          Communication / Founder
        </span>
      </p>
      <p className="text-xl text-gray-500 my-2">
        Diana Seabra was born in Oliveira de Azeméis (Portugal). She studied
        ballet with Ana Luísa Mendonça. Diana danced for Companhia Dança Livre
        (Lisbon) where she worked with Benvindo Fonseca and Jácome Filipe. In
        NY, Diana danced for Aveia Dance Company, Kinetic Architecture, and
        Regina Nejman Company. Diana holds a BA in Psychomotor Rehabilitation by
        Faculdade de Motricidade Humana (Lisbon) and a MA in Dance/Movement
        Therapy by Pratt Institute (New York). Diana dances for Filipe La Feria
        Productions. She teaches at Oeiras Dance Academy, and develops her own
        work with Sublime Dance Company. Diana is the co-founder of UMUV and is
        in charge of communication and marketing.
      </p>

      <div className="m-10">
        <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          HOW UMUV CAME TO LIFE
        </h3>
      </div>

      <p className="text-xl text-gray-500 my-2">
        UMUV didn't have a name at first… it was just an idea that kept coming
        up in our conversations every time we talked about “visibility and
        accessibility in dance”.
      </p>
      <p className="text-xl text-gray-500 my-2">
        Dance seems to be a little behind in the tech world… As a result, its
        reach to a wider community is not as strong as it could be. From our
        experience, and from feedback we get from family and friends, dance
        amateurs who want to dance or watch dance don't seem to know how to
        efficiently look for dance events! They end up “consuming” whatever the
        well-known venues advertize!
      </p>
      <p className="text-xl text-gray-500 my-2">
        Also, the professional dancers, companies, and institutions waste their
        time and effort doing marketing for themselves on many different
        platforms like Twitter, Facebook etc, and pay a lot for extra marketing
        on these platforms in order to reach people on platforms, which are not
        built for dancers, dance, or dance enthusiasts. At the end of the day
        information about all these beautiful dance events is lost in the
        infinite webs of the cold and neutral internet.
      </p>
      <p className="text-xl text-gray-500 my-2">
        Well, we care for what we do and want others to have easy and pain-free
        access to everything about dance, everywhere, at any time!
      </p>
      <p className="text-xl text-gray-500 my-2">
        There are many websites that do this well for restaurants, traveling,
        hotels etc, …why not dance? All dance artists and businesses could be
        gathered in one place to be found by other professional and amateur
        dancers, and to serve each other's needs.
      </p>
      <p className="text-xl text-gray-500 my-2">
        Of course we were probably not being original with this idea… we thought
        there should be something as global as we were thinking it, so we did
        some research. We came to the conclusion that even though there are a
        lot of cool dance websites, most of them either have a narrow focus
        (just auditions for exmaple), or a narrow reach(websites built just for
        a specific city or school), or just technologically not up to date
        compared with websites about food or travel, or all of the above
        combined in some cases! Even on popular ones. There was nothing built
        with the structure we had imagined - EVERYTHING on dance, everything,
        anywhere in the world, at any time, in one place - UMUV!!
      </p>
      <p className="text-xl text-gray-500 my-2">
        First we thought, let's sell the idea, but then we realized we were too
        excited and engaged to let go from it! But how can two dancers build
        such an ambitious online platform? Everything on dance?? How do you do
        it? How do you start? Where? So many complex questions to solve when you
        dream big. We nevertheless decided to start bulding and address these
        questions one by one as we grow. The main thing that made that decision
        happen was the realization that no matter what we achieve, we are
        offering a bit of help to the world dance community.
      </p>
      <p className="text-xl text-gray-500 my-2">
        So, Olsi happens to love learning how to code and Diana happens to be
        good at talking to people and connecting …so we thought WHY DON'T WE TRY
        IT? The worst that can happen is that it doesn't work and we go back to
        being happy together dancing! :)) But at least we learn something new
        and we connect to people...and help dancers!
      </p>
      <p className="text-xl text-gray-500 my-2">So UMUV was born!</p>
      <p className="text-xl text-gray-500 my-2">
        Now we are working hard to make it better every day!
      </p>
      <p className="text-xl text-gray-500 my-2">
        We are very happy to have you here!
      </p>
      <p className="text-xl text-gray-500 my-2">
        UMUV was created with some simple goals in mind:{' '}
      </p>
      <p className="text-xl text-gray-500 my-2"></p>
      <p className="text-xl text-gray-500 my-2">
        To build a platform that allows dancers, dance teachers and dance
        enthusiasts from all over the world to publish their dance
      </p>
      <p className="text-xl text-gray-500 my-2">
        To simplify the process of searching for a dance service like an
        audition or or class and much more.
      </p>
      <p className="text-xl text-gray-500 my-2">
        To help dance schools and other dance institutions to reach a wider
        audience and to help them grow.
      </p>
      <p className="text-xl text-gray-500 my-2">
        To help dance lovers find dance events and services anywhere in the
        world.
      </p>
      <p className="text-xl text-gray-500 my-2">
        %p To create a space where all can look for all their favorite services
        on dance form anywhere in the world.
      </p>
      <p className="text-xl text-gray-500 my-2">
        To help in growing and uniting the global dance community as simply and
        efficiently as possible.
      </p>
      <h2>THE FUTURE</h2>
      <p className="text-xl text-gray-500 my-2">
        We want to become the biggest online dance platform in the world.
      </p>
      <p className="text-xl text-gray-500 my-2">
        We want to help elevate and support to maximum the power and effect that
        dance has in communities around the world
      </p>
      <p className="text-xl text-gray-500 my-2">
        We want to make the posting, searching, and taking class the most
        efficient and the easiest for UMUV users.
      </p>
      <p className="text-xl text-gray-500 my-2">
        We want to make the posting, searching for dance related events of any
        kind, any style, easy, fun, and just efficient.
      </p>
      <p className="text-xl text-gray-500 my-2">
        We want to allow schools and other dance insitutions or bussiness to in
        the most efficient and transparent way possible.
      </p>
      <h4 className="text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl py-4">
        We want to help the dance communities grow all around the world.
      </h4>
      <h5 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        And we believe that with
      </h5>

      <h4 className="text-purple-400 my-4 text-2xl"> efficiency, </h4>
      <h4 className="text-purple-400 my-4 text-2xl"> transparency</h4>
      <h4 className="text-purple-400 my-4 text-2xl"> and easiness of use,</h4>
      <h4>
        {' '}
        we can help dance lovers wherever they are, connect, exchange, and
        consume dance services,
      </h4>
      <h4> activities and dane as art!</h4>
      <h4> You can help us grow! We grow with you.</h4>
      <h4> Thank you for being part of the UMUV community!</h4>
    </div>
  );
};

export default AboutUs;
