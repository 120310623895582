import md5 from 'md5';
import firebaseConfig from './config';
// 1) when seeding the database you'll have to uncomment this!
// import { seedDatabase } from "../seed";

// const firebase = Firebase.initializeApp(firebaseConfig);
// 2) when seeding the database you'll have to uncomment this!
// seedDatabase(firebase);
// 3) once you have populated the database (only run once!), re-comment this so you don't get duplicate data

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
  // deleteUser,
} from 'firebase/auth';
import { getFirestore, deleteDoc, doc, setDoc } from 'firebase/firestore';

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
getAnalytics(app);

// ? https://firebase.google.com/docs/auth/web/manage-users
export async function register(name, email, password) {
  const newUser = await createUserWithEmailAndPassword(auth, email, password);
  const { uid, email: newUserEmail } = newUser.user;
  const photoURL = `https://gravatar.com/avatar/${md5(newUserEmail)}?d=identicon`;

  const data = { id: uid, name, email, photoURL, created_at: new Date().getTime() };
  await setDoc(doc(firestore, 'users', uid), data);

  await updateProfile(auth.currentUser, { displayName: name, photoURL });
  await sendEmailVerification(auth.currentUser);

  return newUser;
}

export async function resetPassword(email) {
  await sendPasswordResetEmail(auth, email);
}

export async function logout() {
  await signOut(auth);
}
export async function login(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function updateUserProfile({ name }) {
  await updateProfile(auth.currentUser, { displayName: name });
}

export async function updateUserProfilePhoto({ photoURL }) {
  await updateProfile(auth.currentUser, { photoURL });
}

export async function deleteDocument({ collection, docID, photoURL, thumbnailURL }) {
  try {
    const docRef = doc(firestore, collection, docID);
    await deleteDoc(docRef).then(() => {
      if (photoURL) {
        deletePhotoInStorage(photoURL);
      }
      if (thumbnailURL) {
        deletePhotoInStorage(thumbnailURL);
      }
    });
  } catch (error) {
    console.log('Error deleting document:', error);
  }
}

export async function deletePhotoInStorage(imgUrl) {
  const imgRef = ref(storage, imgUrl);
  deleteObject(imgRef)
    .then(() => {})
    .catch(error => {
      console.log('Uh-oh, an error occurred!', error);
    });
  return;
}

// deleteUser(auth.currentUser)
//   .then(() => {
//     // User deleted.
//   })
//   .catch(error => {
//     // An error ocurred
//     // ...
//   });
