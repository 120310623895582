import React from "react";
// import { useParams } from "react-router-dom";
import { useAuthListener } from "../../hooks";

const Notifications = () => {
  const { user } = useAuthListener();
  //   let { slug } = useParams();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        flexDirection: "column",
      }}
    >
      <h1>{user?.displayName?.toUpperCase()}</h1>
      <p>The Notifications page is under construction</p>
      <></>
    </div>
  );
};

export default Notifications;
