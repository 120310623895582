// PackageInfo.js

import React from 'react';
import packageJson from '../../package.json'; // Assuming package.json is in the root directory

const NerdStats = () => {
  const { name, version, description, dependencies, devDependencies }= packageJson;

  return (
    <div className="container mx-auto py-20 mt-20">
      <h1 className="text-3xl font-bold mb-4">{name}</h1>
      <p className="text-gray-600 text-lg mb-4">{description}</p>
      <p className="text-gray-800 text-lg mb-4">Version: {version}</p>

      <div>
        <h2 className="text-xl font-semibold mb-2">Dependencies</h2>
        <ul className="list-disc list-inside">
          {Object.keys(dependencies).map((dependency, index) => (
            <li key={index}>
              {dependency}: {dependencies[dependency]}
            </li>
          ))}
        </ul>
        
      </div>

      <div>
        <h2 className="text-xl font-semibold mb-2 mt-4">Dev Dependencies</h2>
        <ul className="list-disc list-inside">
          {Object.keys(devDependencies).map((dependency, index) => (
            <li key={index}>
              {dependency}: {devDependencies[dependency]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NerdStats;
