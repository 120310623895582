export const countries = [
  {
    code: 'AF',
    flag: '🇦🇫',
    name: 'Afghanistan',
    cities: [
      { name: 'Kabul' },
      { name: 'Kandahar' },
      { name: 'Herat' },
      { name: 'Mazar-i-Sharif' },
      { name: 'Jalalabad' },
      { name: 'Kunduz' },
      { name: 'Ghazni' },
      { name: 'Bamyan' },
      { name: 'Farah' },
      { name: 'Pul-e-Khumri' },
      { name: 'Charikar' },
      { name: 'Lashkar Gah' },
      { name: 'Sheberghan' },
      { name: 'Taloqan' },
      { name: 'Asadabad' },
      { name: 'Faizabad' },
      { name: 'Maymana' },
      { name: 'Zaranj' },
      { name: 'Gardez' },
      { name: 'Mehtar Lam' },
    ],
  },
  {
    code: 'AX',
    flag: '🇦🇽',
    name: 'Åland Islands',
    cities: [
      { name: 'Mariehamn' },
      { name: 'Jomala' },
      { name: 'Finström' },
      { name: 'Lemland' },
      { name: 'Saltvik' },
      { name: 'Hammarland' },
      { name: 'Sund' },
      { name: 'Eckerö' },
      { name: 'Kumlinge' },
      { name: 'Brändö' },
      { name: 'Föglö' },
      { name: 'Geta' },
      { name: 'Vårdö' },
      { name: 'Sottunga' },
      { name: 'Kökar' },
    ],
  },
  {
    code: 'AL',
    flag: '🇦🇱',
    name: 'Albania',
    cities: [
      { name: 'Tirana' },
      { name: 'Durrës' },
      { name: 'Vlorë' },
      { name: 'Elbasan' },
      { name: 'Shkodër' },
      { name: 'Fier' },
      { name: 'Korçë' },
      { name: 'Berat' },
      { name: 'Lushnjë' },
      { name: 'Pogradec' },
      { name: 'Gjirokastër' },
      { name: 'Lezhë' },
      { name: 'Kukës' },
      { name: 'Sarandë' },
      { name: 'Patos' },
      { name: 'Burrel' },
      { name: 'Cërrik' },
      { name: 'Peshkopi' },
      { name: 'Kamëz' },
      { name: 'Pukë' },
    ],
  },
  {
    code: 'DZ',
    flag: '🇩🇿',
    name: 'Algeria',
    cities: [
      { name: 'Algiers' },
      { name: 'Oran' },
      { name: 'Constantine' },
      { name: 'Annaba' },
      { name: 'Blida' },
      { name: 'Setif' },
      { name: 'Tlemcen' },
      { name: 'Biskra' },
      { name: 'Batna' },
      { name: 'Sidi Bel Abbes' },
      { name: 'Bejaia' },
      { name: 'Mostaganem' },
      { name: 'Chlef' },
      { name: "M'Sila" },
      { name: 'Tizi Ouzou' },
      { name: 'Saida' },
      { name: 'Skikda' },
      { name: 'Tiaret' },
      { name: 'Guelma' },
      { name: 'Tiarat' },
    ],
  },
  {
    code: 'AS',
    flag: '🇦🇸',
    name: 'American Samoa',
    cities: [
      { name: 'Pago Pago' },
      { name: 'Tafuna' },
      { name: 'Leone' },
      { name: 'Faleniu' },
      { name: 'Aūa' },
      { name: 'Mapusagafou' },
      { name: 'Fagatogo' },
      { name: 'Vaitogi' },
      { name: 'Alofau' },
      { name: 'Malaeimi' },
      { name: 'Futiga' },
      { name: 'Afono' },
      { name: 'Tula' },
      { name: 'Taputimu' },
      { name: "Lauli'i" },
    ],
  },
  {
    code: 'AD',
    flag: '🇦🇩',
    name: 'Andorra',
    cities: [
      { name: 'Andorra la Vella' },
      { name: 'Escaldes-Engordany' },
      { name: 'Encamp' },
      { name: 'Sant Julià de Lòria' },
      { name: 'La Massana' },
      { name: 'Ordino' },
      { name: 'Canillo' },
    ],
  },
  {
    code: 'AO',
    flag: '🇦🇴',
    name: 'Angola',
    cities: [
      { name: 'Luanda' },
      { name: 'Huambo' },
      { name: 'Lobito' },
      { name: 'Benguela' },
      { name: 'Kuito' },
      { name: 'Lubango' },
      { name: 'Malanje' },
      { name: 'Namibe' },
      { name: 'Saurimo' },
      { name: 'Uíge' },
      { name: 'Cabinda' },
      { name: 'Sumbe' },
      { name: 'Caxito' },
      { name: 'Luena' },
      { name: 'Menongue' },
      { name: 'Mbanza-Kongo' },
      { name: 'Ondjiva' },
      { name: 'Ndalatando' },
      { name: 'Cuito Canavale' },
      { name: 'Chitado' },
    ],
  },
  {
    code: 'AI',
    flag: '🇦🇮',
    name: 'Anguilla',
    cities: [
      { name: 'The Valley' },
      { name: 'Stoney Ground' },
      { name: 'North Side' },
      { name: 'Blowing Point' },
      { name: 'East End' },
      { name: 'West End Village' },
      { name: 'Island Harbour' },
      { name: 'Sandy Ground' },
      { name: 'South Hill' },
      { name: 'George Hill' },
      { name: 'Welches' },
      { name: 'The Farrington' },
      { name: 'North Hill Village' },
      { name: 'Long Path' },
      { name: 'The Quarter' },
      { name: 'North Valley' },
      { name: 'South Hill Village' },
    ],
  },
  {
    code: 'AG',
    flag: '🇦🇬',
    name: 'Antigua and Barbuda',
    cities: [
      { name: "St. John's" },
      { name: 'All Saints' },
      { name: 'Liberta' },
      { name: 'Bolans' },
      { name: 'Potters Village' },
      { name: 'Swetes' },
      { name: 'Parham' },
      { name: 'Carlisle' },
      { name: 'Codrington' },
      { name: 'Freetown' },
      { name: 'Old Road' },
      { name: 'Cedar Grove' },
      { name: 'Willikies' },
      { name: 'Seatons' },
      { name: 'Clare Hall' },
      { name: "Johnson's Point" },
      { name: 'Sweets' },
      { name: 'Five Islands Village' },
      { name: 'Green Bay' },
      { name: 'Bendals' },
    ],
  },
  {
    code: 'AR',
    flag: '🇦🇷',
    name: 'Argentina',
    cities: [
      { name: 'Buenos Aires' },
      { name: 'Córdoba' },
      { name: 'Rosario' },
      { name: 'Mendoza' },
      { name: 'La Plata' },
      { name: 'San Miguel de Tucumán' },
      { name: 'Mar del Plata' },
      { name: 'Salta' },
      { name: 'Santa Fe' },
      { name: 'San Juan' },
      { name: 'Resistencia' },
      { name: 'Santiago del Estero' },
      { name: 'Corrientes' },
      { name: 'Bahía Blanca' },
      { name: 'Posadas' },
      { name: 'Paraná' },
      { name: 'Neuquén' },
      { name: 'Formosa' },
      { name: 'San Salvador de Jujuy' },
      { name: 'Comodoro Rivadavia' },
    ],
  },
  {
    code: 'AM',
    flag: '🇦🇲',
    name: 'Armenia',
    cities: [
      { name: 'Yerevan' },
      { name: 'Gyumri' },
      { name: 'Vanadzor' },
      { name: 'Ejmiatsin' },
      { name: 'Hrazdan' },
      { name: 'Kapan' },
      { name: 'Abovyan' },
      { name: 'Armavir' },
      { name: 'Artashat' },
      { name: 'Ashtarak' },
      { name: 'Gavar' },
      { name: 'Ijevan' },
      { name: 'Masis' },
      { name: 'Sevan' },
      { name: 'Spitak' },
      { name: 'Stepanavan' },
      { name: 'Talin' },
      { name: 'Vedi' },
      { name: 'Yeghvard' },
      { name: 'Yeghegnadzor' },
    ],
  },
  {
    code: 'AW',
    flag: '🇦🇼',
    name: 'Aruba',
    cities: [
      { name: 'Oranjestad' },
      { name: 'San Nicolas' },
      { name: 'Noord' },
      { name: 'Santa Cruz' },
      { name: 'Savaneta' },
      { name: 'Paradera' },
      { name: 'Tanki Leendert' },
      { name: 'Pos Chiquito' },
      { name: 'Madiki' },
      { name: 'Moko' },
      { name: 'Babijn' },
      { name: 'Piedra Plat' },
      { name: 'Ponton' },
      { name: 'Rooi Afo' },
      { name: 'Sero Blanco' },
      { name: 'Sero Grandi' },
    ],
  },
  {
    code: 'AU',
    flag: '🇦🇺',
    name: 'Australia',
    cities: [
      { name: 'Sydney' },
      { name: 'Melbourne' },
      { name: 'Brisbane' },
      { name: 'Perth' },
      { name: 'Adelaide' },
      { name: 'Gold Coast' },
      { name: 'Newcastle' },
      { name: 'Canberra' },
      { name: 'Wollongong' },
      { name: 'Sunshine Coast' },
      { name: 'Geelong' },
      { name: 'Hobart' },
      { name: 'Townsville' },
      { name: 'Cairns' },
      { name: 'Darwin' },
      { name: 'Toowoomba' },
      { name: 'Ballarat' },
      { name: 'Bendigo' },
      { name: 'Albury' },
      { name: 'Launceston' },
    ],
  },
  {
    code: 'AT',
    flag: '🇦🇹',
    name: 'Austria',
    cities: [
      { name: 'Vienna' },
      { name: 'Graz' },
      { name: 'Linz' },
      { name: 'Salzburg' },
      { name: 'Innsbruck' },
      { name: 'Klagenfurt' },
      { name: 'Villach' },
      { name: 'Wels' },
      { name: 'Steyr' },
      { name: 'Dornbirn' },
      { name: 'Wiener Neustadt' },
      { name: 'Feldkirch' },
      { name: 'Baden' },
      { name: 'Leoben' },
      { name: 'Krems an der Donau' },
      { name: 'Traun' },
      { name: 'Amstetten' },
      { name: 'Lustenau' },
      { name: 'Gmunden' },
      { name: 'Hallein' },
    ],
  },
  {
    code: 'AZ',
    flag: '🇦🇿',
    name: 'Azerbaijan',
    cities: [
      { name: 'Baku' },
      { name: 'Ganja' },
      { name: 'Sumqayit' },
      { name: 'Mingachevir' },
      { name: 'Lankaran' },
      { name: 'Shirvan' },
      { name: 'Nakhchivan' },
      { name: 'Sheki' },
      { name: 'Yevlakh' },
      { name: 'Barda' },
      { name: 'Agdam' },
      { name: 'Agjabadi' },
      { name: 'Imishli' },
      { name: 'Quba' },
      { name: 'Khachmaz' },
      { name: 'Masally' },
      { name: 'Sabirabad' },
      { name: 'Salyan' },
      { name: 'Zaqatala' },
      { name: 'Samaxi' },
    ],
  },
  {
    code: 'BS',
    flag: '🇧🇸',
    name: 'Bahamas',
    cities: [
      { name: 'Nassau' },
      { name: 'Freeport' },
      { name: 'Lucaya' },
      { name: 'West End' },
      { name: "Cooper's Town" },
      { name: 'Marsh Harbour' },
      { name: 'Andros Town' },
      { name: 'Dunmore Town' },
      { name: 'Alice Town' },
      { name: 'George Town' },
      { name: 'Cockburn Town' },
      { name: 'Rock Sound' },
      { name: 'Matthew Town' },
      { name: 'Colonel Hill' },
      { name: 'Clarence Town' },
      { name: 'High Rock' },
      { name: 'Nicholls Town' },
      { name: "Governor's Harbour" },
      { name: "Arthur's Town" },
      { name: 'Bimini' },
    ],
  },
  {
    code: 'BH',
    flag: '🇧🇭',
    name: 'Bahrain',
    cities: [
      { name: 'Manama' },
      { name: 'Al Muharraq' },
      { name: 'Isa Town' },
      { name: 'Riffa' },
      { name: 'Hamad Town' },
      { name: "A'ali" },
      { name: 'Sitra' },
      { name: 'Jidhafs' },
      { name: 'Al Hidd' },
      { name: 'Madinat Isa' },
      { name: 'Dar Kulayb' },
      { name: 'Busaiteen' },
      { name: 'Muharraq' },
      { name: 'Tubli' },
      { name: 'Sar' },
      { name: 'Salmabad' },
      { name: 'Al Dur' },
      { name: 'Budaiya' },
      { name: 'Juffair' },
      { name: 'Bani Jamra' },
    ],
  },
  {
    code: 'BD',
    flag: '🇧🇩',
    name: 'Bangladesh',
    cities: [
      { name: 'Dhaka' },
      { name: 'Chittagong' },
      { name: 'Khulna' },
      { name: 'Rajshahi' },
      { name: 'Sylhet' },
      { name: 'Barisal' },
      { name: 'Rangpur' },
      { name: 'Comilla' },
      { name: 'Narayanganj' },
      { name: 'Mymensingh' },
      { name: 'Jessore' },
      { name: "Cox's Bazar" },
      { name: 'Bogra' },
      { name: 'Tangail' },
      { name: 'Faridpur' },
      { name: 'Pabna' },
      { name: 'Manikganj' },
      { name: 'Nawabganj' },
      { name: 'Jamalpur' },
      { name: 'Narsingdi' },
    ],
  },
  {
    code: 'BB',
    flag: '🇧🇧',
    name: 'Barbados',
    cities: [
      { name: 'Bridgetown' },
      { name: 'Speightstown' },
      { name: 'Oistins' },
      { name: 'Holetown' },
      { name: 'Bathsheba' },
      { name: 'Crane' },
      { name: 'Saint Lawrence' },
      { name: 'Greenland' },
      { name: 'Four Cross Roads' },
      { name: 'Checker Hall' },
      { name: 'Welchman Hall' },
      { name: 'Cave Hill' },
      { name: 'Eagle Hall' },
      { name: 'Hillaby' },
      { name: 'Blackmans' },
      { name: 'Bridgetown Port' },
      { name: 'Brighton' },
      { name: 'Workmans' },
      { name: 'Haggatt Hall' },
      { name: 'Mullins' },
    ],
  },
  {
    code: 'BY',
    flag: '🇧🇾',
    name: 'Belarus',
    cities: [
      { name: 'Minsk' },
      { name: 'Gomel' },
      { name: 'Mogilev' },
      { name: 'Vitebsk' },
      { name: 'Brest' },
      { name: 'Bobruisk' },
      { name: 'Baranovichi' },
      { name: 'Borisov' },
      { name: 'Pinsk' },
      { name: 'Orsha' },
      { name: 'Novopolotsk' },
      { name: 'Lida' },
      { name: 'Soligorsk' },
      { name: 'Polotsk' },
      { name: 'Molodechno' },
      { name: 'Zhlobin' },
      { name: 'Slutsk' },
      { name: 'Minsk Mazowiecki' },
      { name: 'Maladzyechna' },
      { name: 'Rechytsa' },
    ],
  },
  {
    code: 'BE',
    flag: '🇧🇪',
    name: 'Belgium',
    cities: [
      { name: 'Brussels' },
      { name: 'Antwerp' },
      { name: 'Ghent' },
      { name: 'Charleroi' },
      { name: 'Liege' },
      { name: 'Bruges' },
      { name: 'Schaerbeek' },
      { name: 'Namur' },
      { name: 'Anderlecht' },
      { name: 'Leuven' },
      { name: 'Mons' },
      { name: 'Molenbeek-Saint-Jean' },
      { name: 'La Louviere' },
      { name: 'Kortrijk' },
      { name: 'Hasselt' },
      { name: 'Ostend' },
      { name: 'Tournai' },
      { name: 'Sint-Niklaas' },
      { name: 'Seraing' },
      { name: 'Roeselare' },
    ],
  },
  {
    code: 'BZ',
    flag: '🇧🇿',
    name: 'Belize',
    cities: [
      { name: 'Belize City' },
      { name: 'San Ignacio' },
      { name: 'Orange Walk' },
      { name: 'Belmopan' },
      { name: 'Dangriga' },
      { name: 'Corozal Town' },
      { name: 'San Pedro Town' },
      { name: 'Benque Viejo del Carmen' },
      { name: 'Punta Gorda' },
      { name: 'Cayo District' },
    ],
  },
  {
    code: 'BJ',
    flag: '🇧🇯',
    name: 'Benin',
    cities: [
      { name: 'Cotonou' },
      { name: 'Porto-Novo' },
      { name: 'Parakou' },
      { name: 'Djougou' },
      { name: 'Bohicon' },
      { name: 'Kandi' },
      { name: 'Lokossa' },
      { name: 'Ouidah' },
      { name: 'Abomey-Calavi' },
      { name: 'Natitingou' },
      { name: 'Save' },
    ],
  },
  {
    code: 'BM',
    flag: '🇧🇲',
    name: 'Bermuda',
    cities: [
      { name: 'Hamilton' },
      { name: "St. George's" },
      { name: 'Somerset Village' },
      { name: "Bailey's Bay" },
      { name: 'Warwick Camp' },
      { name: 'Flatts Village' },
      { name: "Smith's Parish" },
      { name: 'Southampton Parish' },
      { name: 'Paget Parish' },
      { name: 'Devonshire Parish' },
      { name: 'Pembroke Parish' },
    ],
  },
  {
    code: 'BT',
    flag: '🇧🇹',
    name: 'Bhutan',
    cities: [
      { name: 'Thimphu' },
      { name: 'Phuntsholing' },
      { name: 'Paro' },
      { name: 'Punakha' },
      { name: 'Wangdue Phodrang' },
      { name: 'Jakar' },
      { name: 'Trashigang' },
      { name: 'Mongar' },
      { name: 'Samdrup Jongkhar' },
      { name: 'Gelephu' },
    ],
  },
  {
    code: 'BO',
    flag: '🇧🇴',
    name: 'Bolivia',
    cities: [
      { name: 'La Paz' },
      { name: 'Cochabamba' },
      { name: 'Santa Cruz de la Sierra' },
      { name: 'El Alto' },
      { name: 'Oruro' },
      { name: 'Sucre' },
      { name: 'Tarija' },
      { name: 'Potosi' },
      { name: 'Sacaba' },
      { name: 'Montero' },
      { name: 'Quillacollo' },
      { name: 'Trinidad' },
      { name: 'Riberalta' },
      { name: 'Warnes' },
      { name: 'Viacha' },
      { name: 'Yacuiba' },
      { name: 'Villa Tunari' },
      { name: 'Camiri' },
      { name: 'San Ignacio de Velasco' },
      { name: 'Cobija' },
    ],
  },
  {
    code: 'BA',
    flag: '🇧🇦',
    name: 'Bosnia and Herzegovina',
    cities: [
      { name: 'Sarajevo' },
      { name: 'Banja Luka' },
      { name: 'Tuzla' },
      { name: 'Zenica' },
      { name: 'Mostar' },
      { name: 'Bihac' },
      { name: 'Brcko' },
      { name: 'Cazin' },
      { name: 'Srebrenik' },
      { name: 'Bijeljina' },
      { name: 'Doboj' },
      { name: 'Gorazde' },
      { name: 'Travnik' },
      { name: 'Livno' },
      { name: 'Kakanj' },
      { name: 'Zivinice' },
      { name: 'Gracanica' },
      { name: 'Visoko' },
      { name: 'Konjic' },
      { name: 'Capljina' },
    ],
  },
  {
    code: 'BW',
    flag: '🇧🇼',
    name: 'Botswana',
    cities: [
      { name: 'Gaborone' },
      { name: 'Francistown' },
      { name: 'Molepolole' },
      { name: 'Serowe' },
      { name: 'Selibe Phikwe' },
      { name: 'Maun' },
      { name: 'Lobatse' },
      { name: 'Jwaneng' },
      { name: 'Mahalapye' },
      { name: 'Palapye' },
      { name: 'Kanye' },
      { name: 'Mochudi' },
      { name: 'Ramotswa' },
      { name: 'Thamaga' },
      { name: 'Mosopa' },
      { name: 'Letlhakane' },
      { name: 'Tshabong' },
      { name: 'Tonota' },
      { name: 'Gabane' },
      { name: 'Ghanzi' },
    ],
  },
  {
    code: 'BR',
    flag: '🇧🇷',
    name: 'Brazil',
    cities: [
      { name: 'São Paulo' },
      { name: 'Rio de Janeiro' },
      { name: 'Brasília' },
      { name: 'Salvador' },
      { name: 'Fortaleza' },
      { name: 'Belo Horizonte' },
      { name: 'Manaus' },
      { name: 'Curitiba' },
      { name: 'Recife' },
      { name: 'Porto Alegre' },
      { name: 'Belém' },
      { name: 'Goiânia' },
    ],
  },
  {
    code: 'IO',
    flag: '🇮🇴',
    name: 'British Indian Ocean Territory',
    cities: [{ name: 'Diego Garcia' }],
  },
  {
    code: 'VG',
    flag: '🇻🇬',
    name: 'Virgin Islands (British)',
    cities: [
      { name: 'Road Town' },
      { name: 'Tortola' },
      { name: 'Virgin Gorda' },
      { name: 'Jost Van Dyke' },
      { name: 'Anegada' },
    ],
  },
  {
    code: 'BN',
    flag: '🇧🇳',
    name: 'Brunei Darussalam',
    cities: [
      { name: 'Bandar Seri Begawan' },
      { name: 'Tutong' },
      { name: 'Seria' },
      { name: 'Kuala Belait' },
      { name: 'Bangar' },
    ],
  },
  {
    code: 'BG',
    flag: '🇧🇬',
    name: 'Bulgaria',
    cities: [
      { name: 'Sofia' },
      { name: 'Plovdiv' },
      { name: 'Varna' },
      { name: 'Burgas' },
      { name: 'Ruse' },
      { name: 'Stara Zagora' },
      { name: 'Pleven' },
      { name: 'Sliven' },
      { name: 'Dobrich' },
      { name: 'Shumen' },
      { name: 'Pernik' },
      { name: 'Yambol' },
    ],
  },
  {
    code: 'BF',
    flag: '🇧🇫',
    name: 'Burkina Faso',
    cities: [
      { name: 'Ouagadougou' },
      { name: 'Bobo-Dioulasso' },
      { name: 'Koudougou' },
      { name: 'Ouahigouya' },
      { name: 'Banfora' },
      { name: 'Dédougou' },
      { name: 'Kaya' },
      { name: 'Tenkodogo' },
      { name: "Fada N'gourma" },
      { name: 'Réo' },
    ],
  },
  {
    code: 'BI',
    flag: '🇧🇮',
    name: 'Burundi',
    cities: [
      { name: 'Bujumbura' },
      { name: 'Gitega' },
      { name: 'Ngozi' },
      { name: 'Ruyigi' },
      { name: 'Muyinga' },
      { name: 'Kayanza' },
      { name: 'Kirundo' },
      { name: 'Bururi' },
      { name: 'Makamba' },
      { name: 'Muramvya' },
      { name: 'Cankuzo' },
    ],
  },
  {
    code: 'KH',
    flag: '🇰🇭',
    name: 'Cambodia',
    cities: [
      { name: 'Phnom Penh' },
      { name: 'Siem Reap' },
      { name: 'Battambang' },
      { name: 'Sihanoukville' },
      { name: 'Kampong Cham' },
      { name: 'Poipet' },
      { name: 'Kampong Speu' },
      { name: 'Kampot' },
      { name: 'Koh Kong' },
      { name: 'Kep' },
      { name: 'Prey Veng' },
    ],
  },
  {
    code: 'CM',
    flag: '🇨🇲',
    name: 'Cameroon',
    cities: [
      { name: 'Yaoundé' },
      { name: 'Douala' },
      { name: 'Bamenda' },
      { name: 'Bafoussam' },
      { name: 'Garoua' },
      { name: 'Maroua' },
      { name: 'Ngaoundéré' },
      { name: 'Kumba' },
      { name: 'Nkongsamba' },
      { name: 'Ebolowa' },
      { name: 'Loum' },
      { name: 'Mbouda' },
    ],
  },
  {
    code: 'CA',
    flag: '🇨🇦',
    name: 'Canada',
    cities: [
      { name: 'Toronto' },
      { name: 'Montreal' },
      { name: 'Vancouver' },
      { name: 'Calgary' },
      { name: 'Edmonton' },
      { name: 'Ottawa' },
      { name: 'Quebec City' },
      { name: 'Winnipeg' },
      { name: 'Hamilton' },
      { name: 'Kitchener' },
      { name: 'Halifax' },
      { name: 'London' },
    ],
  },
  {
    code: 'CV',
    flag: '🇨🇻',
    name: 'Cabo Verde',
    cities: [
      { name: 'Praia' },
      { name: 'Mindelo' },
      { name: 'Santa Maria' },
      { name: 'Espargos' },
      { name: 'Assomada' },
      { name: 'Porto Novo' },
      { name: 'Ribeira Brava' },
      { name: 'Pombas' },
      { name: 'Tarrafal' },
      { name: 'Vila da Ribeira Grande' },
      { name: 'Pedra Badejo' },
      { name: 'São Filipe' },
    ],
  },
  {
    code: 'BQ',
    flag: '🇧🇶',
    name: 'Bonaire, Sint Eustatius and Saba[a]',
    cities: [{ name: 'Kralendijk' }, { name: 'Oranjestad' }, { name: 'The Bottom' }],
  },
  {
    code: 'KY',
    flag: '🇰🇾',
    name: 'Cayman Islands',
    cities: [
      { name: 'George Town' },
      { name: 'West Bay' },
      { name: 'Bodden Town' },
      { name: 'East End' },
      { name: 'North Side' },
    ],
  },
  {
    code: 'CF',
    flag: '🇨🇫',
    name: 'Central African Republic',
    cities: [
      { name: 'Bangui' },
      { name: 'Bimbo' },
      { name: 'Berbérati' },
      { name: 'Carnot' },
      { name: 'Sibut' },
      { name: 'Bambari' },
      { name: 'Kaga-Bandoro' },
      { name: 'Bozoum' },
      { name: 'Bossangoa' },
      { name: 'Nola' },
      { name: 'Bria' },
      { name: 'Bouar' },
    ],
  },
  {
    code: 'TD',
    flag: '🇹🇩',
    name: 'Chad',
    cities: [
      { name: "N'Djamena" },
      { name: 'Moundou' },
      { name: 'Sarh' },
      { name: 'Abéché' },
      { name: 'Kélo' },
      { name: 'Koumra' },
      { name: 'Pala' },
      { name: 'Am Timan' },
      { name: 'Bongor' },
      { name: 'Doba' },
      { name: 'Ati' },
      { name: 'Mongo' },
    ],
  },
  {
    code: 'CL',
    flag: '🇨🇱',
    name: 'Chile',
    cities: [
      { name: "N'Djamena" },
      { name: 'Moundou' },
      { name: 'Sarh' },
      { name: 'Abéché' },
      { name: 'Kélo' },
      { name: 'Koumra' },
      { name: 'Pala' },
      { name: 'Am Timan' },
      { name: 'Bongor' },
      { name: 'Doba' },
      { name: 'Ati' },
      { name: 'Mongo' },
    ],
  },
  {
    code: 'CN',
    flag: '🇨🇳',
    name: 'China',
    cities: [
      { name: 'Shanghai' },
      { name: 'Beijing' },
      { name: 'Guangzhou' },
      { name: 'Shenzhen' },
      { name: 'Tianjin' },
      { name: 'Chongqing' },
      { name: 'Chengdu' },
      { name: 'Nanjing' },
      { name: 'Wuhan' },
      { name: 'Hangzhou' },
      { name: "Xi'an" },
      { name: 'Dongguan' },
      { name: 'Shenyang' },
      { name: 'Qingdao' },
      { name: 'Jinan' },
      { name: 'Harbin' },
      { name: 'Foshan' },
      { name: 'Ningbo' },
      { name: 'Wuxi' },
      { name: 'Changsha' },
      { name: 'Changchun' },
      { name: 'Dalian' },
      { name: 'Suzhou' },
      { name: 'Zhengzhou' },
      { name: 'Shijiazhuang' },
      { name: 'Kunming' },
      { name: 'Zhuhai' },
      { name: 'Nanchang' },
      { name: 'Hefei' },
      { name: 'Wenzhou' },
      { name: 'Guiyang' },
      { name: 'Nanning' },
      { name: 'Xuzhou' },
      { name: 'Lanzhou' },
      { name: 'Zibo' },
    ],
  },
  {
    code: 'CX',
    flag: '🇨🇽',
    name: 'Christmas Island',
    cities: [{ name: 'Flying Fish Cove' }],
  },
  {
    code: 'CC',
    flag: '🇨🇨',
    name: 'Cocos (Keeling) Islands',
    cities: [{ name: 'West Island' }],
  },
  {
    code: 'CO',
    flag: '🇨🇴',
    name: 'Colombia',
    cities: [
      { name: 'Bogotá' },
      { name: 'Medellín' },
      { name: 'Cali' },
      { name: 'Barranquilla' },
      { name: 'Cartagena' },
      { name: 'Cúcuta' },
      { name: 'Soledad' },
      { name: 'Ibagué' },
      { name: 'Bucaramanga' },
      { name: 'Soacha' },
      { name: 'Pereira' },
      { name: 'Santa Marta' },
      { name: 'Villavicencio' },
      { name: 'Valledupar' },
      { name: 'Montería' },
      { name: 'Pasto' },
      { name: 'Manizales' },
      { name: 'Neiva' },
      { name: 'Armenia' },
      { name: 'Sincelejo' },
      { name: 'Popayán' },
      { name: 'Itagüí' },
      { name: 'Floridablanca' },
      { name: 'Envigado' },
      { name: 'Tuluá' },
      { name: 'Bello' },
      { name: 'Barrancabermeja' },
      { name: 'Yumbo' },
      { name: 'Dosquebradas' },
      { name: 'Ciénaga' },
      { name: 'Cartago' },
    ],
  },
  {
    code: 'KM',
    flag: '🇰🇲',
    name: 'Comoros',
    cities: [
      { name: 'Moroni' },
      { name: 'Moutsamoudou' },
      { name: 'Fomboni' },
      { name: 'Domoni' },
      { name: 'Tsimbeo' },
      { name: 'Ouani' },
    ],
  },
  {
    code: 'CD',
    flag: '🇨🇩',
    name: 'Congo, Democratic Republic of the',
    cities: [
      { name: 'Kinshasa' },
      { name: 'Lubumbashi' },
      { name: 'Mbuji-Mayi' },
      { name: 'Kananga' },
      { name: 'Kisangani' },
      { name: 'Bukavu' },
      { name: 'Tshikapa' },
      { name: 'Likasi' },
      { name: 'Kolwezi' },
      { name: 'Beni' },
      { name: 'Mbandaka' },
      { name: 'Uvira' },
      { name: 'Butembo' },
      { name: 'Goma' },
      { name: 'Isiro' },
      { name: 'Bunia' },
      { name: 'Kindu' },
      { name: 'Kikwit' },
      { name: 'Bandundu' },
      { name: 'Kasongo' },
      { name: 'Mwene-Ditu' },
      { name: 'Mbanza-Ngungu' },
      { name: 'Kamina' },
      { name: 'Boma' },
      { name: 'Kalemie' },
      { name: 'Lisala' },
      { name: 'Gemena' },
      { name: 'Matadi' },
      { name: 'Mweka' },
      { name: 'Gbadolite' },
      { name: 'Kananga' },
      { name: 'Kasangulu' },
    ],
  },
  {
    code: 'CG',
    flag: '🇨🇬',
    name: 'Congo',
    cities: [
      { name: 'Brazzaville' },
      { name: 'Pointe-Noire' },
      { name: 'Dolisie' },
      { name: 'Nkayi' },
      { name: 'Ouésso' },
      { name: 'Madingou' },
      { name: 'Impfondo' },
      { name: 'Sibiti' },
      { name: 'Loandjili' },
      { name: 'Mossendjo' },
      { name: 'Gamboma' },
      { name: 'Ewo' },
      { name: 'Loutété' },
      { name: 'Mossaka' },
      { name: 'Kindamba' },
      { name: 'Makoua' },
      { name: 'Mbanza-Ngungu' },
      { name: 'Goma' },
      { name: 'Kasangulu' },
      { name: 'Madingou' },
    ],
  },
  {
    code: 'CK',
    flag: '🇨🇰',
    name: 'Cook Islands',
    cities: [
      { name: 'Avarua' },
      { name: 'Arutanga' },
      { name: 'Amuri' },
      { name: 'Omoka' },
      { name: 'Rakahanga' },
      { name: 'Pukapuka' },
      { name: 'Tautu' },
      { name: 'Tauhunu' },
      { name: 'Vaipae' },
      { name: 'Nikao' },
      { name: 'Muri' },
      { name: 'Titikaveka' },
      { name: 'Avatiu' },
      { name: 'Vaimaanga' },
      { name: 'Tapere of Rutaki' },
      { name: 'Ngaruapai' },
      { name: 'Tapere of Teenui' },
      { name: 'Tupapa' },
      { name: 'Ngatangiia' },
      { name: 'Tapere of Matavera' },
    ],
  },
  {
    code: 'CR',
    flag: '🇨🇷',
    name: 'Costa Rica',
    cities: [
      { name: 'San José' },
      { name: 'Limón' },
      { name: 'San Francisco' },
      { name: 'Alajuela' },
      { name: 'Liberia' },
      { name: 'Paraíso' },
      { name: 'San Isidro' },
      { name: 'Puntarenas' },
      { name: 'Heredia' },
      { name: 'Cartago' },
      { name: 'Curridabat' },
      { name: 'San Rafael' },
      { name: 'Quesada' },
      { name: 'Desamparados' },
      { name: 'San Vicente' },
      { name: 'San Miguel' },
      { name: 'San Antonio' },
      { name: 'Guadalupe' },
      { name: 'Turrialba' },
      { name: 'San Pedro' },
    ],
  },
  {
    code: 'CI',
    flag: '🇨🇮',
    name: "Côte d'Ivoire",
    cities: [
      { name: 'Abidjan' },
      { name: 'Bouaké' },
      { name: 'Daloa' },
      { name: 'Yamoussoukro' },
      { name: 'San-Pédro' },
      { name: 'Gagnoa' },
      { name: 'Korhogo' },
      { name: 'Divo' },
      { name: 'Anyama' },
      { name: 'Agboville' },
      { name: 'Grand-Bassam' },
      { name: 'Abengourou' },
      { name: 'Boundiali' },
      { name: 'Ferkessédougou' },
      { name: 'Adzopé' },
      { name: 'Dimbokro' },
      { name: 'Soubré' },
      { name: 'Tiassalé' },
      { name: 'Odienné' },
      { name: 'Toumodi' },
    ],
  },
  {
    code: 'HR',
    flag: '🇭🇷',
    name: 'Croatia',
    cities: [
      { name: 'Zagreb' },
      { name: 'Split' },
      { name: 'Rijeka' },
      { name: 'Osijek' },
      { name: 'Zadar' },
      { name: 'Slavonski Brod' },
      { name: 'Pula' },
      { name: 'Sesvete' },
      { name: 'Karlovac' },
      { name: 'Varaždin' },
      { name: 'Šibenik' },
      { name: 'Sisak' },
      { name: 'Dubrovnik' },
      { name: 'Bjelovar' },
      { name: 'Koprivnica' },
      { name: 'Požega' },
      { name: 'Vinkovci' },
      { name: 'Samobor' },
      { name: 'Vukovar' },
      { name: 'Kutina' },
    ],
  },
  {
    code: 'CU',
    flag: '🇨🇺',
    name: 'Cuba',
    cities: [
      { name: 'Havana' },
      { name: 'Santiago de Cuba' },
      { name: 'Camagüey' },
      { name: 'Holguín' },
      { name: 'Guantánamo' },
      { name: 'Santa Clara' },
      { name: 'Las Tunas' },
      { name: 'Bayamo' },
      { name: 'Cienfuegos' },
      { name: 'Pinar del Río' },
      { name: 'Sancti Spíritus' },
      { name: 'Manzanillo' },
      { name: 'Ciego de Ávila' },
      { name: 'Morón' },
      { name: 'Palma Soriano' },
      { name: 'Matanzas' },
      { name: 'Nueva Gerona' },
      { name: 'Artemisa' },
      { name: 'Baracoa' },
      { name: 'Trinidad' },
    ],
  },
  {
    code: 'CW',
    flag: '🇨🇼',
    name: 'Curaçao',
    cities: [
      { name: 'Willemstad' },
      { name: 'Sint Michiel Liber' },
      { name: 'Dorp Soto' },
      { name: 'Barber' },
      { name: 'Sint Joris' },
      { name: 'Sint Willibrordus' },
      { name: 'Westpunt' },
      { name: 'Jan Thiel' },
      { name: 'Lagun' },
      { name: 'Bisento' },
      { name: 'Sta Catharina' },
      { name: 'Santa Cruz' },
      { name: 'Sabana Westpunt' },
      { name: 'Grote Berg' },
      { name: 'Banda Abou' },
      { name: 'Mambo Beach' },
      { name: 'Pietermaai' },
      { name: 'Otrabanda' },
      { name: 'Scharloo' },
      { name: 'Salina' },
    ],
  },
  {
    code: 'CY',
    flag: '🇨🇾',
    name: 'Cyprus',
    cities: [
      { name: 'Nicosia' },
      { name: 'Limassol' },
      { name: 'Larnaca' },
      { name: 'Famagusta' },
      { name: 'Paphos' },
      { name: 'Kyrenia' },
      { name: 'Protaras' },
      { name: 'Paralimni' },
      { name: 'Polis' },
      { name: 'Deryneia' },
      { name: 'Morphou' },
      { name: 'Xylofagou' },
      { name: 'Kokkinochoria' },
      { name: 'Tala' },
      { name: 'Kato Paphos' },
      { name: 'Yeroskipou' },
      { name: 'Episkopi' },
      { name: 'Agia Napa' },
      { name: 'Peyia' },
      { name: 'Zygi' },
    ],
  },
  {
    code: 'CZ',
    flag: '🇨🇿',
    name: 'Czechia',
    cities: [
      { name: 'Prague' },
      { name: 'Brno' },
      { name: 'Ostrava' },
      { name: 'Plzen' },
      { name: 'Olomouc' },
      { name: 'Liberec' },
      { name: 'Hradec Kralove' },
      { name: 'Pardubice' },
      { name: 'Zlin' },
      { name: 'Karlovy Vary' },
      { name: 'Ceske Budejovice' },
      { name: 'Usti nad Labem' },
      { name: 'Decin' },
      { name: 'Most' },
      { name: 'Chomutov' },
      { name: 'Jihlava' },
      { name: 'Opava' },
      { name: 'Frydek-Mistek' },
      { name: 'Teplice' },
      { name: 'Karvina' },
    ],
  },
  {
    code: 'DK',
    flag: '🇩🇰',
    name: 'Denmark',
    cities: [
      { name: 'Copenhagen' },
      { name: 'Aarhus' },
      { name: 'Odense' },
      { name: 'Aalborg' },
      { name: 'Esbjerg' },
      { name: 'Randers' },
      { name: 'Kolding' },
      { name: 'Horsens' },
      { name: 'Vejle' },
      { name: 'Roskilde' },
      { name: 'Herning' },
      { name: 'Silkeborg' },
      { name: 'Naestved' },
      { name: 'Fredericia' },
      { name: 'Frederikshavn' },
      { name: 'Hillerod' },
      { name: 'Sonderborg' },
      { name: 'Holstebro' },
      { name: 'Slagelse' },
      { name: 'Koge' },
    ],
  },
  {
    code: 'DJ',
    flag: '🇩🇯',
    name: 'Djibouti',
    cities: [
      { name: 'Djibouti' },
      { name: 'Ali Sabieh' },
      { name: 'Tadjourah' },
      { name: 'Obock' },
      { name: 'Dikhil' },
      { name: 'Arta' },
    ],
  },
  {
    code: 'DM',
    flag: '🇩🇲',
    name: 'Dominica',
    cities: [
      { name: 'Roseau' },
      { name: 'Portsmouth' },
      { name: 'Marigot' },
      { name: 'Mahaut' },
      { name: 'Wesley' },
      { name: 'Salisbury' },
      { name: 'Castle Bruce' },
      { name: 'Soufriere' },
      { name: 'La Plaine' },
      { name: 'Grand Bay' },
    ],
  },
  {
    code: 'DO',
    flag: '🇩🇴',
    name: 'Dominican Republic',
    cities: [
      { name: 'Santo Domingo' },
      { name: 'Santiago de los Caballeros' },
      { name: 'San Pedro de Macorís' },
      { name: 'La Romana' },
      { name: 'San Cristóbal' },
      { name: 'Puerto Plata' },
      { name: 'San Francisco de Macorís' },
      { name: 'Higüey' },
      { name: 'Mao' },
      { name: 'Bonao' },
      { name: 'Moca' },
      { name: 'Azua' },
      { name: 'Nagua' },
      { name: 'Neiba' },
      { name: 'Monte Cristi' },
      { name: 'Jimaní' },
    ],
  },
  {
    code: 'EC',
    flag: '🇪🇨',
    name: 'Ecuador',
    cities: [
      { name: 'Guayaquil' },
      { name: 'Quito' },
      { name: 'Cuenca' },
      { name: 'Manta' },
      { name: 'Portoviejo' },
      { name: 'Ambato' },
      { name: 'Machala' },
      { name: 'Santo Domingo' },
      { name: 'Loja' },
      { name: 'Esmeraldas' },
    ],
  },
  {
    code: 'EG',
    flag: '🇪🇬',
    name: 'Egypt',
    cities: [
      { name: 'Cairo' },
      { name: 'Alexandria' },
      { name: 'Giza' },
      { name: 'Shubra El-Kheima' },
      { name: 'Port Said' },
      { name: 'Suez' },
      { name: 'Luxor' },
      { name: 'El-Mahalla El-Kubra' },
      { name: 'Asyut' },
      { name: 'Mansoura' },
      { name: 'Tanta' },
      { name: 'Fayoum' },
      { name: 'Zagazig' },
      { name: 'Ismailia' },
      { name: 'Kafr El Sheikh' },
      { name: 'Assiut' },
    ],
  },
  {
    code: 'SV',
    flag: '🇸🇻',
    name: 'El Salvador',
    cities: [
      { name: 'San Salvador' },
      { name: 'Santa Ana' },
      { name: 'Soyapango' },
      { name: 'San Miguel' },
      { name: 'Santa Tecla' },
      { name: 'Apopa' },
      { name: 'Delgado' },
      { name: 'Ilopango' },
      { name: 'Mejicanos' },
      { name: 'Sonsonate' },
    ],
  },
  {
    code: 'GQ',
    flag: '🇬🇶',
    name: 'Equatorial Guinea',
    cities: [
      { name: 'Malabo' },
      { name: 'Bata' },
      { name: 'Ebebiyín' },
      { name: 'Aconibe' },
      { name: 'Anisoc' },
      { name: 'Luba' },
      { name: 'Evinayong' },
      { name: 'Mongomo' },
      { name: 'Micomeseng' },
      { name: 'Rebola' },
      { name: 'Nsok' },
    ],
  },
  {
    code: 'ER',
    flag: '🇪🇷',
    name: 'Eritrea',
    cities: [
      { name: 'Asmara' },
      { name: 'Keren' },
      { name: 'Mendefera' },
      { name: 'Adi Keyh' },
      { name: 'Dekemhare' },
      { name: 'Massawa' },
      { name: 'Assab' },
      { name: 'Barentu' },
      { name: "Ak'ordat" },
      { name: 'Teseney' },
    ],
  },
  {
    code: 'EE',
    flag: '🇪🇪',
    name: 'Estonia',
    cities: [
      { name: 'Tallinn' },
      { name: 'Tartu' },
      { name: 'Narva' },
      { name: 'Kohtla-Järve' },
      { name: 'Pärnu' },
      { name: 'Viljandi' },
      { name: 'Rakvere' },
      { name: 'Maardu' },
      { name: 'Sillamäe' },
      { name: 'Kuressaare' },
      { name: 'Valga' },
      { name: 'Jõhvi' },
      { name: 'Haapsalu' },
      { name: 'Keila' },
      { name: 'Paide' },
      { name: 'Türi' },
      { name: 'Põlva' },
      { name: 'Elva' },
      { name: 'Rapla' },
      { name: 'Saue' },
    ],
  },
  {
    code: 'ET',
    flag: '🇪🇹',
    name: 'Ethiopia',
    cities: [
      { name: 'Addis Ababa' },
      { name: 'Dire Dawa' },
      { name: 'Nazret' },
      { name: 'Bahir Dar' },
      { name: 'Gonder' },
      { name: 'Dessie' },
      { name: 'Mekele' },
      { name: 'Jimma' },
      { name: 'Jijiga' },
      { name: 'Harar' },
      { name: 'Awasa' },
      { name: 'Debre Birhan' },
      { name: 'Debre Markos' },
      { name: 'Nekemte' },
      { name: 'Gambela' },
      { name: 'Shashamane' },
    ],
  },
  {
    code: 'FK',
    flag: '🇫🇰',
    name: 'Falkland Islands (Malvinas)',
    cities: [{ name: 'Stanley' }],
  },
  {
    code: 'FO',
    flag: '🇫🇴',
    name: 'Faroe Islands',
    cities: [
      { name: 'Tórshavn' },
      { name: 'Klaksvík' },
      { name: 'Runavík' },
      { name: 'Tvøroyri' },
      { name: 'Fuglafjørður' },
      { name: 'Vágur' },
      { name: 'Saltangará' },
      { name: 'Miðvágur' },
      { name: 'Sørvágur' },
      { name: 'Sandur' },
      { name: 'Strendur' },
      { name: 'Vestmanna' },
      { name: 'Hvalba' },
      { name: 'Skopun' },
      { name: 'Vestmannaeyjar' },
      { name: 'Skúvoy' },
    ],
  },
  {
    code: 'FJ',
    flag: '🇫🇯',
    name: 'Fiji',
    cities: [
      { name: 'Suva' },
      { name: 'Nadi' },
      { name: 'Lautoka' },
      { name: 'Labasa' },
      { name: 'Ba' },
      { name: 'Levuka' },
      { name: 'Savusavu' },
      { name: 'Nasinu' },
      { name: 'Sigatoka' },
      { name: 'Rakiraki' },
    ],
  },
  {
    code: 'FI',
    flag: '🇫🇮',
    name: 'Finland',
    cities: [
      { name: 'Helsinki' },
      { name: 'Espoo' },
      { name: 'Tampere' },
      { name: 'Vantaa' },
      { name: 'Turku' },
      { name: 'Oulu' },
      { name: 'Lahti' },
      { name: 'Kuopio' },
      { name: 'Jyväskylä' },
      { name: 'Pori' },
      { name: 'Lappeenranta' },
      { name: 'Rovaniemi' },
      { name: 'Seinäjoki' },
      { name: 'Kokkola' },
      { name: 'Kotka' },
    ],
  },
  {
    code: 'FR',
    flag: '🇫🇷',
    name: 'France',
    cities: [
      { name: 'Paris' },
      { name: 'Marseille' },
      { name: 'Lyon' },
      { name: 'Toulouse' },
      { name: 'Nice' },
      { name: 'Nantes' },
      { name: 'Strasbourg' },
      { name: 'Montpellier' },
      { name: 'Bordeaux' },
      { name: 'Lille' },
      { name: 'Rennes' },
      { name: 'Reims' },
      { name: 'Le Havre' },
      { name: 'Saint-Étienne' },
      { name: 'Toulon' },
    ],
  },
  {
    code: 'GF',
    flag: '🇬🇫',
    name: 'French Guiana',
    cities: [
      { name: 'Cayenne' },
      { name: 'Saint-Laurent-du-Maroni' },
      { name: 'Kourou' },
      { name: 'Matoury' },
      { name: 'Remire-Montjoly' },
      { name: 'Macouria' },
      { name: 'Roura' },
      { name: 'Sinnamary' },
      { name: 'Iracoubo' },
      { name: 'Grand-Santi' },
      { name: 'Mana' },
      { name: 'Apatou' },
      { name: 'Awala-Yalimapo' },
      { name: 'Camopi' },
      { name: 'Papaichton' },
      { name: 'Regina' },
    ],
  },
  {
    code: 'PF',
    flag: '🇵🇫',
    name: 'French Polynesia',
    cities: [
      { name: 'Papeete' },
      { name: 'Faaa' },
      { name: 'Pirae' },
      { name: 'Mahina' },
      { name: 'Punaauia' },
      { name: 'Arue' },
      { name: 'Uturoa' },
      { name: 'Paea' },
      { name: 'Taravao' },
      { name: 'Hao' },
      { name: 'Papara' },
      { name: 'Taiarapu-Est' },
      { name: 'Taiarapu-Ouest' },
      { name: 'Teavaro' },
      { name: 'Hitiaa O Te Ra' },
      { name: 'Moorea-Maiao' },
      { name: 'Fare' },
      { name: 'Rikitea' },
      { name: 'Mataura' },
      { name: 'Makemo' },
      { name: 'Takaroa' },
      { name: 'Apatou' },
      { name: 'Gambier' },
      { name: 'Tatakoto' },
      { name: 'Nukutavake' },
    ],
  },
  {
    code: 'GA',
    flag: '🇬🇦',
    name: 'Gabon',
    cities: [
      { name: 'Libreville' },
      { name: 'Port-Gentil' },
      { name: 'Franceville' },
      { name: 'Oyem' },
      { name: 'Moanda' },
      { name: 'Mouila' },
      { name: 'Lambaréné' },
      { name: 'Tchibanga' },
      { name: 'Koulamoutou' },
      { name: 'Makokou' },
      { name: 'Bitam' },
      { name: 'Gamba' },
      { name: 'Mounana' },
      { name: 'Ndendé' },
      { name: 'Lastoursville' },
      { name: 'Booué' },
    ],
  },
  {
    code: 'GM',
    flag: '🇬🇲',
    name: 'Gambia',
    cities: [
      { name: 'Banjul' },
      { name: 'Serekunda' },
      { name: 'Brikama' },
      { name: 'Bakau' },
      { name: 'Farafenni' },
      { name: 'Lamin' },
      { name: 'Sukuta' },
      { name: 'Gunjur' },
      { name: 'Essau' },
      { name: 'Barra' },
      { name: 'Bansang' },
    ],
  },
  {
    code: 'GE',
    flag: '🇬🇪',
    name: 'Georgia',
    cities: [{ name: 'Tbilisi' }, { name: 'Kutaisi' }, { name: 'Batumi' }, { name: 'Rustavi' }, { name: 'Zugdidi' }],
  },
  {
    code: 'DE',
    flag: '🇩🇪',
    name: 'Germany',
    cities: [{ name: 'Berlin' }, { name: 'Hamburg' }, { name: 'Munich' }, { name: 'Cologne' }, { name: 'Frankfurt' }],
  },
  {
    code: 'GH',
    flag: '🇬🇭',
    name: 'Ghana',
    cities: [{ name: 'Accra' }, { name: 'Kumasi' }, { name: 'Tamale' }, { name: 'Takoradi' }, { name: 'Ashaiman' }],
  },
  {
    code: 'GI',
    flag: '🇬🇮',
    name: 'Gibraltar',
    cities: [{ name: 'Gibraltar' }],
  },
  {
    code: 'GR',
    flag: '🇬🇷',
    name: 'Greece',
    cities: [
      { name: 'Athens' },
      { name: 'Thessaloniki' },
      { name: 'Patras' },
      { name: 'Heraklion' },
      { name: 'Larissa' },
      { name: 'Volos' },
      { name: 'Ioannina' },
      { name: 'Chania' },
      { name: 'Chalcis' },
      { name: 'Serres' },
    ],
  },
  {
    code: 'GL',
    flag: '🇬🇱',
    name: 'Greenland',
    cities: [
      { name: 'Nuuk' },
      { name: 'Sisimiut' },
      { name: 'Ilulissat' },
      { name: 'Qaqortoq' },
      { name: 'Aasiaat' },
      { name: 'Maniitsoq' },
      { name: 'Tasiilaq' },
      { name: 'Narsaq' },
      { name: 'Paamiut' },
      { name: 'Nanortalik' },
    ],
  },
  {
    code: 'GD',
    flag: '🇬🇩',
    name: 'Grenada',
    cities: [
      { name: "St. George's" },
      { name: 'Grenville' },
      { name: 'Gouyave' },
      { name: 'Victoria' },
      { name: 'Sauteurs' },
      { name: 'Hillsborough' },
      { name: 'Grenada Saint George' },
    ],
  },
  {
    code: 'GP',
    flag: '🇬🇵',
    name: 'Guadeloupe',
    cities: [
      { name: 'Les Abymes' },
      { name: 'Baie-Mahault' },
      { name: 'Le Gosier' },
      { name: 'Petit-Bourg' },
      { name: 'Sainte-Anne' },
      { name: 'Pointe-à-Pitre' },
      { name: 'Le Moule' },
      { name: 'Sainte-Rose' },
      { name: 'Capesterre-Belle-Eau' },
      { name: 'Basse-Terre' },
      { name: 'Saint-Claude' },
      { name: 'Goyave' },
      { name: 'Trois-Rivières' },
      { name: 'Saint-François' },
      { name: 'Marie-Galante' },
      { name: 'Terre-de-Haut' },
      { name: 'Saint-Barthélemy' },
      { name: 'La Désirade' },
    ],
  },
  {
    code: 'GU',
    flag: '🇬🇺',
    name: 'Guam',
    cities: [
      { name: 'Hagåtña' },
      { name: 'Tamuning' },
      { name: 'Dededo' },
      { name: 'Yigo' },
      { name: 'Mangilao' },
      { name: 'Barrigada' },
      { name: 'Santa Rita' },
      { name: 'Chalan Pago-Ordot' },
      { name: 'Agat' },
      { name: 'Sinajana' },
      { name: 'Umatac' },
      { name: 'Inarajan' },
      { name: 'Merizo' },
      { name: 'Talofofo' },
    ],
  },
  {
    code: 'GT',
    flag: '🇬🇹',
    name: 'Guatemala',
    cities: [
      { name: 'Guatemala City' },
      { name: 'Mixco' },
      { name: 'Villa Nueva' },
      { name: 'Petapa' },
      { name: 'San Juan Sacatepéquez' },
      { name: 'Quetzaltenango' },
      { name: 'Villa Canales' },
      { name: 'Escuintla' },
      { name: 'Chinautla' },
      { name: 'Chimaltenango' },
      { name: 'Chichicastenango' },
      { name: 'Amatitlán' },
      { name: 'Totonicapán' },
      { name: 'Santa Catarina Pinula' },
      { name: 'Santa Lucía Cotzumalguapa' },
      { name: 'Puerto Barrios' },
      { name: 'San Francisco El Alto' },
      { name: 'Cobán' },
      { name: 'San Pedro Sacatepéquez' },
      { name: 'San Marcos' },
      { name: 'Jalapa' },
      { name: 'Jutiapa' },
      { name: 'Sololá' },
      { name: 'Huehuetenango' },
      { name: 'Santiago Atitlán' },
      { name: 'San Lucas Sacatepéquez' },
      { name: 'La Esperanza' },
      { name: 'Zacapa' },
    ],
  },
  {
    code: 'GG',
    flag: '🇬🇬',
    name: 'Guernsey',
    cities: [
      { name: 'Saint Peter Port' },
      { name: 'Vale' },
      { name: 'St. Sampson' },
      { name: 'St. Martin' },
      { name: 'Castel' },
      { name: 'Torteval' },
      { name: 'St. Pierre du Bois' },
      { name: 'Forest' },
      { name: 'Sark' },
    ],
  },
  {
    code: 'GN',
    flag: '🇬🇳',
    name: 'Guinea',
    cities: [
      { name: 'Conakry' },
      { name: 'Nzérékoré' },
      { name: 'Kindia' },
      { name: 'Kankan' },
      { name: 'Guéckédou' },
      { name: 'Labé' },
      { name: 'Kissidougou' },
      { name: 'Mamou' },
      { name: 'Faranah' },
      { name: 'Siguiri' },
    ],
  },
  {
    code: 'GW',
    flag: '🇬🇼',
    name: 'Guinea-Bissau',
    cities: [
      { name: 'Bissau' },
      { name: 'Bafatá' },
      { name: 'Gabú' },
      { name: 'Cacheu' },
      { name: 'Bolama' },
      { name: 'Mansôa' },
      { name: 'Safim' },
      { name: 'Buba' },
      { name: 'Quinhámel' },
      { name: 'Fulacunda' },
    ],
  },
  {
    code: 'GY',
    flag: '🇬🇾',
    name: 'Guyana',
    cities: [
      { name: 'Georgetown' },
      { name: 'Linden' },
      { name: 'New Amsterdam' },
      { name: 'Bartica' },
      { name: 'Corriverton' },
      { name: 'Rosignol' },
      { name: 'Anna Regina' },
      { name: 'Ituni' },
      { name: 'Mahaicony' },
      { name: 'Mahdia' },
    ],
  },
  {
    code: 'HT',
    flag: '🇭🇹',
    name: 'Haiti',
    cities: [
      { name: 'Port-au-Prince' },
      { name: 'Cap-Haïtien' },
      { name: 'Delmas' },
      { name: 'Pétion-Ville' },
      { name: 'Port-de-Paix' },
      { name: 'Jacmel' },
      { name: 'Léogâne' },
      { name: 'Les Cayes' },
      { name: 'Verrettes' },
      { name: 'Gonaïves' },
    ],
  },
  {
    code: 'HN',
    flag: '🇭🇳',
    name: 'Honduras',
    cities: [
      { name: 'Tegucigalpa' },
      { name: 'San Pedro Sula' },
      { name: 'Choloma' },
      { name: 'La Ceiba' },
      { name: 'El Progreso' },
      { name: 'Ciudad Choluteca' },
      { name: 'Comayagua' },
      { name: 'Danlí' },
      { name: 'Puerto Cortés' },
      { name: 'Juticalpa' },
    ],
  },
  {
    code: 'HK',
    flag: '🇭🇰',
    name: 'Hong Kong',
    cities: [
      { name: 'Hong Kong' },
      { name: 'Kowloon' },
      { name: 'Tsuen Wan' },
      { name: 'Yuen Long Kau Hui' },
      { name: 'Tung Chung' },
      { name: 'Tai Po' },
      { name: 'Sha Tin' },
      { name: 'Tuen Mun' },
      { name: 'Fanling-Sheung Shui' },
      { name: 'Central' },
    ],
  },
  {
    code: 'HU',
    flag: '🇭🇺',
    name: 'Hungary',
    cities: [
      { name: 'Budapest' },
      { name: 'Debrecen' },
      { name: 'Miskolc' },
      { name: 'Szeged' },
      { name: 'Pécs' },
      { name: 'Győr' },
      { name: 'Nyíregyháza' },
      { name: 'Kecskemét' },
      { name: 'Székesfehérvár' },
      { name: 'Szombathely' },
    ],
  },
  {
    code: 'IS',
    flag: '🇮🇸',
    name: 'Iceland',
    cities: [
      { name: 'Reykjavik' },
      { name: 'Kopavogur' },
      { name: 'Hafnarfjordur' },
      { name: 'Akureyri' },
      { name: 'Garðabær' },
      { name: 'Mosfellsbær' },
      { name: 'Selfoss' },
      { name: 'Seltjarnarnes' },
      { name: 'Hveragerði' },
      { name: 'Ísafjörður' },
    ],
  },
  {
    code: 'IN',
    flag: '🇮🇳',
    name: 'India',
    cities: [
      { name: 'Mumbai' },
      { name: 'Delhi' },
      { name: 'Bengaluru' },
      { name: 'Hyderabad' },
      { name: 'Ahmedabad' },
      { name: 'Chennai' },
      { name: 'Kolkata' },
      { name: 'Surat' },
      { name: 'Pune' },
      { name: 'Jaipur' },
    ],
  },
  {
    code: 'ID',
    flag: '🇮🇩',
    name: 'Indonesia',
    cities: [
      { name: 'Jakarta' },
      { name: 'Surabaya' },
      { name: 'Bandung' },
      { name: 'Medan' },
      { name: 'Bekasi' },
      { name: 'Semarang' },
      { name: 'Tangerang' },
      { name: 'Depok' },
      { name: 'Palembang' },
      { name: 'Makassar' },
    ],
  },
  {
    code: 'IR',
    flag: '🇮🇷',
    name: 'Iran (Islamic Republic of)',
    cities: [
      { name: 'Tehran' },
      { name: 'Mashhad' },
      { name: 'Isfahan' },
      { name: 'Karaj' },
      { name: 'Tabriz' },
      { name: 'Shiraz' },
      { name: 'Qom' },
      { name: 'Ahvaz' },
      { name: 'Kermanshah' },
      { name: 'Urmia' },
    ],
  },
  {
    code: 'IQ',
    flag: '🇮🇶',
    name: 'Iraq',
    cities: [
      { name: 'Baghdad' },
      { name: 'Basra' },
      { name: 'Mosul' },
      { name: 'Erbil' },
      { name: 'Najaf' },
      { name: 'Karbala' },
      { name: 'Kirkuk' },
      { name: 'Ramadi' },
      { name: 'Sulaymaniyah' },
      { name: 'Fallujah' },
    ],
  },
  {
    code: 'IE',
    flag: '🇮🇪',
    name: 'Ireland',
    cities: [
      { name: 'Dublin' },
      { name: 'Cork' },
      { name: 'Limerick' },
      { name: 'Galway' },
      { name: 'Waterford' },
      { name: 'Drogheda' },
      { name: 'Dundalk' },
      { name: 'Bray' },
      { name: 'Swords' },
      { name: 'Tralee' },
    ],
  },
  {
    code: 'IM',
    flag: '🇮🇲',
    name: 'Isle of Man',
    cities: [{ name: 'Douglas' }],
  },
  {
    code: 'IL',
    flag: '🇮🇱',
    name: 'Israel',
    cities: [
      { name: 'Jerusalem' },
      { name: 'Tel Aviv-Yafo' },
      { name: 'Haifa' },
      { name: 'Rishon LeZion' },
      { name: 'Petah Tikva' },
      { name: 'Ashdod' },
      { name: 'Netanya' },
      { name: 'Beer Sheva' },
      { name: 'Bnei Brak' },
      { name: 'Holon' },
    ],
  },
  {
    code: 'IT',
    flag: '🇮🇹',
    name: 'Italy',
    cities: [
      { name: 'Rome' },
      { name: 'Milan' },
      { name: 'Naples' },
      { name: 'Turin' },
      { name: 'Palermo' },
      { name: 'Genoa' },
      { name: 'Bologna' },
      { name: 'Florence' },
      { name: 'Bari' },
      { name: 'Catania' },
      { name: 'Venice' },
      { name: 'Verona' },
      { name: 'Messina' },
      { name: 'Padua' },
      { name: 'Trieste' },
      { name: 'Taranto' },
      { name: 'Brescia' },
      { name: 'Prato' },
      { name: 'Modena' },
      { name: 'Reggio Calabria' },
      { name: 'Reggio Emilia' },
      { name: 'Livorno' },
      { name: 'Foggia' },
      { name: 'Salerno' },
      { name: 'Ravenna' },
      { name: 'Cagliari' },
      { name: 'Matera' },
      { name: 'Lecce' },
      { name: 'Monza' },
      { name: 'Vicenza' },
      { name: 'La Spezia' },
      { name: 'Pescara' },
      { name: 'Arezzo' },
      { name: 'Novara' },
      { name: 'Alessandria' },
      { name: 'Cremona' },
      { name: 'Ascoli Piceno' },
      { name: 'Pistoia' },
      { name: 'Potenza' },
      { name: 'Agrigento' },
      { name: 'Benevento' },
      { name: 'Campobasso' },
      { name: 'Cosenza' },
      { name: 'Macerata' },
      { name: 'Massa' },
      { name: 'Teramo' },
      { name: 'Viterbo' },
      { name: 'Olbia' },
      { name: 'Sondrio' },
      { name: 'Biella' },
      { name: 'Lodi' },
      { name: 'Lecco' },
      { name: 'Rovigo' },
      { name: 'Siena' },
      { name: 'Matera' },
      { name: 'Grosseto' },
      { name: 'Trapani' },
      { name: 'Caltanissetta' },
      { name: 'Oristano' },
      { name: 'Nuoro' },
      { name: 'Carbonia' },
      { name: 'Iglesias' },
      { name: 'Sanremo' },
    ],
  },
  {
    code: 'JM',
    flag: '🇯🇲',
    name: 'Jamaica',
    cities: [
      { name: 'Kingston' },
      { name: 'Montego Bay' },
      { name: 'Mandeville' },
      { name: 'May Pen' },
      { name: 'Old Harbour' },
      { name: 'Spanish Town' },
      { name: 'Savanna-la-Mar' },
      { name: 'Port Antonio' },
      { name: 'Port Maria' },
      { name: 'Lucea' },
    ],
  },
  {
    code: 'JP',
    flag: '🇯🇵',
    name: 'Japan',
    cities: [
      { name: 'Tokyo' },
      { name: 'Yokohama' },
      { name: 'Osaka' },
      { name: 'Nagoya' },
      { name: 'Sapporo' },
      { name: 'Kobe' },
      { name: 'Kyoto' },
      { name: 'Fukuoka' },
      { name: 'Kawasaki' },
      { name: 'Hiroshima' },
    ],
  },
  {
    code: 'JE',
    flag: '🇯🇪',
    name: 'Jersey',
    cities: [
      { name: 'Saint Helier' },
      { name: 'Saint Saviour' },
      { name: 'Saint Clement' },
      { name: 'Grouville' },
      { name: 'Trinity' },
      { name: 'Saint Brelade' },
      { name: 'Saint Lawrence' },
      { name: 'Saint Martin' },
      { name: 'Saint Ouen' },
      { name: 'Saint Mary' },
    ],
  },
  {
    code: 'JO',
    flag: '🇯🇴',
    name: 'Jordan',
    cities: [
      { name: 'Amman' },
      { name: 'Zarqa' },
      { name: 'Irbid' },
      { name: 'Russeifa' },
      { name: 'Al Quwaysimah' },
      { name: 'Aqaba' },
      { name: 'As Salt' },
      { name: 'Madaba' },
      { name: 'Al Karak' },
      { name: "Ma'an" },
    ],
  },
  {
    code: 'KZ',
    flag: '🇰🇿',
    name: 'Kazakhstan',
    cities: [
      { name: 'Almaty' },
      { name: 'Nur-Sultan' },
      { name: 'Shymkent' },
      { name: 'Karaganda' },
      { name: 'Taraz' },
      { name: 'Aktobe' },
      { name: 'Pavlodar' },
      { name: 'Ust-Kamenogorsk' },
      { name: 'Semey' },
      { name: 'Kostanay' },
    ],
  },
  {
    code: 'KE',
    flag: '🇰🇪',
    name: 'Kenya',
    cities: [
      { name: 'Nairobi' },
      { name: 'Mombasa' },
      { name: 'Kisumu' },
      { name: 'Nakuru' },
      { name: 'Eldoret' },
      { name: 'Thika' },
      { name: 'Malindi' },
      { name: 'Kitale' },
      { name: 'Garissa' },
      { name: 'Kakamega' },
    ],
  },
  {
    code: 'KI',
    flag: '🇰🇮',
    name: 'Kiribati',
    cities: [
      { name: 'South Tarawa' },
      { name: 'Betio Village' },
      { name: 'Bikenibeu Village' },
      { name: 'Tebakor Village' },
      { name: 'Bairiki Village' },
      { name: 'Eita Village' },
      { name: 'Antebuka Village' },
      { name: 'Banraeaba Village' },
      { name: 'Rawannawi Village' },
      { name: 'Utiroa Village' },
      { name: 'Taburao Village' },
      { name: 'Bonriki Village' },
    ],
  },
  {
    code: 'XK',
    flag: '🇽🇰',
    name: 'Kosovo (Republic of)',
    cities: [
      { name: 'Prishtina' },
      { name: 'Prizren' },
      { name: 'Gjilan' },
      { name: 'Përnajt' },
      { name: 'Pejë' },
      { name: 'Mitrovicë' },
      { name: 'Ferizaj' },
      { name: 'Gjakovë' },
      { name: 'Podujevë' },
      { name: 'Kamenicë' },
      { name: 'Kërcovë' },
      { name: 'Leposaviq' },
    ],
  },
  {
    code: 'KW',
    flag: '🇰🇼',
    name: 'Kuwait',
    cities: [
      { name: 'Kuwait City' },
      { name: 'Al Ahmadi' },
      { name: 'Hawalli' },
      { name: 'Farwaniya' },
      { name: 'As Salimiyah' },
      { name: 'Sabah as Salim' },
      { name: 'Al Fahahil' },
      { name: 'Kuwait Naval Base' },
      { name: 'Al Khiran' },
    ],
  },
  {
    code: 'KG',
    flag: '🇰🇬',
    name: 'Kyrgyzstan',
    cities: [
      { name: 'Bishkek' },
      { name: 'Osh' },
      { name: 'Jalal-Abad' },
      { name: 'Karakol' },
      { name: 'Talas' },
      { name: 'Tokmok' },
      { name: 'Kyzyl-Kiya' },
      { name: 'Kara-Balta' },
      { name: 'Naryn' },
      { name: 'Balykchy' },
      { name: 'Batken' },
    ],
  },
  {
    code: 'LA',
    flag: '🇱🇦',
    name: "Lao People's Democratic Republic",
    cities: [
      { name: 'Vientiane' },
      { name: 'Savannakhet' },
      { name: 'Luang Prabang' },
      { name: 'Pakxe' },
      { name: 'Thakhek' },
    ],
  },
  {
    code: 'LV',
    flag: '🇱🇻',
    name: 'Latvia',
    cities: [{ name: 'Riga' }, { name: 'Daugavpils' }, { name: 'Liepaja' }, { name: 'Jelgava' }, { name: 'Jurmala' }],
  },
  {
    code: 'LB',
    flag: '🇱🇧',
    name: 'Lebanon',
    cities: [{ name: 'Beirut' }, { name: 'Tripoli' }, { name: 'Sidon' }, { name: 'Tyre' }, { name: 'Jounieh' }],
  },
  {
    code: 'LS',
    flag: '🇱🇸',
    name: 'Lesotho',
    cities: [
      { name: 'Maseru' },
      { name: 'Teyateyaneng' },
      { name: 'Mafeteng' },
      { name: 'Hlotse' },
      { name: "Mohale's Hoek" },
    ],
  },
  {
    code: 'LR',
    flag: '🇱🇷',
    name: 'Liberia',
    cities: [
      { name: 'Monrovia' },
      { name: 'Gbarnga' },
      { name: 'Bensonville' },
      { name: 'Harper' },
      { name: 'Kakata' },
    ],
  },
  {
    code: 'LY',
    flag: '🇱🇾',
    name: 'Libya',
    cities: [
      { name: 'Tripoli' },
      { name: 'Benghazi' },
      { name: 'Misrata' },
      { name: 'Tarhuna' },
      { name: 'Bayda' },
      { name: 'Zawiya' },
      { name: 'Sabha' },
      { name: 'Zliten' },
      { name: 'Derna' },
      { name: 'Ajdabiya' },
    ],
  },
  {
    code: 'LI',
    flag: '🇱🇮',
    name: 'Liechtenstein',
    cities: [
      { name: 'Vaduz' },
      { name: 'Schaan' },
      { name: 'Triesen' },
      { name: 'Balzers' },
      { name: 'Eschen' },
      { name: 'Mauren' },
      { name: 'Triesenberg' },
      { name: 'Ruggell' },
      { name: 'Gamprin' },
      { name: 'Planken' },
    ],
  },
  {
    code: 'LT',
    flag: '🇱🇹',
    name: 'Lithuania',
    cities: [
      { name: 'Vilnius' },
      { name: 'Kaunas' },
      { name: 'Klaipėda' },
      { name: 'Šiauliai' },
      { name: 'Panevėžys' },
      { name: 'Alytus' },
      { name: 'Marijampolė' },
      { name: 'Mažeikiai' },
      { name: 'Jonava' },
      { name: 'Utena' },
    ],
  },
  {
    code: 'LU',
    flag: '🇱🇺',
    name: 'Luxembourg',
    cities: [
      { name: 'Luxembourg City' },
      { name: 'Esch-sur-Alzette' },
      { name: 'Differdange' },
      { name: 'Dudelange' },
      { name: 'Ettelbruck' },
      { name: 'Diekirch' },
      { name: 'Strassen' },
      { name: 'Bertrange' },
      { name: 'Belvaux' },
      { name: 'Echternach' },
    ],
  },
  {
    code: 'MO',
    flag: '🇲🇴',
    name: 'Macao',
    cities: [{ name: 'Macau' }, { name: 'Taipa' }, { name: 'Cotai' }, { name: 'Coloane' }],
  },
  {
    code: 'MK',
    flag: '🇲🇰',
    name: 'North Macedonia',
    cities: [
      { name: 'Skopje' },
      { name: 'Bitola' },
      { name: 'Kumanovo' },
      { name: 'Prilep' },
      { name: 'Tetovo' },
      { name: 'Veles' },
      { name: 'Ohrid' },
      { name: 'Gostivar' },
      { name: 'Struga' },
      { name: 'Kavadarci' },
    ],
  },
  {
    code: 'MG',
    flag: '🇲🇬',
    name: 'Madagascar',
    cities: [
      { name: 'Antananarivo' },
      { name: 'Toamasina' },
      { name: 'Antsirabe' },
      { name: 'Fianarantsoa' },
      { name: 'Mahajanga' },
      { name: 'Toliara' },
      { name: 'Antsiranana' },
      { name: 'Antanifotsy' },
      { name: 'Ambovombe' },
      { name: 'Ambatondrazaka' },
    ],
  },
  {
    code: 'MW',
    flag: '🇲🇼',
    name: 'Malawi',
    cities: [
      { name: 'Lilongwe' },
      { name: 'Blantyre' },
      { name: 'Mzuzu' },
      { name: 'Zomba' },
      { name: 'Kasungu' },
      { name: 'Mangochi' },
      { name: 'Karonga' },
      { name: 'Salima' },
      { name: 'Nkhata Bay' },
      { name: 'Mchinji' },
    ],
  },
  {
    code: 'MY',
    flag: '🇲🇾',
    name: 'Malaysia',
    cities: [
      { name: 'Kuala Lumpur' },
      { name: 'George Town' },
      { name: 'Johor Bahru' },
      { name: 'Ipoh' },
      { name: 'Kuching' },
      { name: 'Petaling Jaya' },
      { name: 'Shah Alam' },
      { name: 'Kota Kinabalu' },
      { name: 'Malacca City' },
      { name: 'Alor Setar' },
    ],
  },
  {
    code: 'MV',
    flag: '🇲🇻',
    name: 'Maldives',
    cities: [
      { name: 'Male' },
      { name: 'Addu City' },
      { name: 'Fuvahmulah' },
      { name: 'Hithadhoo' },
      { name: 'Kulhudhuffushi' },
      { name: 'Thinadhoo' },
      { name: 'Naifaru' },
      { name: 'Dhidhdhoo' },
      { name: 'Eydhafushi' },
      { name: 'Muli' },
    ],
  },
  {
    code: 'ML',
    flag: '🇲🇱',
    name: 'Mali',
    cities: [
      { name: 'Bamako' },
      { name: 'Sikasso' },
      { name: 'Mopti' },
      { name: 'Koutiala' },
      { name: 'Kayes' },
      { name: 'Ségou' },
      { name: 'Gao' },
      { name: 'Markala' },
      { name: 'Kati' },
      { name: 'Bougouni' },
    ],
  },
  {
    code: 'MT',
    flag: '🇲🇹',
    name: 'Malta',
    cities: [
      { name: 'Valletta' },
      { name: 'Birkirkara' },
      { name: 'Mosta' },
      { name: 'Qormi' },
      { name: 'Zabbar' },
      { name: 'Saint John' },
      { name: 'Fgura' },
      { name: 'Zejtun' },
      { name: 'Sliema' },
      { name: 'Marsaskala' },
    ],
  },
  {
    code: 'MH',
    flag: '🇲🇭',
    name: 'Marshall Islands',
    cities: [
      { name: 'Majuro' },
      { name: 'Ebeye' },
      { name: 'Arno Atoll' },
      { name: 'Jabor' },
      { name: 'Jaluit Atoll' },
      { name: 'Wotje Atoll' },
      { name: 'Mili Atoll' },
      { name: 'Namdrik Atoll' },
      { name: 'Kwajalein Atoll' },
      { name: 'Ailinglaplap Atoll' },
    ],
  },
  {
    code: 'MQ',
    flag: '🇲🇶',
    name: 'Martinique',
    cities: [
      { name: 'Fort-de-France' },
      { name: 'Ducos' },
      { name: 'Le Lamentin' },
      { name: 'Le Robert' },
      { name: 'Sainte-Marie' },
      { name: 'Saint-Joseph' },
      { name: 'Le François' },
      { name: 'Saint-Pierre' },
      { name: 'La Trinité' },
      { name: 'Les Trois-Îlets' },
    ],
  },
  {
    code: 'MR',
    flag: '🇲🇷',
    name: 'Mauritania',
    cities: [
      { name: 'Nouakchott' },
      { name: 'Nouadhibou' },
      { name: 'Kiffa' },
      { name: 'Zouérat' },
      { name: 'Atar' },
      { name: 'Rosso' },
      { name: 'Tidjikja' },
      { name: 'Néma' },
      { name: 'Kaédi' },
      { name: 'Aleg' },
    ],
  },
  {
    code: 'MU',
    flag: '🇲🇺',
    name: 'Mauritius',
    cities: [
      { name: 'Port Louis' },
      { name: 'Vacoas-Phoenix' },
      { name: 'Curepipe' },
      { name: 'Quatre Bornes' },
      { name: 'Triolet' },
      { name: 'Goodlands' },
      { name: 'Centre de Flacq' },
      { name: 'Bel Air Rivière Sèche' },
      { name: 'Mahébourg' },
      { name: 'Saint Pierre' },
    ],
  },
  {
    code: 'YT',
    flag: '🇾🇹',
    name: 'Mayotte',
    cities: [
      { name: 'Mamoudzou' },
      { name: 'Koungou' },
      { name: 'Dzaoudzi' },
      { name: 'Dembeni' },
      { name: 'Sada' },
      { name: 'Bandraboua' },
      { name: 'Ouangani' },
      { name: 'Chiconi' },
      { name: 'Chirongui' },
      { name: 'Mtsamboro' },
    ],
  },
  {
    code: 'MX',
    flag: '🇲🇽',
    name: 'Mexico',
    cities: [
      { name: 'Mexico City' },
      { name: 'Guadalajara' },
      { name: 'Monterrey' },
      { name: 'Puebla' },
      { name: 'Tijuana' },
      { name: 'León' },
      { name: 'Cancún' },
      { name: 'Toluca' },
      { name: 'Ciudad Juárez' },
      { name: 'Mérida' },
      { name: 'Aguascalientes' },
      { name: 'Chihuahua' },
      { name: 'Morelia' },
      { name: 'Cuernavaca' },
      { name: 'San Luis Potosí' },
      { name: 'Querétaro' },
      { name: 'Tuxtla Gutiérrez' },
      { name: 'Torreón' },
      { name: 'Zacatecas' },
      { name: 'Villahermosa' },
    ],
  },
  {
    code: 'FM',
    flag: '🇫🇲',
    name: 'Micronesia',
    cities: [{ name: 'Palikir' }, { name: 'Weno' }, { name: 'Colonia' }, { name: 'Kolonia Town' }, { name: 'Tofol' }],
  },
  {
    code: 'MD',
    flag: '🇲🇩',
    name: 'Moldova, Republic of',
    cities: [{ name: 'Chișinău' }, { name: 'Tiraspol' }, { name: 'Bălți' }, { name: 'Bender' }, { name: 'Rîbnița' }],
  },
  {
    code: 'MC',
    flag: '🇲🇨',
    name: 'Monaco',
    cities: [{ name: 'Monaco' }, { name: 'Monte Carlo' }, { name: 'La Condamine' }, { name: 'Fontvieille' }],
  },
  {
    code: 'MN',
    flag: '🇲🇳',
    name: 'Mongolia',
    cities: [
      { name: 'Ulaanbaatar' },
      { name: 'Erdenet' },
      { name: 'Darkhan' },
      { name: 'Choibalsan' },
      { name: 'Ölgii' },
    ],
  },
  {
    code: 'ME',
    flag: '🇲🇪',
    name: 'Montenegro',
    cities: [{ name: 'Podgorica' }, { name: 'Nikšić' }, { name: 'Herceg Novi' }, { name: 'Bar' }, { name: 'Budva' }],
  },
  {
    code: 'MS',
    flag: '🇲🇸',
    name: 'Montserrat',
    cities: [{ name: 'Brades' }, { name: 'Plymouth' }, { name: 'Little Bay' }, { name: "St. John's" }],
  },
  {
    code: 'MA',
    flag: '🇲🇦',
    name: 'Morocco',
    cities: [
      { name: 'Casablanca' },
      { name: 'Rabat' },
      { name: 'Fes' },
      { name: 'Marrakech' },
      { name: 'Agadir' },
      { name: 'Tangier' },
      { name: 'Meknes' },
      { name: 'Oujda' },
      { name: 'Kenitra' },
      { name: 'Tetouan' },
      { name: 'Safi' },
      { name: 'Laayoune' },
    ],
  },
  {
    code: 'MZ',
    flag: '🇲🇿',
    name: 'Mozambique',
    cities: [
      { name: 'Maputo' },
      { name: 'Matola' },
      { name: 'Beira' },
      { name: 'Nampula' },
      { name: 'Chimoio' },
      { name: 'Cidade de Nacala' },
      { name: 'Quelimane' },
      { name: 'Tete' },
      { name: 'Xai-Xai' },
      { name: 'Maxixe' },
      { name: 'Lichinga' },
      { name: 'Pemba' },
    ],
  },
  {
    code: 'MM',
    flag: '🇲🇲',
    name: 'Myanmar',
    cities: [
      { name: 'Yangon' },
      { name: 'Mandalay' },
      { name: 'Naypyidaw' },
      { name: 'Mawlamyine' },
      { name: 'Bago' },
      { name: 'Pathein' },
      { name: 'Monywa' },
      { name: 'Sittwe' },
      { name: 'Taunggyi' },
      { name: 'Myeik' },
      { name: 'Dawei' },
    ],
  },
  {
    code: 'NA',
    flag: '🇳🇦',
    name: 'Namibia',
    cities: [
      { name: 'Windhoek' },
      { name: 'Rundu' },
      { name: 'Walvis Bay' },
      { name: 'Oshakati' },
      { name: 'Swakopmund' },
      { name: 'Katima Mulilo' },
      { name: 'Grootfontein' },
      { name: 'Rehoboth' },
      { name: 'Otjiwarongo' },
      { name: 'Okahandja' },
      { name: 'Keetmanshoop' },
      { name: 'Lüderitz' },
    ],
  },
  {
    code: 'NR',
    flag: '🇳🇷',
    name: 'Nauru',
    cities: [
      { name: 'Aiwo' },
      { name: 'Anabar' },
      { name: 'Anetan' },
      { name: 'Anibare' },
      { name: 'Baiti' },
      { name: 'Boe' },
      { name: 'Buada' },
      { name: 'Denigomodu' },
      { name: 'Ewa' },
      { name: 'Ijuw' },
      { name: 'Meneng' },
      { name: 'Nibok' },
      { name: 'Uaboe' },
      { name: 'Yaren' },
    ],
  },
  {
    code: 'NP',
    flag: '🇳🇵',
    name: 'Nepal',
    cities: [
      { name: 'Kathmandu' },
      { name: 'Pokhara' },
      { name: 'Biratnagar' },
      { name: 'Bharatpur' },
      { name: 'Janakpur' },
      { name: 'Hetauda' },
      { name: 'Bhaktapur' },
      { name: 'Dharan' },
      { name: 'Birgunj' },
      { name: 'Butwal' },
    ],
  },
  {
    code: 'NL',
    flag: '🇳🇱',
    name: 'Netherlands',
    cities: [
      { name: 'Amsterdam' },
      { name: 'Rotterdam' },
      { name: 'The Hague' },
      { name: 'Utrecht' },
      { name: 'Eindhoven' },
      { name: 'Tilburg' },
      { name: 'Groningen' },
      { name: 'Almere' },
      { name: 'Breda' },
      { name: 'Nijmegen' },
    ],
  },
  {
    code: 'NC',
    flag: '🇳🇨',
    name: 'New Caledonia',
    cities: [
      { name: 'Nouméa' },
      { name: 'Mont-Dore' },
      { name: 'Dumbéa' },
      { name: 'Païta' },
      { name: 'Wé' },
      { name: 'Tadine' },
      { name: 'Koné' },
      { name: 'Lifou' },
      { name: 'Ouégoa' },
      { name: 'Boulouparis' },
    ],
  },
  {
    code: 'NZ',
    flag: '🇳🇿',
    name: 'New Zealand',
    cities: [
      { name: 'Auckland' },
      { name: 'Wellington' },
      { name: 'Christchurch' },
      { name: 'Hamilton' },
      { name: 'Tauranga' },
      { name: 'Napier' },
      { name: 'Dunedin' },
      { name: 'Palmerston North' },
      { name: 'Nelson' },
      { name: 'Rotorua' },
    ],
  },
  {
    code: 'NI',
    flag: '🇳🇮',
    name: 'Nicaragua',
    cities: [
      { name: 'Managua' },
      { name: 'León' },
      { name: 'Masaya' },
      { name: 'Chinandega' },
      { name: 'Matagalpa' },
      { name: 'Estelí' },
      { name: 'Granada' },
      { name: 'Juigalpa' },
      { name: 'Jinotega' },
      { name: 'Bluefields' },
    ],
  },
  {
    code: 'NE',
    flag: '🇳🇪',
    name: 'Niger',
    cities: [
      { name: 'Niamey' },
      { name: 'Zinder' },
      { name: 'Maradi' },
      { name: 'Agadez' },
      { name: 'Tahoua' },
      { name: 'Dosso' },
      { name: 'Diffa' },
      { name: 'Arlit' },
      { name: 'Madaoua' },
      { name: 'Mirya' },
    ],
  },
  {
    code: 'NG',
    flag: '🇳🇬',
    name: 'Nigeria',
    cities: [
      { name: 'Lagos' },
      { name: 'Kano' },
      { name: 'Ibadan' },
      { name: 'Benin City' },
      { name: 'Port Harcourt' },
      { name: 'Kaduna' },
      { name: 'Aba' },
      { name: 'Ogbomosho' },
      { name: 'Maiduguri' },
      { name: 'Enugu' },
    ],
  },
  { code: 'NU', flag: '🇳🇺', name: 'Niue', cities: [{ name: 'Alofi' }] },
  {
    code: 'NF',
    flag: '🇳🇫',
    name: 'Norfolk Island',
    cities: [{ name: 'Kingston' }],
  },
  {
    code: 'KP',
    flag: '🇰🇵',
    name: 'South Korea',
    cities: [
      { name: 'Seoul' },
      { name: 'Busan' },
      { name: 'Incheon' },
      { name: 'Daegu' },
      { name: 'Daejeon' },
      { name: 'Gwangju' },
      { name: 'Suwon' },
      { name: 'Ulsan' },
      { name: 'Sejong' },
      { name: 'Changwon' },
    ],
  },
  {
    code: 'MP',
    flag: '🇲🇵',
    name: 'Northern Mariana Islands',
    cities: [{ name: 'Saipan' }],
  },
  {
    code: 'NO',
    flag: '🇳🇴',
    name: 'Norway',
    cities: [
      { name: 'Oslo' },
      { name: 'Bergen' },
      { name: 'Trondheim' },
      { name: 'Stavanger' },
      { name: 'Drammen' },
      { name: 'Fredrikstad' },
      { name: 'Porsgrunn' },
      { name: 'Kristiansand' },
      { name: 'Ålesund' },
      { name: 'Tønsberg' },
      { name: 'Moss' },
      { name: 'Haugesund' },
      { name: 'Sandefjord' },
      { name: 'Arendal' },
      { name: 'Sarpsborg' },
    ],
  },
  {
    code: 'OM',
    flag: '🇴🇲',
    name: 'Oman',
    cities: [
      { name: 'Muscat' },
      { name: 'Salalah' },
      { name: 'Sohar' },
      { name: 'Sur' },
      { name: 'Nizwa' },
      { name: 'Ibri' },
      { name: 'Bahla' },
      { name: 'Barka' },
      { name: 'Rustaq' },
      { name: 'Al Buraimi' },
    ],
  },
  {
    code: 'PK',
    flag: '🇵🇰',
    name: 'Pakistan',
    cities: [
      { name: 'Karachi' },
      { name: 'Lahore' },
      { name: 'Faisalabad' },
      { name: 'Rawalpindi' },
      { name: 'Multan' },
      { name: 'Hyderabad' },
      { name: 'Gujranwala' },
      { name: 'Peshawar' },
      { name: 'Quetta' },
      { name: 'Islamabad' },
    ],
  },
  {
    code: 'PW',
    flag: '🇵🇼',
    name: 'Palau',
    cities: [
      { name: 'Koror' },
      { name: 'Melekeok' },
      { name: 'Airai' },
      { name: 'Angaur' },
      { name: 'Hatohobei' },
      { name: 'Kayangel' },
      { name: 'Ngerulmud' },
      { name: 'Ngardmau' },
      { name: 'Ngaraard' },
      { name: 'Ngatpang' },
    ],
  },
  {
    code: 'PS',
    flag: '🇵🇸',
    name: 'Palestine, State of',
    cities: [
      { name: 'Gaza' },
      { name: 'Ramallah' },
      { name: 'Hebron' },
      { name: 'Nablus' },
      { name: 'Bethlehem' },
      { name: 'Jenin' },
      { name: 'Tulkarm' },
      { name: 'Jericho' },
      { name: 'Qalqilya' },
      { name: 'East Jerusalem' },
    ],
  },
  {
    code: 'PA',
    flag: '🇵🇦',
    name: 'Panama',
    cities: [
      { name: 'Panama City' },
      { name: 'Colón' },
      { name: 'David' },
      { name: 'Santiago' },
      { name: 'Chitré' },
      { name: 'Las Tablas' },
      { name: 'La Chorrera' },
      { name: 'Penonomé' },
      { name: 'Aguadulce' },
      { name: 'Bocas del Toro' },
    ],
  },
  {
    code: 'PG',
    flag: '🇵🇬',
    name: 'Papua New Guinea',
    cities: [
      { name: 'Port Moresby' },
      { name: 'Lae' },
      { name: 'Arawa' },
      { name: 'Mount Hagen' },
      { name: 'Popondetta' },
      { name: 'Madang' },
      { name: 'Kokopo' },
      { name: 'Mendi' },
      { name: 'Kimbe' },
      { name: 'Darwin' },
    ],
  },
  {
    code: 'PY',
    flag: '🇵🇾',
    name: 'Paraguay',
    cities: [
      { name: 'Asunción' },
      { name: 'Ciudad del Este' },
      { name: 'San Lorenzo' },
      { name: 'Luque' },
      { name: 'Capiatá' },
      { name: 'Lambaré' },
      { name: 'Fernando de la Mora' },
      { name: 'Encarnación' },
      { name: 'Itauguá' },
      { name: 'Mariano Roque Alonso' },
    ],
  },
  {
    code: 'PE',
    flag: '🇵🇪',
    name: 'Peru',
    cities: [
      { name: 'Lima' },
      { name: 'Arequipa' },
      { name: 'Trujillo' },
      { name: 'Chiclayo' },
      { name: 'Piura' },
      { name: 'Iquitos' },
      { name: 'Cusco' },
      { name: 'Chimbote' },
      { name: 'Huancayo' },
      { name: 'Tacna' },
    ],
  },
  {
    code: 'PH',
    flag: '🇵🇭',
    name: 'Philippines',
    cities: [
      { name: 'Quezon City' },
      { name: 'Manila' },
      { name: 'Caloocan' },
      { name: 'Davao City' },
      { name: 'Cebu City' },
      { name: 'Zamboanga City' },
      { name: 'Taguig' },
      { name: 'Antipolo' },
      { name: 'Pasig' },
      { name: 'Valenzuela' },
    ],
  },
  { code: 'PN', flag: '🇵🇳', name: 'Pitcairn', cities: [{ name: 'Adamstown' }] },
  {
    code: 'PL',
    flag: '🇵🇱',
    name: 'Poland',
    cities: [
      { name: 'Warsaw' },
      { name: 'Kraków' },
      { name: 'Łódź' },
      { name: 'Wrocław' },
      { name: 'Poznań' },
      { name: 'Gdańsk' },
      { name: 'Szczecin' },
      { name: 'Bydgoszcz' },
      { name: 'Lublin' },
      { name: 'Katowice' },
    ],
  },
  {
    code: 'PT',
    flag: '🇵🇹',
    name: 'Portugal',
    cities: [
      { name: 'Lisbon' },
      { name: 'Porto' },
      { name: 'Amadora' },
      { name: 'Braga' },
      { name: 'Setúbal' },
      { name: 'Coimbra' },
      { name: 'Queluz' },
      { name: 'Funchal' },
      { name: 'Cacém' },
      { name: 'Vila Nova de Gaia' },
      { name: 'Almada' },
      { name: 'Rio de Mouro' },
      { name: 'Aveiro' },
      { name: 'Odivelas' },
      { name: 'Évora' },
    ],
  },
  {
    code: 'PR',
    flag: '🇵🇷',
    name: 'Puerto Rico',
    cities: [
      { name: 'San Juan' },
      { name: 'Bayamón' },
      { name: 'Carolina' },
      { name: 'Ponce' },
      { name: 'Caguas' },
      { name: 'Guaynabo' },
      { name: 'Mayagüez' },
      { name: 'Arecibo' },
      { name: 'Trujillo Alto' },
      { name: 'Fajardo' },
    ],
  },
  {
    code: 'QA',
    flag: '🇶🇦',
    name: 'Qatar',
    cities: [
      { name: 'Doha' },
      { name: 'Al Rayyan' },
      { name: 'Umm Salal' },
      { name: 'Al Wakrah' },
      { name: 'Al Khor' },
      { name: 'Ash Shahaniyah' },
      { name: 'Dukhan' },
      { name: 'Al Wukayr' },
      { name: 'Al Ghuwayriyah' },
    ],
  },
  {
    code: 'RE',
    flag: '🇷🇪',
    name: 'Réunion',
    cities: [
      { name: 'Saint-Denis' },
      { name: 'Saint-Paul' },
      { name: 'Saint-Pierre' },
      { name: 'Le Tampon' },
      { name: 'Saint-André' },
      { name: 'Saint-Louis' },
      { name: 'Le Port' },
      { name: 'Saint-Benoît' },
      { name: 'Saint-Joseph' },
      { name: 'Sainte-Marie' },
    ],
  },
  {
    code: 'RO',
    flag: '🇷🇴',
    name: 'Romania',
    cities: [
      { name: 'Bucharest' },
      { name: 'Cluj-Napoca' },
      { name: 'Timișoara' },
      { name: 'Iași' },
      { name: 'Constanța' },
      { name: 'Craiova' },
      { name: 'Galați' },
      { name: 'Brașov' },
      { name: 'Ploiești' },
      { name: 'Oradea' },
      { name: 'Brăila' },
      { name: 'Arad' },
      { name: 'Pitești' },
      { name: 'Sibiu' },
      { name: 'Bacău' },
      { name: 'Târgu Mureș' },
      { name: 'Baia Mare' },
    ],
  },
  {
    code: 'RU',
    flag: '🇷🇺',
    name: 'Russian Federation',
    cities: [
      { name: 'Moscow' },
      { name: 'Saint Petersburg' },
      { name: 'Novosibirsk' },
      { name: 'Yekaterinburg' },
      { name: 'Nizhny Novgorod' },
      { name: 'Kazan' },
      { name: 'Chelyabinsk' },
      { name: 'Omsk' },
      { name: 'Samara' },
      { name: 'Rostov-on-Don' },
      { name: 'Ufa' },
      { name: 'Krasnoyarsk' },
      { name: 'Voronezh' },
      { name: 'Perm' },
      { name: 'Volgograd' },
    ],
  },
  {
    code: 'RW',
    flag: '🇷🇼',
    name: 'Rwanda',
    cities: [
      { name: 'Kigali' },
      { name: 'Butare' },
      { name: 'Gitarama' },
      { name: 'Ruhengeri' },
      { name: 'Gisenyi' },
      { name: 'Byumba' },
      { name: 'Cyangugu' },
    ],
  },
  {
    code: 'BL',
    flag: '🇧🇱',
    name: 'Saint Barthélemy',
    cities: [{ name: 'Gustavia' }],
  },
  {
    code: 'KN',
    flag: '🇰🇳',
    name: 'Saint Kitts and Nevis',
    cities: [{ name: 'Basseterre' }, { name: 'Charlestown' }],
  },
  {
    code: 'LC',
    flag: '🇱🇨',
    name: 'Saint Lucia',
    cities: [
      { name: 'Castries' },
      { name: 'Vieux Fort' },
      { name: 'Micoud' },
      { name: 'Soufrière' },
      { name: 'Dennery' },
    ],
  },
  {
    code: 'MF',
    flag: '🇲🇫',
    name: 'Saint Martin (French part)',
    cities: [{ name: 'Marigot' }],
  },
  {
    code: 'PM',
    flag: '🇵🇲',
    name: 'Saint Pierre and Miquelon',
    cities: [{ name: 'Saint-Pierre' }],
  },
  {
    code: 'VC',
    flag: '🇻🇨',
    name: 'Saint Vincent & Grenadines',
    cities: [
      { name: 'Kingstown' },
      { name: 'Georgetown' },
      { name: 'Byera Village' },
      { name: 'Barrouallie' },
      { name: 'Port Elizabeth' },
    ],
  },
  {
    code: 'WS',
    flag: '🇼🇸',
    name: 'Samoa',
    cities: [
      { name: 'Apia' },
      { name: 'Vaitele' },
      { name: "Fale'ula" },
      { name: "Si'ufaga" },
      { name: 'Malie' },
      { name: "Fasito'o-uta" },
      { name: 'Satapuala' },
      { name: 'Lufilufi' },
      { name: 'Safotulafai' },
      { name: 'Safotu' },
      { name: 'Leulumoega' },
      { name: 'Faleasiu' },
      { name: 'Sapulu' },
      { name: 'Mulifanua' },
      { name: 'Salelologa' },
    ],
  },
  {
    code: 'SM',
    flag: '🇸🇲',
    name: 'San Marino',
    cities: [
      { name: 'San Marino' },
      { name: 'Serravalle' },
      { name: 'Borgo Maggiore' },
      { name: 'Domagnano' },
      { name: 'Fiorentino' },
      { name: 'Acquaviva' },
      { name: 'Chiesanuova' },
      { name: 'Faetano' },
      { name: 'Montegiardino' },
      { name: 'Fiorentino' },
    ],
  },
  {
    code: 'ST',
    flag: '🇸🇹',
    name: 'Sao Tome and Principe',
    cities: [
      { name: 'São Tomé' },
      { name: 'Neves' },
      { name: 'Guadalupe' },
      { name: 'Santo Amaro' },
      { name: 'Santana' },
      { name: 'Angolares' },
      { name: 'Trindade' },
      { name: 'São João dos Angolares' },
      { name: 'Porto Alegre' },
      { name: 'São João Baptista' },
      { name: 'Santa Catarina' },
    ],
  },
  {
    code: 'SA',
    flag: '🇸🇦',
    name: 'Saudi Arabia',
    cities: [
      { name: 'Riyadh' },
      { name: 'Jeddah' },
      { name: 'Mecca' },
      { name: 'Medina' },
      { name: 'Dammam' },
      { name: "Ta'if" },
      { name: 'Tabuk' },
      { name: 'Buraidah' },
      { name: 'Khamis Mushait' },
      { name: 'Al Hufuf' },
      { name: 'Hail' },
      { name: 'Najran' },
      { name: 'Yanbu' },
      { name: 'Al Qatif' },
      { name: 'Abha' },
    ],
  },
  {
    code: 'SN',
    flag: '🇸🇳',
    name: 'Senegal',
    cities: [
      { name: 'Dakar' },
      { name: 'Thiès' },
      { name: 'Saint-Louis' },
      { name: 'Kaolack' },
      { name: 'Ziguinchor' },
      { name: 'Tambacounda' },
      { name: 'Diourbel' },
      { name: 'Louga' },
      { name: 'Richard Toll' },
      { name: 'Kolda' },
      { name: "M'bour" },
      { name: 'Fatick' },
    ],
  },
  {
    code: 'RS',
    flag: '🇷🇸',
    name: 'Serbia',
    cities: [
      { name: 'Belgrade' },
      { name: 'Novi Sad' },
      { name: 'Niš' },
      { name: 'Kragujevac' },
      { name: 'Subotica' },
      { name: 'Zrenjanin' },
      { name: 'Pančevo' },
      { name: 'Čačak' },
      { name: 'Kraljevo' },
      { name: 'Smederevo' },
    ],
  },
  {
    code: 'SC',
    flag: '🇸🇨',
    name: 'Seychelles',
    cities: [
      { name: 'Victoria' },
      { name: 'Anse Royale' },
      { name: 'Beau Vallon' },
      { name: 'Baie Lazare' },
      { name: 'Grand Anse Mahe' },
      { name: 'Anse Boileau' },
      { name: 'Takamaka' },
      { name: 'Port Glaud' },
      { name: 'Anse aux Pins' },
      { name: 'Pointe Larue' },
    ],
  },
  {
    code: 'SL',
    flag: '🇸🇱',
    name: 'Sierra Leone',
    cities: [
      { name: 'Freetown' },
      { name: 'Bo' },
      { name: 'Kenema' },
      { name: 'Koidu' },
      { name: 'Makeni' },
      { name: 'Lunsar' },
      { name: 'Port Loko' },
      { name: 'Kabala' },
      { name: 'Pujehun' },
      { name: 'Bonthe' },
    ],
  },
  {
    code: 'SG',
    flag: '🇸🇬',
    name: 'Singapore',
    cities: [
      { name: 'Singapore' },
      { name: 'Jurong West' },
      { name: 'Woodlands' },
      { name: 'Tampines' },
      { name: 'Bedok' },
      { name: 'Sengkang' },
      { name: 'Hougang' },
      { name: 'Pasir Ris' },
      { name: 'Yishun' },
      { name: 'Choa Chu Kang' },
    ],
  },
  {
    code: 'SX',
    flag: '🇸🇽',
    name: 'Sint Maarten (Dutch part)',
    cities: [
      { name: 'Philipsburg' },
      { name: 'Simpson Bay' },
      { name: 'Cole Bay' },
      { name: 'Marigot' },
      { name: 'Lowlands' },
      { name: 'Maho' },
      { name: 'Oyster Pond' },
      { name: 'Cul de Sac' },
      { name: 'Grand Case' },
      { name: "Upper Prince's Quarter" },
    ],
  },
  {
    code: 'SK',
    flag: '🇸🇰',
    name: 'Slovakia',
    cities: [
      { name: 'Bratislava' },
      { name: 'Košice' },
      { name: 'Prešov' },
      { name: 'Nitra' },
      { name: 'Žilina' },
      { name: 'Banská Bystrica' },
      { name: 'Trnava' },
      { name: 'Martin' },
      { name: 'Trenčín' },
      { name: 'Poprad' },
    ],
  },
  {
    code: 'SI',
    flag: '🇸🇮',
    name: 'Slovenia',
    cities: [
      { name: 'Ljubljana' },
      { name: 'Maribor' },
      { name: 'Celje' },
      { name: 'Kranj' },
      { name: 'Velenje' },
      { name: 'Koper' },
      { name: 'Novo Mesto' },
      { name: 'Ptuj' },
      { name: 'Trbovlje' },
      { name: 'Domžale' },
    ],
  },
  {
    code: 'SB',
    flag: '🇸🇧',
    name: 'Solomon Islands',
    cities: [
      { name: 'Honiara' },
      { name: 'Gizo' },
      { name: 'Auki' },
      { name: 'Kirakira' },
      { name: 'Tulagi' },
      { name: 'Lata' },
      { name: 'Buala' },
      { name: 'Taro' },
      { name: 'Yandina' },
      { name: 'Arawa' },
    ],
  },
  {
    code: 'SO',
    flag: '🇸🇴',
    name: 'Somalia',
    cities: [
      { name: 'Mogadishu' },
      { name: 'Hargeisa' },
      { name: 'Bosaso' },
      { name: 'Garoowe' },
      { name: 'Kismaayo' },
      { name: 'Beledweyne' },
      { name: 'Jamaame' },
      { name: 'Baidoa' },
      { name: 'Berbera' },
      { name: 'Jowhar' },
    ],
  },
  {
    code: 'ZA',
    flag: '🇿🇦',
    name: 'South Africa',
    cities: [
      { name: 'Johannesburg' },
      { name: 'Cape Town' },
      { name: 'Durban' },
      { name: 'Pretoria' },
      { name: 'Port Elizabeth' },
      { name: 'Bloemfontein' },
      { name: 'Nelspruit' },
      { name: 'Polokwane' },
      { name: 'East London' },
      { name: 'Kimberley' },
    ],
  },
  {
    code: 'GB',
    flag: '🇬🇧',
    name: 'Great Britain',
    cities: [
      { name: 'London' },
      { name: 'Birmingham' },
      { name: 'Manchester' },
      { name: 'Liverpool' },
      { name: 'Bristol' },
      { name: 'Glasgow' },
      { name: 'Leeds' },
      { name: 'Sheffield' },
      { name: 'Edinburgh' },
      { name: 'Leicester' },
      { name: 'Coventry' },
      { name: 'Bradford' },
      { name: 'Cardiff' },
      { name: 'Belfast' },
      { name: 'Nottingham' },
      { name: 'Kingston upon Hull' },
      { name: 'Newcastle upon Tyne' },
      { name: 'Stoke-on-Trent' },
      { name: 'Southampton' },
      { name: 'Derby' },
      { name: 'Portsmouth' },
      { name: 'Brighton' },
      { name: 'Plymouth' },
      { name: 'Northampton' },
      { name: 'Reading' },
      { name: 'Luton' },
      { name: 'Wolverhampton' },
      { name: 'Aberdeen' },
      { name: 'Bolton' },
      { name: 'Bournemouth' },
      { name: 'Norwich' },
      { name: 'Swindon' },
      { name: 'Swansea' },
      { name: 'Southend-on-Sea' },
      { name: 'Middlesbrough' },
      { name: 'Peterborough' },
      { name: 'Blackpool' },
      { name: 'Cambridge' },
      { name: 'Doncaster' },
      { name: 'Hastings' },
      { name: 'Ipswich' },
      { name: 'York' },
      { name: 'Gloucester' },
      { name: 'Watford' },
      { name: 'Slough' },
      { name: 'Exeter' },
      { name: 'Dundee' },
      { name: 'Oxford' },
      { name: 'Basingstoke' },
      { name: 'Warrington' },
      { name: 'Burnley' },
      { name: 'Barrow-in-Furness' },
      { name: 'Dover' },
      { name: 'Folkestone' },
      { name: 'Eastbourne' },
      { name: 'Torquay' },
      { name: 'Worthing' },
      { name: 'Cheltenham' },
      { name: 'Woking' },
      { name: 'Taunton' },
      { name: 'St Albans' },
      { name: 'Basildon' },
      { name: 'Harlow' },
      { name: 'Stevenage' },
      { name: 'Crawley' },
      { name: 'Maidenhead' },
      { name: 'Farnborough' },
      { name: 'Aldershot' },
      { name: 'Bognor Regis' },
      { name: 'Chippenham' },
      { name: 'Trowbridge' },
      { name: 'Yeovil' },
      { name: 'Newbury' },
      { name: 'Redditch' },
      { name: 'Banbury' },
      { name: 'Loughborough' },
      { name: 'Chesterfield' },
      { name: 'Hereford' },
      { name: 'Kidderminster' },
      { name: 'Colchester' },
      { name: 'Braintree' },
      { name: 'Brentwood' },
      { name: 'Grays' },
      { name: 'Canvey Island' },
      { name: 'South Woodham Ferrers' },
      { name: 'Billericay' },
      { name: 'Chelmsford' },
      { name: 'Staines-upon-Thames' },
      { name: 'Epsom' },
      { name: 'Leatherhead' },
      { name: 'Reigate' },
      { name: 'Farnham' },
      { name: 'Guildford' },
      { name: 'Godalming' },
      { name: 'Horsham' },
      { name: 'Haywards Heath' },
      { name: 'Burgess Hill' },
      { name: 'Crowborough' },
      { name: 'East Grinstead' },
      { name: 'Sevenoaks' },
      { name: 'Tonbridge' },
      { name: 'Dartford' },
      { name: 'Gravesend' },
      { name: 'Gillingham' },
      { name: 'Rochester' },
      { name: 'Maidstone' },
      { name: 'Ashford' },
      { name: 'Dover' },
      { name: 'Margate' },
      { name: 'Ramsgate' },
      { name: 'Folkestone' },
      { name: 'Hythe' },
      { name: 'Herne Bay' },
      { name: 'Whitstable' },
      { name: 'Canterbury' },
      { name: 'Sittingbourne' },
      { name: 'Faversham' },
      { name: 'Sheerness' },
      { name: 'Isle of Sheppey' },
      { name: 'Bexleyheath' },
      { name: 'Dartford' },
      { name: 'Gravesend' },
      { name: 'Swanley' },
      { name: 'Sidcup' },
      { name: 'Erith' },
      { name: 'Crayford' },
      { name: 'Belvedere' },
      { name: 'Abbey Wood' },
      { name: 'Thamesmead' },
      { name: 'Beckenham' },
      { name: 'Bromley' },
      { name: 'West Wickham' },
      { name: 'Orpington' },
      { name: 'Chislehurst' },
      { name: 'Biggin Hill' },
      { name: 'Croydon' },
      { name: 'South Croydon' },
      { name: 'Purley' },
      { name: 'Mitcham' },
      { name: 'Morden' },
      { name: 'Sutton' },
      { name: 'Cheam' },
      { name: 'Carshalton' },
      { name: 'Wallington' },
      { name: 'Epsom' },
      { name: 'Banstead' },
      { name: 'Tadworth' },
      { name: 'Coulsdon' },
      { name: 'Warlingham' },
      { name: 'Caterham' },
      { name: 'Oxted' },
      { name: 'Godstone' },
      { name: 'Redhill' },
      { name: 'Reigate' },
      { name: 'Dorking' },
      { name: 'Leatherhead' },
      { name: 'Cobham' },
      { name: 'Weybridge' },
      { name: 'Walton-on-Thames' },
      { name: 'Esher' },
      { name: 'Hersham' },
      { name: 'Molesey' },
      { name: 'Sunbury-on-Thames' },
      { name: 'Ashford' },
      { name: 'Staines-upon-Thames' },
      { name: 'Shepperton' },
      { name: 'Chertsey' },
      { name: 'Addlestone' },
      { name: 'Woking' },
      { name: 'Guildford' },
      { name: 'Farnham' },
    ],
  },
  {
    code: 'KR',
    flag: '🇰🇷',
    name: 'Korea, Republic of',
    cities: [
      { name: 'Seoul' },
      { name: 'Busan' },
      { name: 'Incheon' },
      { name: 'Daegu' },
      { name: 'Daejeon' },
      { name: 'Gwangju' },
      { name: 'Ulsan' },
      { name: 'Suwon' },
      { name: 'Changwon' },
      { name: 'Seongnam' },
      { name: 'Goyang' },
      { name: 'Yongin' },
      { name: 'Cheongju' },
      { name: 'Jeonju' },
      { name: 'Cheonan' },
    ],
  },
  {
    code: 'SS',
    flag: '🇸🇸',
    name: 'South Sudan',
    cities: [
      { name: 'Juba' },
      { name: 'Wau' },
      { name: 'Malakal' },
      { name: 'Bor' },
      { name: 'Aweil' },
      { name: 'Yei' },
      { name: 'Torit' },
      { name: 'Rumbek' },
      { name: 'Nimule' },
      { name: 'Kuacjok' },
    ],
  },
  {
    code: 'ES',
    flag: '🇪🇸',
    name: 'Spain',
    cities: [
      { name: 'Madrid' },
      { name: 'Barcelona' },
      { name: 'Valencia' },
      { name: 'Seville' },
      { name: 'Zaragoza' },
      { name: 'Málaga' },
      { name: 'Murcia' },
      { name: 'Palma de Mallorca' },
      { name: 'Las Palmas de Gran Canaria' },
      { name: 'Bilbao' },
      { name: 'Alicante' },
      { name: 'Córdoba' },
      { name: 'Valladolid' },
      { name: 'Vigo' },
      { name: 'Gijón' },
      { name: "L'Hospitalet de Llobregat" },
      { name: 'La Coruña' },
      { name: 'Granada' },
      { name: 'Vitoria-Gasteiz' },
      { name: 'Elche' },
      { name: 'Santa Cruz de Tenerife' },
      { name: 'Oviedo' },
      { name: 'Badalona' },
      { name: 'Cartagena' },
      { name: 'Terrassa' },
      { name: 'Jerez de la Frontera' },
      { name: 'Sabadell' },
      { name: 'Móstoles' },
      { name: 'Alcalá de Henares' },
    ],
  },
  {
    code: 'LK',
    flag: '🇱🇰',
    name: 'Sri Lanka',
    cities: [
      { name: 'Colombo' },
      { name: 'Galle' },
      { name: 'Jaffna' },
      { name: 'Kandy' },
      { name: 'Negombo' },
      { name: 'Trincomalee' },
      { name: 'Anuradhapura' },
      { name: 'Batticaloa' },
      { name: 'Matara' },
      { name: 'Ratnapura' },
    ],
  },
  {
    code: 'SD',
    flag: '🇸🇩',
    name: 'Sudan',
    cities: [
      { name: 'Khartoum' },
      { name: 'Omdurman' },
      { name: 'Nyala' },
      { name: 'Port Sudan' },
      { name: 'Kassala' },
      { name: 'El Obeid' },
      { name: 'Wad Madani' },
      { name: 'El Fasher' },
      { name: 'Al-Ubayyid' },
      { name: 'Kosti' },
    ],
  },
  {
    code: 'SR',
    flag: '🇸🇷',
    name: 'Suriname',
    cities: [
      { name: 'Paramaribo' },
      { name: 'Lelydorp' },
      { name: 'Nieuw Nickerie' },
      { name: 'Moengo' },
      { name: 'Mariënburg' },
      { name: 'Wageningen' },
      { name: 'Albina' },
      { name: 'Groningen' },
      { name: 'Brownsweg' },
      { name: 'Totness' },
    ],
  },
  {
    code: 'SJ',
    flag: '🇸🇯',
    name: 'Svalbard and Jan Mayen[c]',
    cities: [
      { name: 'Longyearbyen' },
      { name: 'Barentsburg' },
      { name: 'Ny-Ålesund' },
      { name: 'Sveagruva' },
      { name: 'Hopen' },
      { name: 'Bear Island' },
    ],
  },
  {
    code: 'SZ',
    flag: '🇸🇿',
    name: 'Eswatini',
    cities: [
      { name: 'Mbabane' },
      { name: 'Manzini' },
      { name: 'Big Bend' },
      { name: 'Malkerns' },
      { name: 'Nhlangano' },
      { name: 'Siteki' },
      { name: 'Piggs Peak' },
      { name: 'Hlatikulu' },
      { name: 'Mhlume' },
      { name: 'Lobamba' },
    ],
  },
  {
    code: 'SE',
    flag: '🇸🇪',
    name: 'Sweden',
    cities: [
      { name: 'Stockholm' },
      { name: 'Gothenburg' },
      { name: 'Malmö' },
      { name: 'Uppsala' },
      { name: 'Västerås' },
      { name: 'Örebro' },
      { name: 'Linköping' },
      { name: 'Helsingborg' },
      { name: 'Jönköping' },
      { name: 'Norrköping' },
      { name: 'Lund' },
      { name: 'Umeå' },
      { name: 'Gävle' },
      { name: 'Södertälje' },
      { name: 'Växjö' },
    ],
  },
  {
    code: 'CH',
    flag: '🇨🇭',
    name: 'Switzerland',
    cities: [
      { name: 'Zurich' },
      { name: 'Geneva' },
      { name: 'Basel' },
      { name: 'Lausanne' },
      { name: 'Bern' },
      { name: 'Lucerne' },
      { name: 'Winterthur' },
      { name: 'St. Gallen' },
      { name: 'Lugano' },
      { name: 'Biel/Bienne' },
      { name: 'Thun' },
      { name: 'Köniz' },
      { name: 'La Chaux-de-Fonds' },
      { name: 'Schaffhausen' },
      { name: 'Fribourg' },
    ],
  },
  {
    code: 'SY',
    flag: '🇸🇾',
    name: 'Syrian Arab Republic',
    cities: [
      { name: 'Aleppo' },
      { name: 'Damascus' },
      { name: 'Homs' },
      { name: 'Latakia' },
      { name: 'Hama' },
      { name: 'Deir ez-Zor' },
      { name: 'Raqqa' },
      { name: 'Idlib' },
      { name: 'As-Suwayda' },
      { name: 'Tartus' },
    ],
  },
  {
    code: 'TW',
    flag: '🇹🇼',
    name: 'Taiwan, Province of China',
    cities: [
      { name: 'Taipei' },
      { name: 'Kaohsiung' },
      { name: 'Taichung' },
      { name: 'Tainan' },
      { name: 'Banqiao' },
      { name: 'Hsinchu' },
      { name: 'Keelung' },
      { name: 'Chiayi' },
      { name: 'Changhua' },
      { name: 'Pingtung' },
      { name: 'Yilan' },
      { name: 'Taoyuan' },
      { name: 'Miaoli' },
      { name: 'Nantou' },
      { name: 'Hualien City' },
      { name: 'Taitung City' },
      { name: 'Magong' },
    ],
  },
  {
    code: 'TJ',
    flag: '🇹🇯',
    name: 'Tajikistan',
    cities: [
      { name: 'Dushanbe' },
      { name: 'Khujand' },
      { name: 'Kulob' },
      { name: 'Qurghonteppa' },
      { name: 'Istaravshan' },
      { name: 'Vahdat' },
      { name: 'Konibodom' },
      { name: 'Norak' },
      { name: 'Tursunzoda' },
      { name: 'Isfara' },
    ],
  },
  {
    code: 'TZ',
    flag: '🇹🇿',
    name: 'Tanzania, United Republic of',
    cities: [
      { name: 'Dar es Salaam' },
      { name: 'Mwanza' },
      { name: 'Arusha' },
      { name: 'Dodoma' },
      { name: 'Mbeya' },
      { name: 'Morogoro' },
      { name: 'Tanga' },
      { name: 'Kahama' },
      { name: 'Tabora' },
      { name: 'Zanzibar City' },
      { name: 'Songea' },
      { name: 'Musoma' },
      { name: 'Mtwara' },
      { name: 'Shinyanga' },
      { name: 'Bagamoyo' },
      { name: 'Moshi' },
    ],
  },
  {
    code: 'TH',
    flag: '🇹🇭',
    name: 'Thailand',
    cities: [
      { name: 'Bangkok' },
      { name: 'Nonthaburi' },
      { name: 'Nakhon Ratchasima' },
      { name: 'Chiang Mai' },
      { name: 'Hat Yai' },
      { name: 'Udon Thani' },
      { name: 'Pak Kret' },
      { name: 'Khon Kaen' },
      { name: 'Ubon Ratchathani' },
      { name: 'Surat Thani' },
      { name: 'Chon Buri' },
      { name: 'Nakhon Si Thammarat' },
      { name: 'Rayong' },
      { name: 'Phra Nakhon Si Ayutthaya' },
      { name: 'Chanthaburi' },
    ],
  },
  {
    code: 'TL',
    flag: '🇹🇱',
    name: 'Timor-Leste',
    cities: [
      { name: 'Dili' },
      { name: 'Aileu' },
      { name: 'Ainaro' },
      { name: 'Baucau' },
      { name: 'Bobonaro' },
      { name: 'Cova Lima' },
      { name: 'Ermera' },
      { name: 'Lautem' },
      { name: 'Liquiçá' },
      { name: 'Manatuto' },
      { name: 'Manufahi' },
      { name: 'Oecusse' },
      { name: 'Viqueque' },
    ],
  },
  {
    code: 'TG',
    flag: '🇹🇬',
    name: 'Togo',
    cities: [
      { name: 'Lomé' },
      { name: 'Sokodé' },
      { name: 'Kara' },
      { name: 'Kpalimé' },
      { name: 'Atakpamé' },
      { name: 'Bassar' },
      { name: 'Tsévié' },
      { name: 'Aného' },
      { name: 'Mango' },
      { name: 'Dapaong' },
      { name: 'Notse' },
    ],
  },
  {
    code: 'TK',
    flag: '🇹🇰',
    name: 'Tokelau',
    cities: [{ name: 'Nukunonu' }, { name: 'Fakaofo' }, { name: 'Atafu' }],
  },
  {
    code: 'TO',
    flag: '🇹🇴',
    name: 'Tonga',
    cities: [
      { name: "Nuku'alofa" },
      { name: 'Neiafu' },
      { name: 'Haveluloto' },
      { name: 'Vaini' },
      { name: 'Pangai' },
      { name: 'Ohonua' },
      { name: "Ha'apai" },
    ],
  },
  {
    code: 'TT',
    flag: '🇹🇹',
    name: 'Trinidad and Tobago',
    cities: [
      { name: 'Port of Spain' },
      { name: 'Chaguanas' },
      { name: 'San Fernando' },
      { name: 'Arima' },
      { name: 'Marabella' },
      { name: 'Point Fortin' },
      { name: 'Tunapuna' },
      { name: 'Scarborough' },
      { name: 'Sangre Grande' },
      { name: 'Princes Town' },
    ],
  },
  {
    code: 'TN',
    flag: '🇹🇳',
    name: 'Tunisia',
    cities: [
      { name: 'Tunis' },
      { name: 'Sfax' },
      { name: 'Sousse' },
      { name: 'Gabès' },
      { name: 'Bizerte' },
      { name: 'Kairouan' },
      { name: 'Gafsa' },
      { name: 'Ariana' },
      { name: 'La Soukra' },
      { name: 'Ben Arous' },
      { name: 'Zarzis' },
    ],
  },
  {
    code: 'TR',
    flag: '🇹🇷',
    name: 'Turkey',
    cities: [
      { name: 'Istanbul' },
      { name: 'Ankara' },
      { name: 'Izmir' },
      { name: 'Bursa' },
      { name: 'Adana' },
      { name: 'Gaziantep' },
      { name: 'Konya' },
      { name: 'Antalya' },
      { name: 'Diyarbakir' },
      { name: 'Mersin' },
    ],
  },
  {
    code: 'TM',
    flag: '🇹🇲',
    name: 'Turkmenistan',
    cities: [
      { name: 'Ashgabat' },
      { name: 'Turkmenabat' },
      { name: 'Daşoguz' },
      { name: 'Mary' },
      { name: 'Balkanabat' },
      { name: 'Bayramaly' },
      { name: 'Türkmenbaşy' },
      { name: 'Tejen' },
      { name: 'Abadan' },
      { name: 'Atamyrat' },
    ],
  },
  {
    code: 'TC',
    flag: '🇹🇨',
    name: 'Turks and Caicos Islands',
    cities: [
      { name: 'Providenciales' },
      { name: 'Grand Turk' },
      { name: 'South Caicos' },
      { name: 'North Caicos' },
      { name: 'Middle Caicos' },
      { name: 'Salt Cay' },
      { name: 'Pine Cay' },
      { name: 'Parrot Cay' },
      { name: 'East Caicos' },
    ],
  },
  {
    code: 'TV',
    flag: '🇹🇻',
    name: 'Tuvalu',
    cities: [
      { name: 'Funafuti' },
      { name: 'Nanumea' },
      { name: 'Nui' },
      { name: 'Nukufetau' },
      { name: 'Nukulaelae' },
      { name: 'Niulakita' },
      { name: 'Vaitupu' },
      { name: 'Nanumanga' },
      { name: 'Niutao' },
    ],
  },
  {
    code: 'UG',
    flag: '🇺🇬',
    name: 'Uganda',
    cities: [
      { name: 'Kampala' },
      { name: 'Gulu' },
      { name: 'Lira' },
      { name: 'Mbarara' },
      { name: 'Jinja' },
      { name: 'Mbale' },
      { name: 'Masaka' },
      { name: 'Arua' },
      { name: 'Entebbe' },
      { name: 'Kasese' },
    ],
  },
  {
    code: 'UA',
    flag: '🇺🇦',
    name: 'Ukraine',
    cities: [
      { name: 'Kiev' },
      { name: 'Kharkiv' },
      { name: 'Odessa' },
      { name: 'Dnipro' },
      { name: 'Donetsk' },
      { name: 'Zaporizhzhia' },
      { name: 'Lviv' },
      { name: 'Kryvyi Rih' },
      { name: 'Mykolaiv' },
      { name: 'Mariupol' },
    ],
  },
  {
    code: 'AE',
    flag: '🇦🇪',
    name: 'United Arab Emirates',
    cities: [
      { name: 'Abu Dhabi' },
      { name: 'Dubai' },
      { name: 'Sharjah' },
      { name: 'Al Ain' },
      { name: 'Ajman' },
      { name: 'Ras al-Khaimah' },
      { name: 'Fujairah' },
      { name: 'Umm al-Quwain' },
    ],
  },
  {
    code: 'US',
    flag: '🇺🇸',
    name: 'United States of America',
    cities: [
      { name: 'New York City' },
      { name: 'Los Angeles' },
      { name: 'Chicago' },
      { name: 'Houston' },
      { name: 'Phoenix' },
      { name: 'Philadelphia' },
      { name: 'San Antonio' },
      { name: 'San Diego' },
      { name: 'Dallas' },
      { name: 'San Jose' },
      { name: 'Austin' },
      { name: 'Jacksonville' },
      { name: 'Fort Worth' },
      { name: 'Columbus' },
      { name: 'San Francisco' },
      { name: 'Charlotte' },
      { name: 'Indianapolis' },
      { name: 'Seattle' },
      { name: 'Denver' },
      { name: 'Washington, D.C.' },
      { name: 'Boston' },
      { name: 'Nashville' },
      { name: 'El Paso' },
      { name: 'Detroit' },
      { name: 'Memphis' },
      { name: 'Portland' },
      { name: 'Oklahoma City' },
      { name: 'Las Vegas' },
      { name: 'Louisville' },
      { name: 'Baltimore' },
      { name: 'Milwaukee' },
      { name: 'Albuquerque' },
      { name: 'Tucson' },
      { name: 'Mesa' },
      { name: 'Fresno' },
      { name: 'Sacramento' },
      { name: 'Atlanta' },
      { name: 'Kansas City' },
      { name: 'Colorado Springs' },
      { name: 'Miami' },
      { name: 'Raleigh' },
      { name: 'Omaha' },
      { name: 'Long Beach' },
      { name: 'Virginia Beach' },
      { name: 'Oakland' },
      { name: 'Minneapolis' },
      { name: 'Tulsa' },
      { name: 'Wichita' },
      { name: 'New Orleans' },
      { name: 'Arlington' },
      { name: 'Tampa' },
      { name: 'Santa Ana' },
      { name: 'St. Louis' },
      { name: 'Pittsburgh' },
      { name: 'Corpus Christi' },
      { name: 'Riverside' },
      { name: 'Cincinnati' },
      { name: 'Lexington' },
      { name: 'Anchorage' },
      { name: 'Stockton' },
      { name: 'Toledo' },
      { name: 'Saint Paul' },
      { name: 'Newark' },
      { name: 'Greensboro' },
      { name: 'Buffalo' },
      { name: 'Plano' },
      { name: 'Lincoln' },
      { name: 'Henderson' },
      { name: 'Fort Wayne' },
    ],
  },
  {
    code: 'UM',
    flag: '🇺🇲',
    name: 'US Minor Outlying Islands',
    cities: [
      { name: 'Baker Island' },
      { name: 'Howland Island' },
      { name: 'Jarvis Island' },
      { name: 'Johnston Atoll' },
      { name: 'Kingman Reef' },
      { name: 'Midway Atoll' },
      { name: 'Navassa Island' },
      { name: 'Palmyra Atoll' },
      { name: 'Wake Island' },
    ],
  },
  {
    code: 'VI',
    flag: '🇻🇮',
    name: 'Virgin Islands (U.S.)',
    cities: [{ name: 'Saint Croix' }, { name: 'Saint John' }, { name: 'Saint Thomas' }],
  },
  {
    code: 'UY',
    flag: '🇺🇾',
    name: 'Uruguay',
    cities: [
      { name: 'Montevideo' },
      { name: 'Salto' },
      { name: 'Ciudad de la Costa' },
      { name: 'Paysandú' },
      { name: 'Las Piedras' },
      { name: 'Rivera' },
      { name: 'Maldonado' },
      { name: 'Tacuarembó' },
      { name: 'Melo' },
      { name: 'Mercedes' },
    ],
  },
  {
    code: 'UZ',
    flag: '🇺🇿',
    name: 'Uzbekistan',
    cities: [
      { name: 'Tashkent' },
      { name: 'Namangan' },
      { name: 'Samarkand' },
      { name: 'Andijan' },
      { name: 'Bukhara' },
      { name: 'Nukus' },
      { name: 'Qarshi' },
      { name: 'Jizzakh' },
      { name: 'Urgench' },
      { name: 'Fergana' },
    ],
  },
  {
    code: 'VU',
    flag: '🇻🇺',
    name: 'Vanuatu',
    cities: [
      { name: 'Port Vila' },
      { name: 'Luganville' },
      { name: 'Norsup' },
      { name: 'Isangel' },
      { name: 'Sola' },
      { name: 'Lakatoro' },
      { name: 'Leava' },
      { name: 'Lenakel' },
      { name: 'Saratamata' },
      { name: 'Longana' },
    ],
  },
  {
    code: 'VA',
    flag: '🇻🇦',
    name: 'Holy See',
    cities: [{ name: 'Vatican City' }],
  },
  {
    code: 'VE',
    flag: '🇻🇪',
    name: 'Venezuela',
    cities: [
      { name: 'Caracas' },
      { name: 'Maracaibo' },
      { name: 'Valencia' },
      { name: 'Barquisimeto' },
      { name: 'Ciudad Guayana' },
      { name: 'Barcelona' },
      { name: 'Maturín' },
      { name: 'Puerto La Cruz' },
      { name: 'San Cristóbal' },
      { name: 'Cumaná' },
      { name: 'Cabimas' },
      { name: 'Coro' },
      { name: 'Guarenas' },
      { name: 'Punto Fijo' },
      { name: 'Los Teques' },
      { name: 'Acarigua' },
      { name: 'Charallave' },
      { name: 'Barinitas' },
      { name: 'Guacara' },
      { name: 'La Victoria' },
      { name: 'Guatire' },
      { name: 'Carora' },
      { name: 'Valera' },
      { name: 'Santa Teresa del Tuy' },
      { name: 'Tinaquillo' },
      { name: 'Porlamar' },
      { name: 'Valle de la Pascua' },
      { name: 'Anaco' },
      { name: 'El Tigre' },
      { name: 'Upata' },
      { name: 'San Fernando de Apure' },
    ],
  },
  {
    code: 'VN',
    flag: '🇻🇳',
    name: 'Viet Nam',
    cities: [
      { name: 'Ho Chi Minh City' },
      { name: 'Hanoi' },
      { name: 'Da Nang' },
      { name: 'Haiphong' },
      { name: 'Bien Hoa' },
      { name: 'Nha Trang' },
      { name: 'Can Tho' },
      { name: 'Rach Gia' },
      { name: 'Quy Nhon' },
      { name: 'Vung Tau' },
      { name: 'Nam Dinh' },
      { name: 'Thanh Hóa' },
      { name: 'Hue' },
      { name: 'Buôn Ma Thuột' },
      { name: 'Thai Nguyen' },
      { name: 'Long Xuyen' },
      { name: 'Sóc Trăng' },
      { name: 'Cam Ranh' },
      { name: 'Sơn Tây' },
      { name: 'Phan Rang-Tháp Chàm' },
      { name: 'Lào Cai' },
      { name: 'Ha Tinh' },
      { name: 'Bac Ninh' },
      { name: 'Yen Vinh' },
      { name: 'Hòa Bình' },
      { name: 'Cao Lãnh' },
      { name: 'Pleiku' },
      { name: 'Thu Dau Mot' },
      { name: 'Tuy Hoa' },
      { name: 'Hà Tĩnh' },
      { name: 'Phan Thiết' },
      { name: 'Sơn La' },
      { name: 'Hà Đông' },
      { name: 'Việt Trì' },
      { name: 'Đồng Hới' },
      { name: 'Ninh Bình' },
      { name: 'Thái Bình' },
    ],
  },
  {
    code: 'WF',
    flag: '🇼🇫',
    name: 'Wallis and Futuna',
    cities: [{ name: 'Mata Utu' }, { name: 'Alofi' }, { name: 'Kolia' }, { name: 'Mua' }, { name: 'Liku' }],
  },
  {
    code: 'EH',
    flag: '🇪🇭',
    name: 'Western Sahara',
    cities: [
      { name: 'El Aaiún' },
      { name: 'Dakhla' },
      { name: 'Smara' },
      { name: 'Laayoune Plage' },
      { name: 'Boujdour' },
    ],
  },
  {
    code: 'YE',
    flag: '🇾🇪',
    name: 'Yemen',
    cities: [
      { name: "Sana'a" },
      { name: 'Taiz' },
      { name: 'Al Hudaydah' },
      { name: 'Aden' },
      { name: 'Ibb' },
      { name: 'Dhamar' },
      { name: 'Al-Mukalla' },
      { name: 'Zinjibar' },
      { name: 'Sayyan' },
      { name: 'Sadah' },
    ],
  },
  {
    code: 'ZM',
    flag: '🇿🇲',
    name: 'Zambia',
    cities: [
      { name: 'Lusaka' },
      { name: 'Kitwe' },
      { name: 'Ndola' },
      { name: 'Kabwe' },
      { name: 'Chingola' },
      { name: 'Mufulira' },
      { name: 'Livingstone' },
      { name: 'Luanshya' },
      { name: 'Kasama' },
      { name: 'Chipata' },
    ],
  },
  {
    code: 'ZW',
    flag: '🇿🇼',
    name: 'Zimbabwe',
    cities: [
      { name: 'Harare' },
      { name: 'Bulawayo' },
      { name: 'Chitungwiza' },
      { name: 'Mutare' },
      { name: 'Gweru' },
      { name: 'Epworth' },
      { name: 'Kwekwe' },
      { name: 'Kadoma' },
      { name: 'Masvingo' },
      { name: 'Chinhoyi' },
    ],
  },
  {
    code: 'GB',
    flag: '🇬🇧',
    name: 'United Kingdom',
    cities: [
      { name: 'London' },
      { name: 'Birmingham' },
      { name: 'Manchester' },
      { name: 'Glasgow' },
      { name: 'Liverpool' },
      { name: 'Edinburgh' },
      { name: 'Leeds' },
      { name: 'Newcastle' },
      { name: 'Bristol' },
      { name: 'Sheffield' },
    ],
  },
];

export const categoryOptions = [
  'audition',
  'workshop',
  'openCall',
  'scholarship',
  'intensive',
  'residency',
  'festival',
  'program',
  'training',
  'studies',
  'internship',
  'show',
  'competition',
  'job',
  'online',
  'other',
];
