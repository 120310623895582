import tw, { styled, css } from 'twin.macro';

export const AppUpdateFormWrapperTwinMacro = styled.div(() => [
  tw`py-20 w-full`,
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    color: black;
    padding-bottom: 6rem;

    .cropped-img {
      text-align: center;
    }

    form {
      font-family: 'Montserrat', sans-serif;
      padding: 2rem;
      -webkit-backdrop-filter: blur(30px);
      backdrop-filter: blur(30px);
      -webkit-box-shadow: 4px 1px 1px 3px #b7c7e1;
      -moz-box-shadow: 1px 2px 1px 1px #b7c7e1;
      box-shadow: 0px 0px 100px 0.5px #b7c7e2;

      input:not([type='file']):not([type='checkbox']),
      .mantine-DatePickerInput-input {
        font-family: 'Montserrat', sans-serif;
        margin: 0.5rem 0;
        border: none;
        &:focus {
          outline: none;
        }
        background: rgba(255, 255, 255, 0.29);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.45);
        width: -webkit-fill-available;
      }

      /* .ReactCrop {
          max-width: fit-content !important;
        } */
      /* 
        input[type='file'] {
          background-image: url('https://res.cloudinary.com/umuv/image/upload/v1622919587/UMUV/upload_photo.png');
          color: transparent;
          overflow: hidden;
          cursor: pointer;
          background-color: white;
          background-position: center;
          background-size: cover;
          width: 140px;
          height: 140px;
          border-radius: 100px;
          padding-left: 130px;
          margin-top: -50px;
        } */

      /* textarea#description,
        textarea#more_info,
        textarea {
          color: #222;
          width: 100%;
          padding: 2rem;
          border-radius: 12px;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          border: 1px solid rgba(255, 255, 255, 0.45);
        } */

      /* button {
          margin: 1.5rem 0;
          padding: 0;
          border: 0;
          text-transform: uppercase;
          font-family: 'Montserrat', sans-serif;
          cursor: pointer;
        } */
    }
  `,
]);
