import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';
import { Hits, Pagination, InstantSearch, SearchBox, Configure, Menu } from 'react-instantsearch';
import { Hit } from './Hit';

const searchClient = algoliasearch('PZZKRPTY1C', 'f495d1c0702ae168bce795f6ffb13c74');

export const SearchWithAlgolia = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName="muvents" className="flex">
      <Configure hitsPerPage={20} />
      <div className="mr-10 max-w-[280px] flex-col items-start mt-9 hidden md:flex">
        <p className="uppercase py-2 text-sm">Filter by event type</p>
        <Menu
          attribute="category"
          classNames={{
            root: 'py-6 border border-1 border-slate-500/10 rounded p-10 min-w-[240px]',
            list: 'flex flex-col',
            item: 'my-1 cursor-pointer text-white rounded-full',
            selectedItem: 'rounded-full',
            label: 'bg-slate-700 text-white text-center py-1 px-2 text-xs text-center rounded-full',
            count: 'bg-yellow-300',
            showMore:
              'text-purple-700 border-none border-slate-500 rounded-full focus:border-none focus:outline-none focus:ring-0 focus:ring-transparent',
          }}
          sortBy={['count:desc', 'name:asc']}
          showMore={true}
          about="category"
        />

        <div className="py-8"></div>
        <p className="uppercase py-2 text-sm">Filter by country</p>
        <Menu
          attribute="country"
          classNames={{
            root: 'py-6 border border-1 border-slate-500/10 rounded p-10 max-w-[240px]',
            list: 'flex flex-col',
            item: 'my-1 cursor-pointer text-white rounded-full',
            selectedItem: 'rounded-full',
            label: 'bg-slate-700 text-white text-center py-1 px-2 text-xs text-center rounded-full',
            count: 'bg-sky-200 text-slate-900',
            showMore:
              'text-purple-700 border-none border-slate-500 rounded-full focus:border-none focus:outline-none focus:ring-0 focus:ring-transparent',
          }}
          sortBy={['count:desc', 'name:asc']}
          about="country"
          showMore={true}
        />
        <div className="py-8"></div>
        <p className="uppercase py-2 text-sm">Filter by creator</p>
        <Menu
          attribute="creator_name"
          classNames={{
            root: 'py-6 border border-1 border-slate-500/10 rounded p-10 max-w-[240px]',
            list: 'flex flex-col',
            item: 'my-1 cursor-pointer text-white rounded-full',
            selectedItem: 'rounded-full',
            label: 'bg-slate-700 text-white text-center py-1 px-2 text-xs text-center rounded-full',
            count: 'bg-sky-200 text-slate-900',
            showMore:
              'text-purple-700 border-none border-slate-500 rounded-full focus:border-none focus:outline-none focus:ring-0 focus:ring-transparent',
          }}
          sortBy={['count:desc']}
          showMore={true}
        />
      </div>

      <div className="ais-InstantSearch flex flex-col justify-center">
        <SearchBox
          classNames={{
            root: '',
            form: '',
            input:
              'text-white focus:text-slate-800 bg-slate-600 focus:bg-slate-100 px-10 py-1 rounded-full border-none focus:placeholder-slate-600 placeholder-white focus:outline-none focus:ring-0 focus:ring-transparent fill-slate-100',
          }}
          placeholder="Search for dance events"
        />

        <Hits
          hitComponent={Hit}
          classNames={{
            root: '',
            item: 'bg-white rounded shadow-md p-0 flex-1',
          }}
        />
        <Pagination className="block w-full justify-center text-center my-10" />
      </div>
    </InstantSearch>
  );
};
