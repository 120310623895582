import React, { useState } from 'react';
import { firestore } from '../../lib/firebase.prod';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Formik, Form } from 'formik';
import { Button, Collapse, Space, TextInput, Textarea } from '@mantine/core';
import { Check, Versions, Writing } from 'tabler-icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AppUpdateFormWrapperTwinMacro } from './styles';

const AppUpdateModal = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [update_1_title, setTitle] = useState('');
  const [update_1_description, setDescription] = useState('');
  const [update_tag_version, setUpdateTagVersion] = useState(''); // month.day.year -> october.24.2023

  const handleUMUVappUpdateSubmit = async () => {
    const appUpdate = { update_1_title, update_1_description, update_tag_version, created_at: serverTimestamp() };
    try {
      await addDoc(collection(firestore, 'email_notif_umuv_updates'), appUpdate);
      notifications.show({
        id: 'new-app-update',
        withCloseButton: true,
        autoClose: 6000,
        title: `Yay! Created. Check slack for info about the new update`,
        message: `Successfully created an entry on email_notif_umuv_updates collection of firebase`,
        color: 'teal',
        loading: false,
        icon: <Check size="1rem" />,
      });
      close();
    } catch (error) {
      notifications.show({
        id: 'new-app-update-error',
        withCloseButton: true,
        autoClose: 6000,
        title: `Oops! Something went wrong`,
        message: `Error: ${error}`,
        color: 'red',
        loading: false,
        icon: <Check size="1rem" />,
      });
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <p className="text-slate-100 bg-slate-800 text-sm py-1 px-2 mt-2 cursor-pointer rounded-full" onClick={open}>
          NEW UMUV APP UPDATE
        </p>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        centered
        fullScreen
        transitionProps={{ transition: 'fade', duration: 500 }}
      >
        <AppUpdateFormWrapperTwinMacro>
          <Formik initialValues={{}} onSubmit={handleUMUVappUpdateSubmit}>
            {({ isSubmitting }) => (
              <Form className="sm:w-full md:w-2/2 lg:w-2/3 xl:w-2/5 mx-2">
                {/*----------------------------------------------*/}
                {/*--------------  SECTION -----------------*/}
                {/*----------------------------------------------*/}
                <div className="flex flex-col gap-2">
                  <h1 className="text-purple-400">New UMUV app update</h1>
                  <Space h="xl" />

                  <TextInput
                    placeholder="Title"
                    radius="xs"
                    value={update_1_title}
                    icon={<Writing size={16} />}
                    onChange={event => setTitle(event.target.value)}
                    className="w-full"
                  />

                  <Textarea
                    icon={<Writing size={16} />}
                    placeholder={`Write a short description about update`}
                    autosize
                    minRows={5}
                    value={update_1_description}
                    onChange={evt => setDescription(evt.currentTarget.value)}
                  />
                  <Space h="xl" />
                  <TextInput
                    placeholder="tag version (full_month_name day_number year)"
                    radius="xs"
                    value={update_tag_version}
                    icon={<Versions size={16} />}
                    onChange={evt => setUpdateTagVersion(evt.currentTarget.value)}
                    className="w-full"
                  />
                </div>

                <Button
                  leftIcon={<Collapse size={14} />}
                  type="submit"
                  className="w-full mt-12 rounded-full text-2xl h-16 bg-purple-300 hover:bg-gray-100 hover:text-purple-500 text-white font-montserrat"
                  disabled={isSubmitting}
                >
                  SUBMIT
                </Button>
              </Form>
            )}
          </Formik>
        </AppUpdateFormWrapperTwinMacro>
        <div className="flex justify-center">
          <Button
            leftIcon={<Collapse size={14} />}
            className="xs:w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/5 mx-2 rounded-full text-2xl h-16 bg-white hover:bg-gray-100 hover:text-purple-500 text-purple-400 border-purple-400 border-1 font-montserrat"
            onClick={close}
          >
            CLOSE
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AppUpdateModal;
