import React from 'react';
import { formatNumber } from '../helpers/util-fns';

export default function Badge({ name, number }) {
  return (
    <div className=" bg-[#11102b] text-center rounded-full px-4  py-1 align-middle flex flex-col">
      <small className="text-slate-100">
        {formatNumber(number)} {name}
      </small>
    </div>
  );
}
