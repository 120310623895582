import React, { useEffect, useMemo, useState } from 'react';
import { firestore } from '../lib/firebase.prod';
import { collection, query, limit, getDocs, startAfter } from 'firebase/firestore';
import { Button } from '@mantine/core';
import { SpinningOrbitLoader } from 'react-loaders-kit';
import { ArrowBigDownLines } from 'tabler-icons-react';
import { Spinner } from './styles';
import styled from 'styled-components';

export const Dot = styled.span`
  display: list-item;
  color: ${({ color }) => color};
  height: 0;
  z-index: 10;
  margin-left: 0.5rem;
`;

const webColors = {
  resource: '#EE6983',
  company: '#57D1C9',
  dancewebsite: '#D2001A',
  show: '#570530',
  school: '#185ADB',
  theater: '#FA2FB5',
  org: '#E6B31E',
  operaHouse: '#607EAA',
};

const data = [
  { value: 'resource', label: 'resource' },
  { value: 'company', label: 'company' },
  { value: 'dancewebsite', label: 'dance website' },
  { value: 'show', label: 'show' },
  { value: 'school', label: 'school' },
  { value: 'theater', label: 'theater' },
  { value: 'org', label: 'org' },
  { value: 'operaHouse', label: 'opera house' },
];

const Websites = () => {
  const [websites, setWebsites] = useState([]);
  const [cursorDoc, setCursorDoc] = useState(null);
  const [loading, setLoading] = useState(false);

  const websitesQuery = useMemo(() => query(collection(firestore, 'websites'), limit(200)), []);

  useEffect(() => {
    setLoading(true);
    getDocs(websitesQuery).then(snapshot => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      const firstWebsites = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));

      setCursorDoc(lastVisible);
      setWebsites(firstWebsites);
      setLoading(false);
    });
  }, [websitesQuery]);

  async function getMore() {
    setLoading(true);
    const paginatedQuery = query(collection(firestore, 'websites'), startAfter(cursorDoc), limit(200));
    return getDocs(paginatedQuery).then(snapshot => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      setCursorDoc(lastVisible);

      const paginatedWebsites = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));
      setWebsites(prev => [...prev, ...paginatedWebsites]);
      setLoading(false);
    });
  }

  return (
    <>
      <div className="flex justify-center mt-40 flex-wrap gap-4">
        {data.map(item => {
          // const bgColor = `bg-${item.value}`;

          return (
            <div
              style={{ backgroundColor: webColors[item.value] }}
              key={item.value}
              className={`ml-1 mr-1 pl-4 pr-4 inline-block text-center w-[200] items-center pt-1 pb-1 border-1 border-gray-200 rounded-full cursor-pointer text-white`}
              // onClick={() =>
              //   loading
              //     ? null
              //     : setCategoryFilters((current) => {
              //         if (current.length === 1 && current.includes(item.value)) {
              //           return current;
              //         } else if (current.includes(item.value)) {
              //           return current.filter((filter) => filter !== item.value);
              //         } else {
              //           return [...current, item.value];
              //         }
              //       })
              // }
            >
              {item.label}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <div className="mt-20 p-10 flex flex-wrap gap-6 w-3/4">
          {websites.map(({ name, url, type }) => {
            const dotColor = type === 'dance website' ? webColors.dancewebsite : webColors[type];

            return (
              <div
                key={url}
                className="text-xl pl-4 pr-2 pt-2 pb-2 max-w-sm bg-slate-100 rounded  space-x-2 text-purple-600 hover:bg-slate-100"
              >
                <Dot color={dotColor} />
                <a href={url} className="" target="_blank" rel="noreferrer">
                  {name}
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-center m-20">
        {loading ? (
          <Spinner>
            <SpinningOrbitLoader {...{ loading: true, size: 120 }} />
          </Spinner>
        ) : (
          websites.length >= 40 && (
            <Button
              onClick={getMore}
              radius={4}
              rightIcon={<ArrowBigDownLines size={14} />}
              disabled={loading}
              className="w-1/2 rounded-md text-slate-100 text-xl h-12 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 font-montserrat"
            >
              Load more
            </Button>
          )
        )}
      </div>
    </>
  );
};

export default Websites;
