import { styled } from 'twin.macro';
import 'styled-components/macro';


export const UserCardWrapper = styled.div`
  min-width: 260px;
  margin: 4px 2px;
  border: 1px solid mediumpurple;
  font-family: 'Montserrat', sans-serif;

  position: relative;
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.06);
  border: none;
  transition: box-shadow 0.3s ease;
  margin: 20px;
  border-radius: 0 0 4px 4px;
  border: none !important;
  display: flex;
  flex-direction: column;

  a {
    color: #c785fd;
    font-family: 'Montserrat', sans-serif;
  }

  &:hover {
    box-shadow: 0px 0px 30px 10px rgba(50, 46, 51, 0.2);
    a {
      color: #c785fd;
    }
  }
`;
