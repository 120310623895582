import React from "react";
import { Route, Redirect } from "react-router-dom";

export function IsUserRedirect({ user, loggedInPath, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        if (!user) {
          return children;
        }

        if (user) {
          return <Redirect to={{ pathname: loggedInPath }} />;
        }

        return null;
      }}
    />
  );
}

export function ProtectedRoute({ user, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          return children;
        }

        if (!user) {
          return (
            <Redirect to={{ pathname: "signin", state: { from: location } }} />
          );
        }

        return null;
      }}
    />
  );
}
export function AdminRoute({ user, admin, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (admin) {
          return children;
        } else if (user && !admin) {
          return (
            <Redirect to={{ pathname: "muvents", state: { from: location } }} />
          );
        } else if (!user) {
          <Redirect to={{ pathname: "signin", state: { from: location } }} />;
        }

        return null;
      }}
    />
  );
}
