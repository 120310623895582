export const HOME = '/';
export const MUVENTS = '/muvents';
export const ARTICLES = '/dance-articles';
export const ARTICLE = '/dance-articles/:slug';
export const WEBSITES = '/websites';
export const VIDEOS = '/videos';
export const FACTS = '/facts';
export const MUVENT = '/muvents/:slug';
export const EDIT_MUVENT = '/muvents/:slug/edit';
export const POST_MUVENT = '/create-muvent';
export const SIGN_UP = '/signup';
export const RESET_PATH = '/reset-password';
export const SIGN_IN = '/login';
export const USER = '/users/:slug';
export const SETTINGS = '/users/:slug/settings';
export const NOTIFICATIONS = '/users/:slug/notifications';
export const NYBDC_USER_OF_MONTH = '/user-of-the-month/new-york-baroque-dance-company';
export const PROPELLER = '/hello_propeller';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const PRIVACY = '/privacy-policy';
export const COOKIE_POLICY = '/cookie-policy';
export const BO = '/umuv/admin/backoffice';
export const USERS_ALL = '/users-all';
export const ABOUT_US = '/about';
export const PARTNERSHIPS = '/partnerships';
export const DONATE = '/donate';
export const CAREERS = '/careers';
export const SPONSORS = '/sponsors';
export const ADVERTISE = '/advertise';
export const APP_UPDATES = '/app-updates';
export const NERD_STATS = '/nerd-stats';
export const GOOGLE_ADS = '/ads.txt';
export const HELP = '/help';
