import React from 'react';
import { useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { USERS_ALL } from '../constants/routes';
import { useHistory } from 'react-router-dom';
import { IconX } from '@tabler/icons-react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../lib/firebase.prod';

export default function useUser(slug) {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const userRef = doc(firestore, 'users', slug);

  useEffect(() => {
    setLoading(true);

    async function getUser() {
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUser(docSnap.data());
        }
      } catch (error) {
        notifications.show({
          id: 'muvent-smth-went-wrong',
          withCloseButton: true,
          autoClose: 5000,
          title: 'Something went wrong.',
          message: 'We could not retrieve your muvent data. Please ask for assistance',
          color: 'red',
          loading: false,
          icon: <IconX size="1rem" />,
        });
        history.push(USERS_ALL);
      } finally {
        setLoading(false);
      }
    }

    getUser();
  }, [slug]);

  return { loading, user };
}
