import React from 'react';

const Advertise = () => {
  return (
    <div className="mt-40 text-center">
      <div className="mt-40">
        <h2 className="text-center text-3xl text-pink-400 py-20">WE GIVE WINGS TO YOUR EVENTS!</h2>
      </div>
      <h1 className="">Reach more people globally</h1>
      <h1 className="">Advertise with us and you won&apos;t regret it ! </h1>

      <div className="flex flex-col justify-center items-center text-center">
        <div className="my-10 w-full md:w-1/2">
          <p className="py-10">
            <span className="block text-pink-500 italic pb-2">
              Are you a Dance Brand, Acrobatics Brand, Yoga Brand, or any other?
            </span>
            UMUV will happily advertise brands that we believe will bring benefits to our customers. We want our
            customers to enjoy the easiness of finding good products in UMUV.
          </p>

          <p className="py-10">
            <span className="block text-pink-500 italic pb-2">Are you a Theatre, a Dance School a Dance Festival?</span>
            Do you want your organization to appear first on UMUV searches? Do you want your classes, shows, workshops
            to be suggested to customers that live close to you? Promote your posts on UMUV and reach an entire
            community
          </p>
          <p className="py-10">
            <span className="block text-pink-500 italic pb-2">
              Are you a Bar, Club, Disco? Do you have parties and other dancing events that you would like to see
              crowded with fun people?
            </span>
            Promote your posts and let our happy UMUVers invade your party with their friends!
          </p>
          <p className="py-10">
            <span className="block text-pink-500 italic pb-2">
              Are you health practitioner who works with people that move all day?{' '}
            </span>
            Advertise your therapeutic sessions with UMUV, so our customers can move awesomely and healthy, forever!
          </p>
        </div>

        <div className="pb-40">
          <h4 className="text-pink-500"> Email us at for more information</h4>
          <h4 className="text-pink-500">
            <a href="mailto:umuvworld@gmail.com" target="_blank" rel="noopener noreferrer">
              umuvworld@gmail.com
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Advertise;
