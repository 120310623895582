const firebaseConfig = {
  apiKey: 'AIzaSyBvitzm-ZQ7bvBlDlqsy7tVByEtpUCudbE',
  authDomain: 'umuv-world.firebaseapp.com',
  databaseURL: 'https://umuv-world.firebaseio.com',
  projectId: 'umuv-world',
  storageBucket: 'umuv-world.appspot.com',
  messagingSenderId: '812834356142',
  appId: '1:812834356142:web:5d1c2db4f5f83affd5663f',
  measurementId: 'G-0RR9V2TWV1',
};

export default firebaseConfig;
