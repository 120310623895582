import React, { useEffect, useState } from 'react';
import { useAuthListener } from '../../hooks';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  deletePhotoInStorage,
  firestore,
  storage,
  updateUserProfile,
  updateUserProfilePhoto,
} from '../../lib/firebase.prod';

import { Checkbox, FileInput, Input, Progress, Tabs, TextInput } from '@mantine/core';
import { BellRinging, HomeCog, User, WritingSignOff } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import { removeDashes } from '../../helpers/util-fns';
import { IconPhoto } from '@tabler/icons';
import { useDocumentTitle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

const AllSettings = () => {
  const { user } = useAuthListener();
  useDocumentTitle('Settings - UMUV');
  const [checkedNewMuvents, setCheckedNewMuvents] = useState(false);
  const [checkedLikeMuvent, setCheckedLikeMuvent] = useState(false);
  const [checkedUMUVAppUpdates, setCheckedUMUVAppUpdates] = useState(false);
  const [firebaseError, setFirebaseError] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [uploadPhotoProgress, setUploadPhotoProgress] = useState(null);

  useEffect(() => {
    const notificationsRef = doc(firestore, 'notifications', user.uid);
    getDoc(notificationsRef).then(docSnap => {
      if (docSnap.exists()) {
        const { email_on_new_muvent, email_on_new_like, email_on_umuv_app_updates } = docSnap.data();
        if (email_on_new_muvent) setCheckedNewMuvents(email_on_new_muvent);
        if (email_on_new_like) setCheckedLikeMuvent(email_on_new_like);
        if (email_on_umuv_app_updates) setCheckedUMUVAppUpdates(email_on_umuv_app_updates);
      }
    });
  }, [user.uid]);

  function handleLikeMuventNotif(evt) {
    const checked = evt.currentTarget.checked;
    const notificationsRef = doc(firestore, 'notifications', user.uid);

    setDoc(
      notificationsRef,
      {
        email_on_new_like: checked,
        email: user.email,
        name: user.displayName || 'umuv user',
      },
      { merge: true },
    ).then(() => setCheckedLikeMuvent(checked));
  }

  function handleNewMuventNotif(evt) {
    const checked = evt.currentTarget.checked;
    const notificationsRef = doc(firestore, 'notifications', user.uid);

    setDoc(
      notificationsRef,
      {
        email_on_new_muvent: checked,
        email: user.email,
        name: user.displayName || 'umuv user',
      },
      { merge: true },
    ).then(() => setCheckedNewMuvents(checked));
  }

  function handleNewUMUVAppUpdatesNotif(evt) {
    const checked = evt.currentTarget.checked;
    const notificationsRef = doc(firestore, 'notifications', user.uid);

    setDoc(
      notificationsRef,
      {
        email_on_umuv_app_updates: checked,
        email: user.email,
        name: user.displayName || 'umuv user',
      },
      { merge: true },
    ).then(() => setCheckedUMUVAppUpdates(checked));
  }

  async function handleSaveName({ name }) {
    try {
      await updateUserProfile({ name });

      notifications.show({
        id: 'name-update-notif',
        withCloseButton: true,
        autoClose: 5000,
        title: `Your name was successfully updated to ${name}!`,
        message: `Take pride in it 😀!`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
    } catch (error) {
      if (error?.message) {
        setFirebaseError(error?.message);
      }
      if (error?.message) {
        setFirebaseError(error?.message);
      }
    }
  }

  async function deletePreviousProfilePhoto() {
    try {
      await deletePhotoInStorage(user.photoURL);

      notifications.show({
        id: 'photo-removed-success-notif',
        withCloseButton: true,
        autoClose: 5000,
        title: `Your old profile photo was removed successfully!`,
        message: `Share with friends 😀!`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
    } catch (error) {
      notifications.show({
        id: 'photo-removed-fail-notif',
        withCloseButton: true,
        autoClose: 5000,
        title: `Your old profile photo was removed successfully!`,
        message: `Share your new beautiful photo with friends 😀!`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
    }
  }

  const handleImagePreview = uploadedImage => {
    setImage(uploadedImage);

    const reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const size = Math.min(img.width, img.height);
        canvas.width = size;
        canvas.height = size;
        const x = (img.width - size) / 2;
        const y = (img.height - size) / 2;
        context.drawImage(img, x, y, size, size, 0, 0, size, size);
        canvas.toBlob(
          blob => {
            const newFile = new File([blob], uploadedImage.name, {
              type: 'image/webp',
              lastModified: Date.now(),
            });
            const MAX_SIZE = 1 * 1024 * 1024;
            const quality = MAX_SIZE / newFile.size;
            const compressedDataUrl = canvas.toDataURL('image/webp', quality);
            setImagePreviewUrl(compressedDataUrl);
          },
          'image/webp',
          0.8,
        );
      };
    };
  };

  async function handleImageUpload(evt) {
    evt.preventDefault();

    deletePreviousProfilePhoto();
    const response = await fetch(imagePreviewUrl);
    const blob = await response.blob();
    const metadata = { contentType: 'image/webp' };
    const newUserPhotoRef = ref(storage, `images/users/${user.uid}_${image.name}`);
    const uploadTask = uploadBytesResumable(newUserPhotoRef, blob, metadata);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadPhotoProgress(uploadProgress);
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
          default:
            break;
        }
      },
      () => {
        // Handle unsuccessful uploads
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        handleUpdatePhotoInUsersCollection({ photoURL: downloadURL });
        handleSaveProfilePhoto({ photoURL: downloadURL });
        setUploadPhotoProgress(null);
        setImage(null);
        setImagePreviewUrl(downloadURL);
      },
    );
  }

  async function handleUpdatePhotoInUsersCollection({ photoURL }) {
    const userRef = doc(firestore, 'users', user.uid);
    await updateDoc(userRef, { photoURL });
  }

  async function handleSaveProfilePhoto({ photoURL }) {
    try {
      await updateUserProfilePhoto({ photoURL });
      notifications.show({
        id: 'photo-removed-fail-notif',
        withCloseButton: true,
        autoClose: 5000,
        title: `Your new profile photo was updated successfully!`,
        message: `Now that is fun! 🎊`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
    } catch (error) {
      if (error?.message) {
        setFirebaseError(error?.message);
      }
    }
  }

  function Notifications() {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 m-6">
        <p className="pb-6">
          Choose the email notifications you like to receive from UMUV on related content activity.
        </p>
        <Checkbox
          label="Receive emails about new muvents"
          color="red"
          checked={checkedNewMuvents}
          onChange={handleNewMuventNotif}
          className="py-2"
        />
        <Checkbox
          label="Receive email about someone liking your muvent"
          color="red"
          checked={checkedLikeMuvent}
          onChange={handleLikeMuventNotif}
          className="py-2"
        />
        <Checkbox
          label="Receive emails about future UMUV App updates"
          color="red"
          checked={checkedUMUVAppUpdates}
          onChange={handleNewUMUVAppUpdatesNotif}
          className="py-2"
        />
      </div>
    );
  }

  function UserSettings() {
    const nameForm = useForm({
      initialValues: { name: user?.displayName || '' },
      validate: {
        name: value => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      },
    });

    return (
      <div className="bg-white rounded-lg shadow-lg p-6 m-6">
        <div className="flex flex-col w-full p-8">
          <p className="pb-6">
            You can update your account name here which is a public name and it will be used in the muvents you create.
          </p>
          <form onSubmit={nameForm.onSubmit(handleSaveName)}>
            <Input.Wrapper>
              <TextInput
                icon={<WritingSignOff size={18} strokeWidth={2} color={'black'} />}
                {...nameForm.getInputProps('name')}
                style={{ marginTop: '.5rem' }}
              />
              <button
                type="submit"
                className="sticky px-4 py-1 text-xl mt-2 text-white font-montserrat focus:outline-none border-2  bg-indigo-500 rounded-full bg-gradient-to-r from-pink-400 to-purple-400"
              >
                SAVE
              </button>
            </Input.Wrapper>
          </form>
          <p>{firebaseError}</p>
        </div>

        <div className="flex flex-col w-full p-8">
          <form className="mt-4" onSubmit={handleImageUpload}>
            <FileInput
              // label="Upload photo"
              placeholder="Update your profile photo"
              accept="image/*"
              value={image}
              icon={<IconPhoto size={14} style={{ marginRight: 5 }} />}
              onChange={img => handleImagePreview(img)}
            />
            <br></br>
            <button
              type="submit"
              className="sticky px-4 py-1 text-xl mt-2 text-white font-montserrat focus:outline-none border-2  bg-indigo-500 rounded-full bg-gradient-to-r from-pink-400 to-purple-400"
              disabled={uploadPhotoProgress || !image}
            >
              UPLOAD PHOTO
            </button>
          </form>
          {!!uploadPhotoProgress && (
            <Progress value={uploadPhotoProgress} label={`${uploadPhotoProgress}%`} size="xl" radius="xl" />
          )}
        </div>
        <hr></hr>
      </div>
    );
  }

  function CheckMark() {
    return (
      <svg className="w-4 h-4 mr-2 text-gray-900" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
        ></path>
      </svg>
    );
  }

  function AccountSettings() {
    return (
      <div className="m-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold mb-10 text-gray-900 underline">Your Account Plan</h2>
          <div className="flex justify-between items-center border-b border-gray-300 pb-4 mb-4">
            <div className="flex items-center">
              <span className="text-gray-500 text-sm mr-2">Plan:</span>
              <span className="text-lg font-medium">Free</span>
            </div>
            <span className="text-purple-500 text-lg font-medium">$0/year</span>
          </div>

          <div className="flex justify-between items-center border-b border-gray-300 py-4 mb-4">
            <div className="w-full mb-4">
              <p className="flex items-center justify-center lg:justify-start">
                <CheckMark />
                Free Posts
              </p>
              <p className="flex items-center justify-center lg:justify-start py-2">
                <CheckMark />
                Custom Notifications
              </p>
              <p className="flex items-center justify-center lg:justify-start py-2">
                <CheckMark />
                Your events never expire
              </p>
            </div>
          </div>
          {/* <div className="flex justify-between items-center py-4">
            <div className="flex items-center"></div>
            <button className="bg-purple-400 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-200">
              Upgrade Plan
            </button>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center mt-40">
      <div className="lg:w-3/4 xs:w-full sm:w-full xl:w-2/4 px-2">
        <figure className="md:flex bg-gray-100 rounded-xl p-4 md:p-0 mt-4 items-center flex-col justify-center mb-20 bg-gradient-to-b from-teal-50 to-purple-100">
          {image || user?.photoURL ? (
            <img
              className="w-48 h-48 rounded-full mt-10 bg-cover bg-center"
              src={imagePreviewUrl ? imagePreviewUrl : user.photoURL}
              alt={user?.displayName}
            ></img>
          ) : null}

          <div className="text-center mt-6 font-montserrat mb-20">
            <p className="font-medium text-2xl">{removeDashes(user?.displayName)}</p>
          </div>
          <Tabs
            defaultValue="notifications"
            orientation="vertical"
            styles={() => ({
              tab: {
                '&[data-active]': {
                  backgroundColor: '#ede9fe',
                  borderColor: '#ede9fe',
                  color: '#444444',
                },
              },
            })}
            className="w-full"
          >
            <Tabs.List variant="pills">
              <Tabs.Tab value="notifications">
                <div className="flex items-center py-2">
                  <BellRinging size={16} className="mx-1"></BellRinging>
                  NOTIFICATIONS
                </div>
              </Tabs.Tab>
              <Tabs.Tab value="user">
                <div className="flex items-center py-2">
                  <User size={16} className="mx-1"></User> USER SETTINGS
                </div>
              </Tabs.Tab>
              <Tabs.Tab value="account">
                <div className="flex items-center py-2">
                  <HomeCog size={16} className="mx-1"></HomeCog> ACCOUNT
                </div>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="notifications">
              <Notifications />
            </Tabs.Panel>
            <Tabs.Panel value="user">
              <UserSettings />
            </Tabs.Panel>
            <Tabs.Panel value="account">
              <AccountSettings />
            </Tabs.Panel>
          </Tabs>
        </figure>
      </div>
    </div>
  );
};

export default AllSettings;
