import React from 'react';

const CookiePolicy = () => {
  return (
    <div>
      CookiePolicy
      <div className="py-6 mt-40 mx-10">
        <h3 className="text-blue-600">What Are Cookies</h3>
        <p className="text-gray-500">
          As is common practice with almost all professional websites this site uses cookies, which are tiny files that
          are downloaded to your computer, to improve your experience. This page describes what information they gather,
          how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or &quot;break&quot; certain elements of the sites
          functionality. For more general information on cookies see the
          <a href="http://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noreferrer">
            Wikipedia article on HTTP Cookies...
          </a>
        </p>
        <div className="py-4">
          <h3 className="text-blue-600">How We Use Cookies</h3>
          <p className="text-gray-500">
            We use cookies for a variety of reasons detailed below. Unfortunately is most cases there are no industry
            standard options for disabling cookies without completely disabling the functionality and features they add
            to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or
            not in case they are used to provide a service that you use.
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">Disabling Cookies</h3>
          <p className="text-gray-500">
            You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for
            how to do this). Be aware that disabling cookies will affect the functionality of this and many other
            websites that you visit. Disabling cookies will usually result in also disabling certain functionality and
            features of this site. Therefore it is recommended that you do not disable cookies.
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">The Cookies We Set</h3>

          <p className="text-gray-500">
            If you create an account with us then we will use cookies for the management of the signup process and
            general administration. These cookies will usually be deleted when you log out however in some cases they
            may remain afterwards to remember your site preferences when logged out.
          </p>
        </div>

        <p className="text-gray-500">
          We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log
          in every single time you visit a new page. These cookies are typically removed or cleared when you log out to
          ensure that you can only access restricted features and areas when logged in.
        </p>

        <p className="text-gray-500">
          This site offers newsletter or email subscription services and cookies may be used to remember if you are
          already registered and whether to show certain notifications which might only be valid to
          subscribed/unsubscribed users. This site payment facilities and some cookies are essential to ensure that your
          order is remembered between pages so that we can process it properly.
        </p>
        <p className="text-gray-500">
          From time to time we plan to offer user surveys and questionnaires to provide you with interesting insights,
          helpful tools, or to understand our user base more accurately. These surveys may use cookies to remember who
          has already taken part in a survey or to provide you with accurate results after you change pages. When you
          submit data to through a form such as those found on contact pages or comment forms cookies may be set to
          remember your user details for future correspondence.
        </p>

        <div className="py-4">
          <h3 className="text-blue-600">Third Party Cookies</h3>

          <p className="text-gray-500">
            In some special cases we also use cookies provided by trusted third parties. The following section details
            which third party cookies you might encounter through this site. UMUV uses Google Analytics which is one of
            the most widespread and trusted analytics solution on the web for helping us to understand how you use the
            site and ways that we can improve your experience. These cookies may track things such as how long you spend
            on the site and the pages that you visit so we can continue to produce engaging content. For more
            information on Google Analytics cookies, see the official
            <a
              href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600"
            >
              {' '}
              Google Analytics page.
            </a>
          </p>
          <p className="text-gray-500">
            Third party analytics are used to track and measure usage of this site so that we can continue to produce
            engaging content. These cookies may track things such as how long you spend on the site or pages you visit
            which helps us to understand how we can improve the site for you. From time to time we test new features and
            make subtle changes to the way that the site is delivered. When we are still testing new features these
            cookies may be used to ensure that you receive a consistent experience whilst on the site whilst ensuring we
            understand which optimisations our users appreciate the most. of data that these cookies will track. We also
            use social media buttons and/or plugins on this site that allow you to connect with your social network in
            various ways. For these to work the following social media sites including Facebook authentication.
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">
            We use a number a third party integrated systems to make your experience better and our features on UMUV
            functional. This list includes:
          </h3>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">
            <a href="https://www.cloudflare.com/" target="_blank" rel="noreferrer">
              Reliability, Performance & Insights.
            </a>
          </h3>

          <p className="text-gray-500">
            We want to make UMUV as secure as possible. UMUV has secured its = link_to "SSL",
            "https://www.globalsign.com/en/ssl-information-center/what-is-an-ssl-certificate/", target: '_blank'
            certificate from Cloudflare which makes communication between UMUV and its users safe and secure. Cloudflare
            also offers protection against web attacks like DDos & more. UMUV is striving to take any measure to make
            your data and communication through our Website secure, private and transparent. Learn more about
            Cloudflares
            <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            and
            <a href="https://www.cloudflare.com/cookie-policy/" target="_blank" rel="noreferrer">
              Cookie Policy
            </a>
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">
            <a href="https://www.google.com/" target="_blank" rel="noreferrer">
              Google
            </a>
          </h3>

          <p className="text-gray-500">
            Google Analytics + YouTube - Web traffic tracking & Video Sharing Website UMUV uses analytics and YouTube
            APIs. Learn more about
            <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">
              Google&apos;s Privacy Policy
            </a>
            and
            <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noreferrer">
              Google's Partner Policy
            </a>
          </p>

          <p className="text-gray-500">
            Google Maps UMUV uses Google Maps to provide certain features of the Service, and, as a result, we are
            contractually obligated to make our Members aware of certain terms related to the use of such features.
            Therefore, you acknowledge and agree that by signing up for an account and using the Service, you are also
            bound by the
            <a href="https://maps.google.com/help/terms_maps.html" target="_blank" rel="noreferrer">
              Google Maps/Google Earth Additional Terms of Service
            </a>
            (including the =
            <a href="https://policies.google.com/privacy?hl=en&gl=us" target="_blank" rel="noreferrer">
              Google Privacy Policy
            </a>
            ).
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">
            <a href="https://mailchimp.com" target="_blank" rel="noreferrer">
              MailChimp
            </a>
          </h3>

          <p className="text-gray-500">
            Please read about their
            <a href="http://www.mailchimp.com/legal/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            and
            <a href="http://www.mailchimp.com/legal/cookies/" target="_blank" rel="noreferrer">
              Cookie Statement
            </a>
            for information regarding how they collect, use, and disclose Content and personal information and protect
            privacy when using the Service.
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">
            <a href="https://stripe.com" target="_blank" rel="noreferrer">
              STRIPE
            </a>
          </h3>

          <p className="text-gray-500">
            Learn more about
            <a href="https://stripe.com/us/privay" target="_blank" rel="noreferrer">
              STRIPE&apos;s privacy policy.
            </a>
          </p>
        </div>

        <div className="py-4">
          <h3 className="text-blue-600">More Information</h3>

          <p className="text-gray-500">
            Hopefully this information has clarified things for you and as was previously mentioned if there is
            something that you aren&apos;t sure whether you need or not it&apos;s usually safer to leave cookies enabled
            in case it does interact with one of the features you use on our site. However if you are still looking for
            more information, you can contact us through one of our preferred contact methods.
          </p>
          <p className="text-gray-500">Email: umuvworld@gmail.com if you have questions or concerns.</p>

          <p className="text-gray-500">Congratulations! You&apos;ve reached the end.</p>
          <p className="text-gray-500">Thanks for taking the time to learn about UMUV&apos;s Cookie Policy.</p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
